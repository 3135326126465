import React, { useEffect, useState } from 'react'
import { Button, Select, Input, Table, Tooltip, Image, Modal, message } from 'antd'
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { PageHeader } from '../../../../../components'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useTransportersPagination } from '../../../../../services/transporter-list/transporter-list.service'
import { useTransporterStore } from '../../../../../services/transporter/transporter.service'
import TransporterEditForm from '../../forms/TransporterEditForm/TransporterEditForm'
import { ITransporterCreateSchema } from '../../../../../services/transporter-create/transporter-create.schema'

const { Option } = Select
const { Search } = Input

const TransportersMaintContainer: React.FC = () => {
  const { companyGlobal } = useCompanyStore()
  const { getTransporters, loadingTransporters, transporters, count, page } = useTransportersPagination()
  const { transporterUpdated, transporterCreated,
    deleteTransporter, createTransporter, updateTransporter } = useTransporterStore()

  const [defaultActive, setDefaultActive] = useState<string>('ACTIVE')
  const [searchValue, setSearchValue] = useState<string>('')
  const [coverageModalVisible, setCoverageModalVisible] = useState(false)
  const [selectedTransporter, setSelectedTransporter] = useState<any>(null)
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false)
  const [selectedTransporterId, setSelectedTransporterId] = useState<number | null>(null)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const handleOpenDrawer = (data: any = null) => {
    setSelectedTransporter(data)
    setIsEditMode(!!data)
    setEditModalVisible(true)
  }

  const handlePagination = (pageNumber: number) => {
    getTransporters(defaultActive === 'ACTIVE', searchValue, pageNumber)
  }

  const onSearch = (value: string) => {
    setSearchValue(value)
    getTransporters(defaultActive === 'ACTIVE', value, 1)
  }

  useEffect(() => {
    if (transporterUpdated || transporterCreated) {
      getTransporters(defaultActive === 'ACTIVE', searchValue, page)
    }
  }, [transporterUpdated, transporterCreated])

  useEffect(() => {
    getTransporters(defaultActive === 'ACTIVE', searchValue, 1)
  }, [defaultActive, companyGlobal])

  const handleOpenCoverageModal = (transporter: any) => {
    setSelectedTransporter(transporter)
    setCoverageModalVisible(true)
  }

  const showDeleteConfirm = (transporterId: number) => {
    setSelectedTransporterId(transporterId)
    setDeleteConfirmVisible(true)
  }

  const handleDelete = async () => {
    if (selectedTransporterId) {
      await deleteTransporter(selectedTransporterId)
      message.success('Transportadora eliminada correctamente')
      getTransporters(defaultActive === 'ACTIVE', searchValue, page)
    }
    setDeleteConfirmVisible(false)
  }

  const handleFormSubmit = async (data: ITransporterCreateSchema) => {
    try {
      if (isEditMode && selectedTransporter?.id) {
        await updateTransporter(selectedTransporter.id, {
          urlImage: data.urlImage,
          isActive: data.isActive, // Asegúrate de incluir isActive aquí
        })
        message.success('Transportadora actualizada correctamente')
      } else {
        await createTransporter(data)
        message.success('Transportadora creada correctamente')
      }
      setEditModalVisible(false)
      setSelectedTransporter(null)
      getTransporters(defaultActive === 'ACTIVE', searchValue, page)
    } catch (error) {
      message.error('Error al actualizar la transportadora')
      console.error('Error updating transporter:', error)
    }
  }


  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as const,
    },
    {
      title: 'Código de País',
      dataIndex: 'countryCode',
      key: 'countryCode',
      align: 'center' as const,
    },
    {
      title: 'Estado',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center' as const,
      render: (isActive: boolean) => (isActive ? 'Activo' : 'Inactivo'),
    },
    {
      title: 'Imagen',
      dataIndex: 'urlImage',
      key: 'urlImage',
      align: 'center' as const,
      render: (urlImage: string) => (
        <Image src={urlImage} alt="Transportadora" width={50} height={50} />
      ),
    },
    {
      title: 'Coberturas',
      key: 'coverages',
      align: 'center' as const,
      render: (_: any, transporter: any) => (
        <Tooltip title="Ver coberturas">
          <Button onClick={() => handleOpenCoverageModal(transporter)} icon={<EyeOutlined />} />
        </Tooltip>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center' as const,
      render: (_: any, transporter: any) => (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Tooltip title="Editar">
            <Button shape="circle" icon={<EditOutlined />} onClick={() => handleOpenDrawer(transporter)} />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button shape="circle" icon={<DeleteOutlined />}
              onClick={() => showDeleteConfirm(transporter.id)}
              disabled={true}/>
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          <Button type="primary" onClick={() => handleOpenDrawer()}>
            Agregar Transportadora
          </Button>
          <Search
            placeholder="Buscar por nombre o país"
            onSearch={onSearch}
            enterButton
            style={{ width: 304 }}
            allowClear
          />
          <div>|</div>
          <Select
            optionFilterProp="label"
            style={{ minWidth: '130px' }}
            onChange={(value) => setDefaultActive(value)}
            value={defaultActive}
          >
            <Option key="ACTIVE" value="ACTIVE" label="ACTIVO">
              ACTIVO
            </Option>
            <Option key="INACTIVE" value="INACTIVE" label="INACTIVO">
              INACTIVO
            </Option>
          </Select>
        </div>
      </PageHeader>

      <Table
        rowKey="id"
        columns={columns}
        dataSource={transporters}
        pagination={{
          pageSize: 15,
          current: page,
          total: count,
          onChange: handlePagination,
        }}
        loading={loadingTransporters}
        scroll={{ x: '100%' }}
        size="small"
      />

      <Modal
        title="Confirmación"
        open={isDeleteConfirmVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteConfirmVisible(false)}
        okText="Eliminar"
        cancelText="Cancelar"
      >
        ¿Está seguro de que desea eliminar esta transportadora?
      </Modal>

      <Modal
        title={isEditMode ? 'DeleteOutlined' : 'Agregar Transportadora'}
        open={editModalVisible}
        onCancel={() => {
          setEditModalVisible(false)
          setSelectedTransporter(null)
        }}
        footer={null}
        centered
      >
        <TransporterEditForm
          onSubmit={handleFormSubmit}
          isEditMode={isEditMode}
          onCancel={() => {
            setEditModalVisible(false)
            setSelectedTransporter(null)
          }}
          initialValues={selectedTransporter} // Nuevo: pasar valores iniciales
        />
      </Modal>


      <Modal
        title={`Coberturas de ${selectedTransporter?.name}`}
        open={coverageModalVisible}
        onCancel={() => setCoverageModalVisible(false)}
        footer={null}
        centered
      >
        <Table
          rowKey="id"
          dataSource={selectedTransporter?.coverages || []}
          columns={[
            {
              title: 'Almacén',
              dataIndex: ['warehouse', 'name'],
              key: 'warehouse',
            },
            {
              title: 'Ubigeo',
              dataIndex: ['ubigeo', 'name'],
              key: 'ubigeo',
            },
            {
              title: 'Días adicionales',
              dataIndex: 'addDays',
              key: 'addDays',
            },
            {
              title: 'Costo de entrega',
              dataIndex: 'deliveryCost',
              key: 'deliveryCost',
            },
          ]}
          pagination={false}
          size="small"
        />
      </Modal>
    </>
  )
}

export default TransportersMaintContainer
