/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Space, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { formatDate, differenceDays } from '../../../../../utils/date';
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema';
import { useOrderUpdate } from '../../../../../services/order-update/order-update.service';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import OrderEditForm from '../../forms/OrderEditForm/OrderEditForm';
import { useOrderDeliveredEdit } from '../../../../../services/order-create/order.edit';
import { ORDER_STATUS_LIST } from '../../../../../services/order-create/order.params';
import { useAuthStore } from '../../../../../services/auth/auth.service';

const OrderDeliveryEditContainer = () => {
  const { orderSelected: order, setOrderUpdated } = useOrderStore();
  const { orderUpdate, orderUpdated, loading } = useOrderUpdate();
  const { closeDrawer } = useDrawer();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isField, setIsField] = useState<boolean>(false);
  const { companyUser } = useOrderDeliveredEdit();
  const { authUser } = useAuthStore();

  const handleSubmit = (data: IOrderUpdateSchema) => {
    orderUpdate(data);
  };

  useEffect(() => {
    if (order) {
      if (companyUser === 1) {
        // PRINCIPAL
        if (
          order.status === ORDER_STATUS_LIST.PENDING_DELIVERY ||
          order.status === ORDER_STATUS_LIST.RESCHEDULED
        ) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else if (authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'SELLER') {
        //NORMAL
        if (
          order.status === ORDER_STATUS_LIST.PENDING_DELIVERY &&
          differenceDays(order.shipping.scheduleDate) == 0 &&
          order.isWarehouseMain
        ) {
          setIsField(false);
          setDisabled(true);
        } else if (
          order.status === ORDER_STATUS_LIST.PENDING_DELIVERY &&
          differenceDays(order.shipping.scheduleDate) > 0 &&
          order.isWarehouseMain
        ) {
          setDisabled(false);
          setIsField(false);
        } else {
          setDisabled(true);
          setIsField(true);
        }
      } else {
        setDisabled(false);
        setIsField(false);
      }
    }
  }, []);

  useEffect(() => {
    if (orderUpdated) {
      closeDrawer();
      setOrderUpdated(orderUpdated);
    }
  }, [orderUpdated]);

  return (
    <>
      {order ? (
        <>
          <Space align="baseline">
            <Title level={3}>Orden #{order?.orderNumber}</Title>
          </Space>

          <div style={{ marginBottom: '1.5rem' }}>
            <div>
              <Space>
                <div>
                  <b>Fecha de pedido:</b>
                </div>
                <div>{formatDate(order?.createdAt)}</div>
              </Space>
            </div>
            <div>
              <Space>
                <div>
                  <b>Vendedor:</b>
                </div>
                <div>{order.user?.fullname}</div>
              </Space>
            </div>
          </div>

          <Spin spinning={loading}>
            <OrderEditForm
              initialValues={order}
              onSubmit={handleSubmit}
              disabled={disabled}
              isField={isField}
              companyUser={companyUser}
            />
          </Spin>
        </>
      ) : null}
    </>
  );
};

export default OrderDeliveryEditContainer;
