import { useEffect, useState } from 'react';
import { useFirebase } from '../../hooks';
import { IChatSupport } from './chat-order.interface';
import { IChatMessageSchema } from './chat-order.schema';
import dayjs from 'dayjs';

export const useChatMessages = () => {
  const { getFromDatabase, response, loading, updateFromDatabase } =
    useFirebase();
  const [messageAll, setMessageAll] = useState<IChatSupport[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listAll: IChatSupport[] = Object.values(response as any);

    setMessageAll(listAll);
  }, [response]);

  const getChatMessage = (orderNumber: string) => {
    getFromDatabase(`chat_support/${orderNumber}`);
  };

  const updateChatMessage = (orderNumber: string, data: IChatMessageSchema) => {
    data.orderNumber = orderNumber;
    data.currentMillis = `${Date.now()}`;
    data.active = true;
    data.createdAt = dayjs().utcOffset(-5).format('YYYY-MM-DD HH:mm:ss');
    updateFromDatabase(
      `chat_support/${orderNumber}/${data.currentMillis}`,
      data,
    );
  };

  return {
    updateChatMessage,
    getChatMessage,
    messageAll,
    loading,
  };
};
