import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../../components';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useDispatchGuideList } from '../../../../../services/dispatch-guide-list/dispatch-guide-list.service';
import DispatchGuideListTable from '../../components/DispatchGuideListTable/DispatchGuideListTable';
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import { useConveyorStore } from '../../../../../services/location/location.service';
import { useUserListTransport } from '../../../../../services/order-create/order.edit';

const { Search } = Input;
const { Option } = Select;

const DispatchGuideMaintContainer = () => {
  const { openDrawer } = useDrawer();
  const { getDispatchGuides, dispatchGuides, loading, count, currentPage } =
    useDispatchGuideList();
  const {
    setDispatchGuideSelected,
    dispatchGuideUpdated,
    summaryProductEdited,
    orderAddedToDispatch,
    dispatchGuideDeleted,
  } = useDispatchGuideStore();
  const { authUser } = useAuthStore();
  const { warehouses, loadingWarehouses, getWarehouses } =
    useWarehouseByCountry();
  const [warehouseSelected, setWarehouseSelected] = useState<string>('ALL');
  const [searchText, setSearchText] = useState('');
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('');
  const [transpIdSelected, setTranspIdSelected] = useState<string>('');
  const [hiddenTransp, setHiddenTransp] = useState(false);
  const { setConveyorSelected } = useConveyorStore();
  const { isComboxTransp } = useUserListTransport();

  const handleOpenDrawer = () => {
    setDispatchGuideSelected(null);
    openDrawer('DISPATCH_GUIDE_EDIT', 'Crear guía');
  };

  const handlePagination = (page: number) => {
    getDispatchGuides(page, searchText, warehouseSelected, transpIdSelected);
  };

  useEffect(() => {
    if (transporters && transporters.length > 0) {
      setTranspIdSelected(`${transporters[0].id}`);
      setTranspSelected(`${transporters[0].name}`);

      setConveyorSelected({
        id: transporters[0].id,
        name: transporters[0].name,
        coverages: [],
        isConveyorPremium: false,
      });

      getDispatchGuides(
        1,
        searchText,
        warehouseSelected,
        `${transporters[0].id}`,
      );
    }
  }, [transporters]);

  useEffect(() => {
    getWarehouses();

    if (isComboxTransp === false) {
      getTransporters();
      setHiddenTransp(false);

      if (authUser.user.role.name === 'STORE' && authUser.user.warehouse) {
        setWarehouseSelected(`${authUser.user.warehouse.id}`);
      }
    } else if (isComboxTransp === true) {
      setHiddenTransp(true);
      setTranspIdSelected(`${authUser.user.conveyor?.id}`);

      if (authUser.user.conveyor) {
        setConveyorSelected({
          id: authUser.user.conveyor.id,
          name: '',
          coverages: [],
          isConveyorPremium: false,
        });
      }

      getDispatchGuides(
        1,
        searchText,
        warehouseSelected,
        `${authUser.user.conveyor?.id}`,
      );
    }
  }, []);

  const onSearch = (value: string) => {
    setSearchText(value);

    if (value.length > 0) {
      getDispatchGuides(1, value, warehouseSelected, transpIdSelected);
    } else {
      getDispatchGuides(1, value, warehouseSelected, transpIdSelected);
    }
  };

  useEffect(() => {
    if (dispatchGuideUpdated) {
      setDispatchGuideSelected(null);
      getDispatchGuides(1, searchText, warehouseSelected, transpIdSelected);
    }
  }, [dispatchGuideUpdated]);

  useEffect(() => {
    if (summaryProductEdited && summaryProductEdited.length > 0) {
      getDispatchGuides(1, searchText, warehouseSelected, transpIdSelected);
    }
  }, [summaryProductEdited]);

  useEffect(() => {
    if (dispatchGuideDeleted) {
      getDispatchGuides(1, searchText, warehouseSelected, transpIdSelected);
    }
  }, [dispatchGuideDeleted]);

  useEffect(() => {
    if (orderAddedToDispatch) {
      getDispatchGuides(1, searchText, warehouseSelected, transpIdSelected);
    }
  }, [orderAddedToDispatch]);

  const handleChangeWarehouse = (value: string) => {
    setWarehouseSelected(value);

    getDispatchGuides(1, searchText, value, transpIdSelected);
  };

  const handleChangeTransporter = (id: string) => {
    const t = transporters.find((t) => `${t.name}` === `${id}`);

    if (t) {
      setConveyorSelected(t);

      setTranspIdSelected(`${t.id}`);
      getDispatchGuides(1, searchText, warehouseSelected, `${t.id}`);
    }

    setTranspSelected(id);
  };

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            gap: '.5rem',
            alignItems: 'center',
          }}
        >
          {hiddenTransp ? (
            <></>
          ) : (
            <>
              <h5>Transportadora</h5>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {transporters?.map((key) => (
                  <Option key={key.name} value={key.name}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          {authUser.user.warehouse ? (
            <></>
          ) : (
            <>
              <h5 style={{ marginBottom: '0' }}>Almacén</h5>

              <Select
                placeholder="Almacén"
                showSearch
                loading={loadingWarehouses}
                optionFilterProp="label"
                style={{ minWidth: '150px' }}
                onChange={handleChangeWarehouse}
                value={warehouseSelected}
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL" label={'TODOS'}>
                  TODOS
                </Option>
                {warehouses.map((key) => (
                  <Option key={key.id} value={key.id} label={key.name}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <div>
            <Button type="primary" onClick={handleOpenDrawer}>
              Crear Guía
            </Button>
          </div>

          <div>
            <Search
              placeholder="Buscar guía y pedidos de la guía"
              onSearch={onSearch}
              enterButton
              style={{ width: 304 }}
              allowClear
            />
          </div>
        </div>
      </PageHeader>
      <DispatchGuideListTable
        handlePagination={handlePagination}
        dispatchGuides={dispatchGuides}
        loading={loading}
        count={count}
        currentPage={currentPage}
      />
    </>
  );
};

export default DispatchGuideMaintContainer;
