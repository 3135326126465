import React, { useEffect } from 'react';
import { Button, Typography } from 'antd';
import {
  useUserLogoutUser,
  useUserStore,
} from '../../../../../services/user/user.service';
import { useModal } from '../../../../../contexts/ModalContext';

const { Title } = Typography;

const UserLogoutContainer = () => {
  const { userSelected, setUserUpdated } = useUserStore();
  const { userLogoutUser, response, loading } = useUserLogoutUser();
  const { closeModal } = useModal();

  const handleLogoutUser = () => {
    if (userSelected) {
      userLogoutUser(userSelected.id, userSelected.platform);
    }
  };

  useEffect(() => {
    if (response && userSelected) {
      const newSessions = userSelected.session;
      const indexSession = userSelected.session.findIndex(
        (it) => it.platform === userSelected.platform,
      );

      closeModal();
      setUserUpdated({
        ...userSelected,
        session: newSessions.splice(indexSession),
      });
    }
  }, [response]);

  return (
    <>
      <Title level={5} style={{ textAlign: 'center' }}>
        ¿Estas seguro que desea terminar la sesión del usuario?
      </Title>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <Button
          type="primary"
          style={{ marginRight: '10px' }}
          onClick={handleLogoutUser}
          disabled={loading}
        >
          Aceptar
        </Button>
        <Button disabled={loading} onClick={closeModal}>
          Cancelar
        </Button>
      </div>
    </>
  );
};

export default UserLogoutContainer;
