import React from 'react';
import './App.css';
import { RouterConfig } from './routes/RouterConfig';
import { ContextManager } from './contexts/ContextManager';
import { LayoutWrapper } from './modules/layout';

function App() {
  return (
    <ContextManager>
      <LayoutWrapper>
        <RouterConfig />
      </LayoutWrapper>
    </ContextManager>
  );
}

export default App;
