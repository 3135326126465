import { Input, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../../components';
import { ReturnListTable } from '../../components';
import { useReturnList } from '../../../../../services/return-list/return-list.service';
import { useMotoriedListAll } from '../../../../../services/user-motorized/user-motorized.service';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ReturnMaintContainer = () => {
  const dateFormat = 'YYYY-MM-DD';
  const { getMotorizedAll, loadingMt, motorizeds } = useMotoriedListAll();
  const [motoSelected, setMotoSelected] = useState<string>('ALL');
  const [searchValue, setSearchValue] = useState<string>('');
  const { getReturn, returnGuides, loading, count, currentPage } =
    useReturnList();
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    dayjs(subDays(new Date(), 30)),
    dayjs(new Date()),
  ]);

  useEffect(() => {
    getMotorizedAll();
    getReturn(
      selectedDatesOrder[0].format('YYYY-MM-DD'),
      selectedDatesOrder[1].format('YYYY-MM-DD'),
      1,
      searchValue,
      motoSelected,
    );
  }, []);

  const handlePagination = (page: number) => {
    getReturn(
      selectedDatesOrder[0].format('YYYY-MM-DD'),
      selectedDatesOrder[1].format('YYYY-MM-DD'),
      page,
      searchValue,
      motoSelected,
    );
  };

  const onSearch = (value: string) => {
    getReturn(
      selectedDatesOrder[0].format('YYYY-MM-DD'),
      selectedDatesOrder[1].format('YYYY-MM-DD'),
      1,
      value,
      motoSelected,
    );

    setSearchValue(value);
  };

  const handleChangeMotorized = (motoId: string) => {
    getReturn(
      selectedDatesOrder[0].format('YYYY-MM-DD'),
      selectedDatesOrder[1].format('YYYY-MM-DD'),
      1,
      searchValue,
      motoId,
    );

    setMotoSelected(motoId);
  };

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    getReturn(
      dates[0].format('YYYY-MM-DD'),
      dates[1].format('YYYY-MM-DD'),
      1,
      searchValue,
      motoSelected,
    );
    setSelectedDatesOrder(dates);
  };

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            gap: '.5rem',
            alignItems: 'center',
          }}
        >
          <h5 style={{ alignContent: 'center' }}>Fechas</h5>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(subDays(new Date(), 30)), dayjs(new Date())]}
            onCalendarChange={handleCalendarChangeOrder}
            clearIcon={false}
          />

          <h5 style={{ alignContent: 'center' }}>Motorizado</h5>

          <Select
            placeholder="Motorizado"
            value={motoSelected}
            onChange={handleChangeMotorized}
            loading={loadingMt}
            style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key={'ALL'} value={'ALL'}>
              TODOS
            </Option>
            {motorizeds.map((c) => (
              <Option key={c.id} value={`${c.id}`} label={c.email}>
                {c.email}
              </Option>
            ))}
          </Select>

          <div>
            <Search
              placeholder="Buscar por número de pedido"
              onSearch={onSearch}
              enterButton
              style={{ width: 304 }}
              allowClear
            />
          </div>
        </div>
      </PageHeader>

      <ReturnListTable
        handlePagination={handlePagination}
        returnGuides={returnGuides}
        loading={loading}
        count={count}
        currentPage={currentPage}
      />
    </>
  );
};

export default ReturnMaintContainer;
