import React, { FC } from 'react';
import { Modal } from 'antd';

type TTermsAndConditionModalProps = {
  open: boolean;
  onAccept: () => void;
  onCancel: () => void;
};

export const TermsAndConditionModal: FC<TTermsAndConditionModalProps> = ({
  open,
  onAccept,
  onCancel,
}) => {
  return (
    <Modal
      title="Términos y Condiciones Aliclik"
      open={open}
      onOk={onAccept}
      onCancel={onCancel}
      bodyStyle={{
        height: '400px',
        overflowY: 'scroll',
        fontSize: '12px',
        paddingTop: '10px',
      }}
    >
      Mediante la presente se especifican los Términos y Condiciones Generales
      de Aliclik, al usted aceptar manifiesta haber leído, entendido y estar de
      acuerdo con ellos. Los términos y condiciones generales describen el
      alcance del servicio prestado, las obligaciones y los derechos de usted
      como usuario y los nuestros como plataforma; por tanto, estos Términos &
      Condiciones son vinculantes y de obligatorio cumplimiento.
      <br />
      <b>1. DEFINICIONES</b>
      <br />
      Tienda Ecom: Micro, pequeñas, medianas y grandes empresas que comercian
      productos físicos a través de plataformas electrónicas, redes sociales y
      hacen parte de Aliclik. Proveedor: Empresa propietaria de los productos
      suministrados a cada Tienda Ecom. Almacén o Bodega: Empresa que almacena
      los productos de cada Proveedor. Courier o Transportadora: Empresa de
      transporte encargada del reparto al cliente final de la Tienda Ecom y
      recaudo. Cliente Aliclik: Comercios, personas naturales o jurídicas que
      tienen una cuenta en la plataforma Aliclik, han aceptado sus Términos y
      Condiciones y utilizan con cierta asiduidad los servicios Aliclik. Cuenta
      Aliclik: El acceso, al repositorio de información y todo el material
      contenido por el usuario, relacionado con las funcionalidades de Aliclik.
      Servicio Aliclik: El conjunto de aplicaciones y funcionalidades
      desarrolladas y dispuestas por ALICLIK SYSTEM S.A.C.
      <br />
      <b>2. ALICLIK</b>
      <br />
      Es una compañía de tecnología que ofrece servicios vinculados al comercio
      electrónico, mediante el cual se pretende incentivar, apoyar y dinamizar
      los procesos inherentes al comercio electrónico. A través de la plataforma
      se facilita a Tiendas Ecom, comercios, emprendedores y comerciantes las
      herramientas necesarias para que éstos, en nombre propio puedan vender y
      enviar sus productos, así como cobrar por sus pedidos de forma remota. Por
      lo tanto, Aliclik es un simple intermediario entre Tiendas Ecom,
      Proveedores, Almacenes, Courier y las plataformas de pago, encargado de
      gestionar el envío y cobro de los productos negociados. Obrando bajo la
      figura de mandato sin representación otorgado conforme a lo estipulado en
      los artículos 1809° y siguientes del Código Civil Peruano.
      <br />
      <b>3. CAPACIDAD DE LAS PARTES</b>
      <br />
      Nuestros servicios sólo están ofrecidos a aquellas personas que tienen
      capacidad para contratar y obligarse según la legislación vigente en el
      territorio peruano. Por tanto, para utilizar nuestra plataforma y acceder
      a los servicios ofrecidos por Aliclik, usted certifica ser mayor de 18
      años y no tener ningún tipo de limitación para su ejercicio obligacional
      si es persona natural, y en caso de obligarse como representante legal de
      una persona jurídica, afirma tener capacidad para obligarse a nombre de
      tal.
      <br />
      <b>4. OBJETO</b>
      <br />
      El objeto del presente documento es facilitar al usuario el uso de la
      plataforma Aliclik, para que este, de la mano de los aliados estratégicos
      de Aliclik pueda realizar ventas a través de internet. Las Tiendas Ecom
      son exclusivos responsables de la legalidad y legitimidad de los artículos
      que ofrecen. Cada Tienda Ecom deberá cumplir con todas las obligaciones
      regulatorias pertinentes y contar con los registros, habilitaciones,
      permisos y/o autorizaciones exigidas por la normativa aplicable para la
      venta de los bienes y servicios ofrecidos. Aliclik no asume
      responsabilidad alguna por la existencia en el sitio de productos o
      servicios que infrinjan las políticas o cualquier ley o resolución
      judicial vigente. El objeto del acuerdo que usted suscribe al aceptar
      estos términos y condiciones cobija desde el uso de la tecnología de
      Aliclik hasta la orden de mandato consistente en la negociación con el
      courier para la entrega del producto vendido al cliente final y el recaudo
      de dinero y entrega a usted a través del servicio de “AliWallet”.
      <br />
      <b>5. CONSENTIMIENTO</b>
      <br />
      Al usted aceptar los presentes términos manifiesta entender las
      condiciones del servicio prestado y su deseo de acceder a ella por
      voluntad propia sin coacción externa. Usted es libre de utilizar o no los
      servicios ofrecidos; sin embargo, en caso de optar por hacerlo usted se
      obliga a respetar las condiciones expuestas en este texto, además de las
      condiciones de nuestras políticas de tratamiento de datos personales.
      <br />
      <b>6. RESPONSABILIDADES</b>
      <br />
      6.1. DE TIENDAS ECOM
      <br />
      Los vendedores son exclusivos responsables de la legalidad y legitimidad
      de los artículos que ofrecen, asimismo, deberán cumplir con todas las
      obligaciones regulatorias pertinentes y contar con los registros,
      habilitaciones, permisos y/o autorizaciones exigidas por la normativa
      aplicable para la venta de los bienes y servicios ofrecidos. Emitir boleta
      o factura de venta a su cliente final.
      <br />
      6.2. DE PROVEEDORES
      <br />
      Los proveedores son propietarios y responsables de la legalidad y
      legitimidad de los artículos que suministran a cada Tienda Ecom, asimismo,
      deberán cumplir con todas las obligaciones regulatorias pertinentes y
      contar con los registros, habilitaciones, permisos y/o autorizaciones
      exigidas por la normativa aplicable para la venta de los bienes y
      servicios ofrecidos. Emitir boleta o factura de venta a la Tienda Ecom
      <br />
      6.2. DE ALMACÉN O BODEGA
      <br />
      Empresa que almacena los productos de cada Proveedor.
      <br />
      6.3. DE COURIER
      <br />
      Empresa de transporte encargada del reparto y recaudo.
      <br />
      6.4. DE ALICLIK
      <br />
      Aliclik no asume responsabilidad alguna por la existencia en el sitio de
      productos o servicios que infrinjan las políticas o cualquier ley o
      resolución judicial vigente. Sin perjuicio de lo antes señalado, Aliclik
      sólo será responsable por cualquier defecto en la prestación de su
      servicio, en la medida en que le sea imputable y con el alcance previsto
      en las leyes vigentes.
      <br />
      6.5. DEL CLIENTE ALICLIK
      <br />
      Suministrar información verídica y verificable a la plataforma. Actuar con
      respeto del ordenamiento jurídico. Tener una clara identificación de los
      productos ofrecidos, vendidos y enviados a través de la plataforma
      Aliclik. Conservar las formas y el respeto durante el trato con Aliclik y
      sus aliados. Respetar la propiedad intelectual de Aliclik, sus clientes y
      aliados. Emitir factura de venta cuando corresponda. Brindar información
      real respecto al contenido, dimensiones y peso de los paquetes enviados.
      <br />
      <b>7. DECLARACIÓN</b>
      <br />
      Al hacer uso de los servicios de Aliclik, – incluidos Aliclik Envíos,
      Aliclik Pagos, Aliclik Bodegas, Aliclik referidos o cualquier otro -,
      usted declara y garantiza lo siguiente: Su intención es legítima y no
      pretende violar o trasgredir cualquier norma jurídica del ordenamiento
      vigente Cuenta con todos los permisos, autorizaciones, licencias, poderes
      y derechos requeridos para realizar sus actividades y operaciones, así
      como cumplir con las obligaciones derivadas. Que cuenta con la capacidad
      técnica, económica y financiera, así como con adecuados recursos humanos
      para prestar los Servicios. Faculta a Aliclik a retirar sus productos de
      su plataforma, o de suspender el servicio cuando sospeche de actuaciones
      ilícitas.
      <br />
      <b>8. REGISTRO</b>
      <br />
      Para el uso de nuestros servicios, se deberá completar el formulario de
      registro con los datos que le sean requeridos, comprometiéndose a hacerlo
      de manera exacta, precisa y verdadera, así como a mantener sus datos
      siempre actualizados. Los usuarios – Comercio y Clientes Aliclik – del
      servicio de Aliclik serán los únicos responsables de la certeza de sus
      datos de registro. Sin perjuicio de la información brindada en el
      formulario, podremos solicitar y/o consultar información adicional para
      corroborar la identidad de los usuarios. Solo se permitirá la creación de
      una Cuenta a cada persona jurídica o natural; por ende, Aliclik se reserva
      el derecho de cancelar las distintas cuentas que ostente la misma persona
      natural o jurídica.
      <br />
      <b>9. CANCELACIÓN DE LA CUENTA</b>
      <br />
      Aliclik se reserva el derecho a cancelar su cuenta si encuentra
      suplantación, fraude, pluralidad de cuentas en cabeza de un mismo titular
      o que desde su cuenta se están llevando a cabo acciones contrarias a lo
      pactados en los términos de servicio o la ley. Del mismo modo usted podrá
      unilateralmente solicitar la cancelación de su cuenta mediante correo
      electrónico a la dirección administrativo@aliclik.app. En tal situación se
      suspenderá el servicio y se le liberará el dinero que usted tenga en su
      billetera; sin embargo, usted autoriza expresamente a Aliclik descontar de
      su billetera, previo a la cancelación cualquier suma que usted
      efectivamente le adeude a Aliclik o se encuentre en disputa con alguna
      entidad o persona por denuncia de fraude.
      <br />
      <b>10. TÉRMINOS Y CONDICIONES DE ENVÍOS</b>
      <br />
      10.1. DEFINICIONES ESPECÍFICAS
      <br />
      Orden de pedido o pedido: Venta realizada por cualquier medio pero
      reportada a la plataforma Aliclik.
      <br />
      Envío: Orden de pedido preparada por el comercio con el propósito de ser
      entregada, sea pagada previamente a Aliclik o con orden de recaudo al
      recibir.
      <br />
      Paquete: Unidad que agrupa las mercancías dentro de un pedido.
      <br />
      Mercancías: Productos contenidos dentro de un paquete que ordenó el
      comprador al comercio a través del pedido.
      <br />
      Recaudo al recibir: Pedido con recaudo al recibir o más conocido como
      contra entrega es la orden de recaudar un dinero que será entregado
      posteriormente a Aliclik para que sea bonificado a la Tienda Ecom.
      <br />
      Estado de despacho: Gestionados por el Almacén y Courier
      <br />
      Por preparar: El pedido ha sido registrado por la Tienda Ecom y está listo
      para prepararse.
      <br />
      Preparado: El almacén donde se encuentran las mercancías ha alistado el
      paquete siguiente las condiciones correctas de Fullfilment.
      <br />
      Observado: El almacén validó que no se cumplen las condiciones para
      preparar el paquete (falta de stock, pagos no válidos, mercancías
      defectuosas, etc)
      <br />
      En tránsito: El paquete ha sido entregado por el almacén al Courier siendo
      desde este momento responsabilidad del mismo.
      <br />
      En agencia: El Courier está trasladando el paquete a la ciudad destino
      <br />
      Validado: El paquete ha llegado correctamente a su destino y está listo
      para la entrega al cliente final.
      <br />
      Disponible: El paquete no ha sido entregado al cliente original pero está
      libre para poder reutilizarse en algún otro pedido de la misma ciudad.
      <br />
      Por devolver: El paquete no ha podido entregarse ni reutilizarse por lo
      que se requiere regresar al almacén de origen.
      <br />
      Almacén Central: El paquete ha llegado al centro de acopio para
      posteriormente distribuirse a los almacenes de origen.
      <br />
      Remanente en tránsito: El paquete ha salido del Almacén Central del
      Courier en camino al almacén de origen.
      <br />
      Dejado en almacén: El Courier entregó el paquete al almacén de origen
      siendo desde este momento responsabilidad del mismo.
      <br />
      Devuelto: El almacén de origen validó correctamente el retorno y se
      procedió con la actualización de stock.
      <br />
      Estados de entrega: Gestionado por el Courier
      <br />
      Pendiente de entrega: El pedido está en proceso de entrega.
      <br />
      No despachado: El pedido no fue despachado por el Almacén por diversos
      motivos.
      <br />
      Sin stock: El pedido no se despachó por falta de stock.
      <br />
      Fuera de cobertura: El pedido se agendó para una zona fuera de la
      cobertura a la que llega el Courier.
      <br />
      Rechazado: El Courier llegó a la ubicación del cliente final sin embargo
      éste se negó a recibir y pagar el pedido.
      <br />
      De viaje: El cliente indica que se encuentra de viaje por lo cual no puede
      recibir el pedido.
      <br />
      Reprogramado: El cliente final indica específicamente que quiere recibir
      el pedido en otra fecha.
      <br />
      Cancelado: El cliente final indica que ya no desea el pedido.
      <br />
      Anulado: El pedido no se procede a entregar a solicitud de Tienda Ecom.
      <br />
      No responde: El courier agotó todos los medios disponibles para
      comunicarse con el cliente final.
      <br />
      Entregado: Entrega y recaudo exitoso del pedido.
      <br />
      Novedad: Estado de notificación por parte del Courier sobre alguna novedad
      en la entrega que debe ser resuelta.
      <br />
      Solución de novedad: Información brindada por el comercio o comprador en
      respuesta a la notificación de novedad del Courier.
      <br />
      Envío siniestrado: Envío que sufrió de siniestro y que el seguro del
      transportador cubrirá por el valor que se ha declarado (valor del pedido)
      y será bonificado a través de Aliclik.
      <br />
      10.2. NATURALEZA DEL SERVICIO
      <br />
      Al usted realizar una venta a través de nuestra plataforma, Aliclik
      procederá a través de sus aliados comerciales a acompañarlo durante el
      proceso de transporte y entrega de la mercancía vendida por usted. Para
      esto, es necesario que usted suministre a través de nuestra plataforma los
      datos necesarios para tal fin. Es muy importante para el correcto
      desarrollo del servicio que usted verifique la dirección de entrega, los
      datos del comprador y las dimensiones del paquete. El servicio prestado
      por Aliclik es el de un mandatario, quien celebra con terceros contratos
      de transporte para llevar a cabo las entregas de las ventas realizadas a
      través de nuestra plataforma, por tanto, en ningún momento se debe
      interpretar que Aliclik es responsable de las mercaderías; sin embargo,
      seremos intermediarios en la comunicación con las transportadoras o
      Courier aliadas. Por tanto, usted reconoce que, en las ventas realizadas,
      Aliclik no hace parte de la cadena de producción, distribución o venta,
      limitándose a ser un mandatario que a su nombre negocia con terceros para
      facilitarle condiciones de envío y recaudo. Una vez usted aporte los datos
      necesarios, la transportadora o courier por usted seleccionada a través de
      la plataforma procederá a recoger la mercancía en la fecha y hora
      indicada, así mismo le generará un número de guía que usted debe conservar
      para conocer el estado del envío y poder sustentar cualquier tipo de
      reclamación ante la transportadora. Aliclik no se hace responsable por el
      retardo o demora de las transportadoras.
      <br />
      10.3. DATOS NECESARIOS PARA EL ENVÍO
      <br />
      Para poder recoger, transportar y entregar las mercancías por usted
      vendidas, Aliclik entregará a la transportadora seleccionada las
      direcciones de recojo y entrega por usted proporcionadas; por lo tanto,
      usted es enteramente responsable de los datos que suministra al momento
      del envío, debe tener en cuenta que cualquier error suyo en los nombres o
      en las direcciones de recojo y entrega que genere costos, deberá ser
      asumido por usted, autorizándonos conforme a nuestros “Términos y
      condiciones de pago” de descontar de su billetera el valor respectivo de
      tal error. Los datos que usted nos suministre serán administrados y
      tratados con base en nuestra “Política de Tratamiento de Datos Personales”
      la cual usted declara haber leído y aceptado al aceptar nuestros términos
      y condiciones.
      <br />
      10.4. FORMA DEL SERVICIO
      <br />
      Al usted cargar una orden de envío en nuestra plataforma, está
      indicándonos que debemos celebrar un contrato de transporte en nuestro
      nombre, como mandatario sin representación; para tal fin, se le solicitará
      ingrese la dirección de recojo, la dirección de entrega y las medidas y
      peso del envío, para con base en ello realizar el proceso de facturación
      por envío. Con el número de guía, y el número de pedido suministrado,
      usted y sus clientes podrán hacer seguimiento al estado del pedido y
      solicitar apoyo a través de nuestros canales de comunicación respectivos,
      quienes servirán como puente entre la transportadora seleccionada para el
      envío y usted. En ningún momento emana relación de subordinación,
      exclusividad, no competencia, suministro, depósito o cualquiera similar
      diferente al mandato entre usted y Aliclik, por lo que en cualquier
      momento y sin previo aviso es posible dar por terminada la relación
      comercial sin lugar a indemnizaciones, pues nos reservamos el derecho de
      revocar el acceso a nuestros servicios o variar términos y condiciones.
      Cada orden de envío que usted suministre a la plataforma inicia una
      relación contractual nueva, la cual termina una vez se entregue el pedido
      y se le desembolse el dinero en su billetera. Durante este periodo, no es
      posible para ninguna de las partes culminar la relación. Es posible que
      usted contrate el servicio de Aliclik Envíos sin recaudo, es decir; sin
      que Aliclik sea su colaborador para la recepción del pago del precio por
      el producto vendido; sin embargo, usted reconoce que acepta los “Términos
      y condiciones de pago”. Del mismo modo, cuando usted ordene un envío sin
      que Aliclik realice ningún recaudo, mediante la modalidad de “pago por
      consignación” o cualquier otra donde no se utilice alguna de nuestras
      pasarelas de pago o pago a contraentrega, en su billetera se congelará el
      valor del envío hasta tanto este se complete y sea descontado o bien, sea
      cancelado por su parte si aún no se ha recogido. Tenga en cuenta que, si
      su producto es de valor cuantioso, como bisutería, oro, plata o relojes de
      alto costo, debe llevarlos usted mismo hacia la sede del transportador
      elegido, indicando claramente el valor del producto.
      <br />
      10.5. VALOR DEL ENVÍO
      <br />
      El valor del envío variará según el peso volumétrico, forma de pago,
      distancia y transportadora escogida. Usted suministrará los datos del
      producto a enviar y con base en ellos se generará la tarifa de envío y un
      número de guía. En caso de que los datos suministrados no correspondan a
      la realidad del producto y la transportadora elegida genere sobrecostos o
      cobros adicionales por el producto enviado, usted se obliga
      inequívocamente a cancelar dicho valor y autoriza a Aliclik para congelar
      y descontar el saldo correspondiente al sobrecosto en su billetera
      virtual. En todos los casos el pago del valor del envío corre por su
      cuenta.
      <br />
      10.6. OBLIGACIONES ESPECIALES
      <br />
      Al usted hacer uso de Aliclik envíos, se obliga a respetar el ordenamiento
      jurídico peruano y nuestras políticas internas. Por tanto, se abstendrá de
      comerciar, ofrecer, promocionar y sobre todo enviar productos prohibidos
      por la legislación peruana, incluyendo más no limitando: especies
      endémicas, fauna y flora reguladas, objetos gravemente inflamables,
      objetos explosivos, medicinas reguladas, armas de fuego, armas de fogueo
      sin el respectivo salvoconducto, sustancias psicotrópicas, etc. Del mismo
      modo, en caso de ofrecer productos importados usted se obliga a tener en
      regla todos los documentos, manuscritos y certificados exigidos por la
      legislación peruana para su venta y transporte. En caso de decomiso o
      sanción usted reconoce que tanto Aliclik como sus transportadoras aliadas
      son simples intermediarios
      <br />
      10.7. LIMITE DE RESPONSABILIDAD
      <br />
      Aliclik no será responsable si usted viola de alguna manera el
      ordenamiento jurídico o si en su actuación vulnera los derechos de un
      tercero. Del mismo modo, usted reconoce que Aliclik es un simple
      intermediario y por tanto no será solidariamente responsable en caso de
      demanda o reclamación en su contra. Por tanto, usted comprende y acepta
      expresamente que Aliclik no será responsable de ningún daño directo,
      indirecto, incidental, especial o consecuencial, incluidos, entre otros,
      daños por lucro cesante, buena voluntad, uso, datos u otras pérdidas
      intangibles resultantes del uso o incapacidad para utilizar el servicio.
      <br />
      <b>11. TÉRMINOS Y CONDICIONES DE PAGOS</b>
      <br />
      11.1. CONSIDERACIONES PREVIA
      <br />
      11.1.1. Aliclik no está sometida a la inspección y vigilancia de la
      Superintendencia de Banca, Seguros y AFP. No realiza actividad financiera
      ni presta ningún servicio financiero o bursátiles. Tampoco otorga
      autorización a terceros para que utilicen los Servicios para tales fines.
      No capta recursos, ni recibe depósitos bancarios del público.
      <br />
      11.1.2. Los recursos de los comercios que Aliclik recauda, los recibe a
      título de mandatario o intermediario entre usted y sus compradores, por
      tanto, su función consiste en recaudar el pago realizado a usted como
      vendedor y posteriormente depositarlo en su cuenta respectiva luego de
      haber realizado las deducciones respectivas por concepto de pago.
      <br />
      11.1.3. El mandato que otorga el Tienda Ecom a Aliclik como plataforma,
      excluye facultades para cumplir las obligaciones tributarias o cambiarias
      del Comercio. La Tienda Ecom es el único responsable de conocer y cumplir
      la legislación aplicable.
      <br />
      11.1.4. Aliclik no garantiza la autenticidad o legalidad de las
      transacciones que se procesan a través de su Plataforma. El riesgo de
      fraude por suplantación de identidad de un pagador, o el de ilegalidad de
      una transacción son enteramente asumidos por el Comercio.
      <br />
      11.2. OPERACIÓN DEL SERVICIO DE PAGOS
      <br />
      11.2.1. Al usted vender cualquier producto apoyado por nuestra plataforma,
      o al usted autorizar a la plataforma recaudar el pago por determinado
      producto está celebrando un contrato de mandato remunerado con Aliclik,
      consensual y sin representación quien a su nombre recibirá el pago y se lo
      mostrará como disponible en su billetera.
      <br />
      11.2.2. Inmediatamente se realice un pago virtual a su comercio el Gateway
      nos informará la recepción del dinero en la cuenta bancaria de Aliclik
      autorizada para tal fin, luego de esto procederemos a colocarlo a su
      disposición una vez el comprador reciba el producto en la dirección
      indicada.
      <br />
      11.2.3. Al aceptar estos términos y condiciones usted autoriza a Aliclik
      para de antemano realizar los cobros respectivos por cada transacción,
      mostrando su billetera el respectivo balance.
      <br />
      11.2.4. En caso de que la entidad bancaria reporte cualquier tipo de
      alerta de fraude, usted autoriza expresamente a Aliclik para entregar el
      monto solicitado por la entidad bancaria mientras se allegan los soportes
      requeridos. En caso de que usted no logre acreditar la legalidad de la
      transacción ante la entidad bancaria, Aliclik no se encuentra obligado a
      pagar ningún valor.
      <br />
      11.2.5. Aliclik descontará de su cuenta el valor del servicio y envíos
      según corresponda. Usted podrá verificar los valores discriminados en el
      estado de cuenta que podrá generar a través de la plataforma.
      <br />
      11.2.6. En caso de que el pago se realice a contra entrega (cash on
      delivery), Aliclik pondrá a disposición este valor en su billetera
      inmediatamente el aliado de transporte consigne el dinero a la cuenta
      Aliclik.
      <br />
      11.2.7. En cualquier momento, antes de colocar a disposición el dinero
      recaudado por Aliclik en su billetera, la plataforma le puede solicitar
      verificación de identidad o de titularidad de la cuenta, esto es por la
      seguridad de todos.
      <br />
      11.3. OBLIGACIONES DE LA TIENDA ECOM
      <br />
      11.3.1. Autorizar el descuento de las respectivas sumas generadas a favor
      de Aliclik.
      <br />
      11.3.2. Abstenerse de acceder al código de la plataforma Aliclik, o
      utilizar ingeniería de reversa o mecanismos similares violando así la
      propiedad intelectual.
      <br />
      11.3.3. Abstenerse de utilizar la plataforma para fines ilícitos.
      <br />
      11.3.4. Aportar los documentos que respalden las transacciones en caso de
      ser requeridos por Aliclik o por alguna entidad bancaria en caso de
      sospecha de fraude.
      <br />
      11.3.5. Coadyuvar a esclarecer cualquier investigación en contra del
      comercio por parte de las entidades respectivas.
      <br />
      11.3.6. Proporcionar la adecuada información para facturación a la
      plataforma.
      <br />
      11.3.7. Abstenerse de utilizar cuentas bancarias de terceros sin su
      autorización.
      <br />
      11.3.8. Proporcionar los documentos de identificación solicitados por la
      plataforma para la realización de pagos y la autorización de operación.
      <br />
      11.4. OBLIGACIONES DE ALICLIK
      <br />
      11.4.1 Recaudar los pagos realizados a las Tiendas ECOM y depositarlos en
      la cuenta bancaria por este indicada, dejando claridad de los valores
      recaudados, los montos descontados y los saldos pendientes a través de la
      plataforma.
      <br />
      11.4.2 Respetar y no divulgar la información privilegiada que por el
      servicio prestado se llegare a obtener.
      <br />
      11.4.3. Apoyar y trabajar de la mano con los comercios en caso de
      investigación de fraude por parte de entidades bancarias, siempre y cuando
      el comercio allegue los respectivos soportes de la licitud de la
      transacción o transacciones objeto de investigación.
      <br />
      11.4.4. No congelar o bloquear arbitrariamente el saldo o las billeteras
      de los comercios sin una debida información o comunicación.
      <br />
      11.4.5. Establecer canales de comunicación que lleven confianza al
      comercio y al cliente respecto al servicio prestado.
      <br />
      11.4.6. Aliclik no garantiza una continua e ininterrumpida disponibilidad
      de sus servicios, pero si se compromete a realizar todas las labores
      tendientes a ofrecer estabilidad en la medida de lo posible.
      <br />
      11.4.7. Aliclik no se compromete ni se obliga a determinados tiempos de
      procesamiento de transacciones.
      <br />
      11.4.8. Aliclik no asume ni asumirá posibles riesgos de fraude debido a
      suplantación de identidad. Si se compromete a ayudar al comercio respecto
      al trámite frente a las entidades bancarias correspondientes siempre y
      cuando el comercio allegue los soportes solicitados en los tiempos
      respectivos.
      <br />
      11.5. CONDICIONES ESPECIALES DEL SERVICIO DE PAGOS
      <br />
      El comercio declara que al usar este servicio:
      <br />
      11.5.1. Conoce y autoriza que, de forma transitoria y en su calidad de
      mandatario, Aliclik reciba los Ingresos del comercio en la cuenta de
      depósito que Aliclik tiene en la Entidad Adquirente.
      <br />
      11.5.2. Conoce y autoriza a Aliclik Pagos para debitar de su Ingresos,
      todos los descuentos a los que haya lugar conforme se estipulan en los
      términos.
      <br />
      11.5.3. Conoce y acepta que, si Aliclik rechaza la solicitud de
      desembolso, congela saldo o descuenta saldo de su cuenta, usted tiene la
      carga de acreditar el haber actuado sin violación de nuestros Términos o
      el ordenamiento jurídico.
      <br />
      11.5.4. Conoce y otorga mandato irrevocable a Aliclik para que, por cuenta
      y orden del Comercio, Aliclik solicite a las plataformas de pagos bloquear
      los Ingresos desembolsados a la cuenta de depósito del Comercio cuando se
      inicie una investigación por un contracargo, un fraude o un
      enriquecimiento sin justa causa.
      <br />
      11.5.5. Autoriza expresamente a Aliclik a congelar y descontar de su
      cuenta si observa movimientos sospechosos, que puedan dar pie a fraude o
      indebida destinación de la plataforma.
      <br />
      11.5.6. Autoriza expresamente a Aliclik a congelar y descontar los saldos
      en su cuenta que sean objeto de reclamación por la entidad bancaria,
      poniéndolos a disposición de esta hasta tanto no se levante la alerta de
      fraude.
      <br />
      11.5.7. Descuentos. – Cada vez que se procese una transacción, a los
      Ingresos brutos que esta transacción represente, se le realizarán los
      respectivos descuentos; por lo tanto, al Comercio solo se le desembolsará
      los Ingresos netos.
      <br />
      11.5.8. Autoriza expresamente a Aliclik a congelar y descontar los saldos
      en su cuenta que se generen por sobre costos o devoluciones generados
      debido a los envíos realizados a través de la plataforma.
      <br />
      11.6. TOPE DE PROCESAMIENTO DE TRANSACCIONES
      <br />
      Cada comercio tiene asignados automáticamente un Tope Máximo por
      transacción y un Tope Máximo Diario (calculado como el total transado en
      las últimas veinticuatro (24) horas. Estos topes tienen como finalidad
      establecer los límites en dinero que deberán tener las transacciones
      realizadas en la tienda que se pueden recibir en un día como medida de
      seguridad, esto se irá expandiendo automáticamente de acuerdo con el
      comportamiento de aprobación o rechazo de transacciones
      <br />
      11.7. LÍMITE DE RESPONSABILIDAD
      <br />
      Aliclik no se hace responsable por los perjuicios que terceros le puedan
      ocasionar durante el uso de nuestros servicios. Del mismo modo, Aliclik no
      es responsable si usted utiliza nuestros servicios para transgredir el
      ordenamiento jurídico peruano. Estas limitaciones se aplicarán incluso si
      se ha informado a Aliclik de la posibilidad de tales daños. Al aceptar
      estos términos, usted acepta indemnizar y mantenernos exentos de
      responsabilidad respecto de cualquier reclamo, denuncia o demanda,
      incluidos los honorarios razonables de los abogados, que surjan de su uso
      y, según corresponda, a nuestra matriz, subsidiarias, socios comerciales,
      funcionarios, directores, agentes, empleados y proveedores de Aliclik, de
      un Servicio de Terceros o su relación con un Proveedor de Terceros.
      <br />
      11.8. TERCEROS EN EL SERVICIO DE PAGOS
      <br />
      11.8.1. Además de estos Términos y condiciones, usted también acepta estar
      sujeto a los términos adicionales específicos del servicio aplicables a
      los servicios que compre o que sean proporcionados por los socios de
      Aliclik u otros terceros.
      <br />
      11.8.2. No proporcionamos ninguna garantía con respecto a los servicios de
      terceros. Usted reconoce que Aliclik no tiene control sobre los Servicios
      de terceros y que no será responsable ante nadie por dichos Servicios de
      terceros, ni de la disponibilidad de los Servicios de terceros en los
      sitios web de Aliclik.
      <br />
      11.8.3. Aliclik no garantiza la disponibilidad de los Servicios de
      Terceros y usted reconoce que Aliclik puede deshabilitar el acceso a los
      Servicios de Terceros en cualquier momento a su entera discreción y sin
      previo aviso.
      <br />
      11.8.4. Aliclik no es responsable ni se hace responsable ante nadie por la
      interrupción o suspensión del acceso o la desactivación de cualquier
      Servicio de terceros.
      <br />
      11.8.5. Aliclik recomienda encarecidamente que busque asesoramiento
      especializado antes de utilizar o confiar en los Servicios de terceros,
      para asegurarse de que satisfagan sus necesidades. En particular, las
      calculadoras de impuestos deben usarse solo como referencia y no como un
      sustituto del asesoramiento fiscal independiente al evaluar las tasas de
      impuestos correctas que debe cobrar a sus clientes.
      <br />
      11.8.6. Si instala o habilita un Servicio de Terceros para su uso con los
      Servicios, nos concede permiso para permitir que el Proveedor de Terceros
      correspondiente acceda a sus datos y tome las medidas que sean necesarias
      para la interoperación del Servicio de Terceros con los Servicios, y
      cualquier intercambio de datos u otra interacción entre usted y el
      Proveedor Tercero es únicamente entre usted y dicho Proveedor Tercero.
      <br />
      11.8.7. Aliclik no es responsable de ninguna divulgación, modificación o
      eliminación de sus datos u otros materiales, ni de las pérdidas o daños
      correspondientes que pueda sufrir, como resultado del acceso de un
      Servicio de terceros o un Proveedor tercero a sus datos u otros
      materiales. En ninguna circunstancia Aliclik será responsable por
      cualquier daño directo, indirecto, incidental, especial, punitivo,
      extraordinario o de otro tipo que resulte de cualquier Servicio de
      Terceros o su relación contractual con cualquier Proveedor Tercero,
      incluido cualquier Mentor. Estas limitaciones se aplicarán incluso si se
      ha informado a Aliclik de la posibilidad de tales daños. Las limitaciones
      anteriores se aplicarán en la máxima medida permitida por la ley
      aplicable.
      <br />
      <b>12.TERMINOS Y CONDICIONES DE PROVEDORES</b>
      <br />
      12.1. Los Proveedores ofrecerán sus productos a través de la plataforma
      suministrada por Aliclik, donde las tiendas ECOM podrán adquirirlas por sí
      mismo, o venderlas a terceros al precio que deseen, indicando el punto de
      entrega y forma de pago de estos productos.
      <br />
      12.2. Los Proveedores se obligan a dar una información verídica sobre los
      productos, estado, cantidad, condiciones y demás delimitaciones de las
      mercancías a ofrecer. Cualquier incompatibilidad entre la oferta realizada
      y el producto suministrado hará responsable el proveedor, dejando a
      Aliclik indemne como simple intermediario de las consecuencias civiles o
      penales que de esto se corresponda.
      <br />
      12.3. El Proveedor se obliga a enviar la cantidad y productos que el
      Comercio le indique dentro de los tiempos estipulados.
      <br />
      12.4. La Tienda ECOM acepta y reconoce que Aliclik es un simple
      intermediario y que no es responsable de la calidad o cantidad de los
      productos suministrados por Los Proveedores, en todo caso, Aliclik servirá
      como intermediario en caso de que surja una situación que lo amerite.
      <br />
      12.5. Aliclik se reserva el derecho de cancelar el acceso al servicio
      Proveedores de forma unilateral si encuentra o sospecha violación a los
      términos generales.
      <br />
      12.6. FORMA DE OPERACIÓN
      <br />
      12.6.1. El Proveedor se obliga a enviar a la dirección que la Tienda ECOM
      indique, la cantidad de productos que este le referencie con base en la
      información que el Proveedor ha suministrado con anterioridad a la
      Plataforma.
      <br />
      12.6.2. Los Comercios tendrán acceso a través de la plataforma Aliclik a
      los catálogos de precios y productos ofrecidos por los Proveedores,
      teniendo la facultad de ofrecerlos para su venta a terceros al margen de
      precio que considere conveniente.
      <br />
      12.6.3. El Proveedor emitirá factura a nombre del Comercio discriminando
      claramente la razón del gravamen. El servicio prestado por Aliclik se
      facturará en forma independiente.
      <br />
      12.6.4. El precio de los productos enviados por El Proveedor será el
      informado a la plataforma al momento de que el Comercio de la orden de
      envío al Proveedor, por tanto, en caso de duda respecto al precio, se
      mirará el precio reportado al momento de la transacción.
      <br />
      12.6.5. El Proveedor se obliga a garantizar a la Tienda ECOM o a quien
      este haya vendido los productos ordenados, el perfecto estado de estos,
      dando los auxilios y derechos que concede la legislación civil y
      comercial,. Por tanto, de antemano el Proveedor se obliga inequívocamente
      a responder por las garantías y el estado de los productos ofrecidos,
      dejando a Aliclik y sus comercios indemnes en caso de reclamación por
      temas de garantías.
      <br />
      12.6.6. El Proveedor se obliga a despachar el producto vendido dentro de
      las siguientes 48 horas hábiles luego de la recepción de la orden de
      envío; si el pedido fue despachado después de este tiempo y se genera
      devolución quedará obligado a pagar el flete.
      <br />
      <b>13. PRIVACIDAD DE DATOS</b>
      <br />
      En Aliclik empleamos de forma responsable la información personal,
      protegiendo la privacidad de los Usuarios – Comercios y Clientes Aliclik –
      que nos brindaron sus datos. Asimismo, tomamos las medidas necesarias para
      garantizar la seguridad de dicha información.
      <br />
      <b>14. PROPIEDAD INTELECTUAL</b>
      <br />
      Aliclik es propietaria de todos los derechos de propiedad intelectual
      sobre sus sitios, todo su contenido, servicios, productos, marcas, nombres
      comerciales, logos, diseños, imágenes, frases publicitarias, derechos de
      autor, dominios, programas de computación, códigos, desarrollos, software,
      bases de datos, información, tecnología, patentes y modelos de utilidad,
      diseños y modelos industriales, secretos comerciales, entre otros
      (“Propiedad Intelectual”) y se encuentran protegidos por leyes nacionales
      e internacionales. Aunque Aliclik otorga permiso para usar sus productos y
      servicios conforme a lo previsto en los Términos y Condiciones, esto no
      implica una autorización para usar su Propiedad Intelectual, excepto
      consentimiento previo y expreso de Aliclik y/o sus sociedades vinculadas.
      En cualquier caso, los Comercios que usen dichos productos y servicios no
      podrán utilizar la Propiedad Intelectual de Aliclik de una manera que
      cause confusión en el público y deberán llevar a cabo su actividad
      comercial bajo una marca o nombre comercial propio y distintivo, que no
      resulte confundible con la marca Aliclik y sus productos. Está prohibido
      usar nuestros productos o servicios para fines ilegales, realizar
      cualquier tipo de ingeniería inversa u obras derivadas, utilizar
      herramientas de búsqueda o de extracción de datos y contenidos de nuestra
      plataforma para su reutilización y/o crear bases de datos propias que
      incluyan en todo o en parte nuestro contenido sin nuestra expresa
      autorización. Está también prohibido el uso indebido, sin autorización y/o
      contrario a la normativa vigente y/o que genere confusión o implique uso
      denigratorio y/o que le cause perjuicio, daños o pérdidas a Aliclik. La
      utilización de los productos y servicios de Aliclik tampoco implica la
      autorización para usar propiedad intelectual de terceros que pueda estar
      involucrada, cuyo uso quedará bajo exclusiva responsabilidad del usuario.
      En caso de que uno de los Usuarios o cualquier publicación infrinja la
      Propiedad Intelectual de Aliclik, se nos faculta a poder remover dicha
      publicación total o parcialmente, sancionar al usuario conforme a lo
      previsto en estos Términos y Condiciones y ejercer las acciones
      extrajudiciales y/o judiciales correspondientes.
      <br />
      <b>15. SANCIONES</b>
      <br />
      En caso de que los Usuarios incumplieran una ley o los Términos y
      Condiciones, podremos: advertir, suspender, restringir o inhabilitar
      temporal o definitivamente su cuenta, sin perjuicio de la indemnización
      acorde al perjuicio.
      <br />
      <b>16. INDEMNIDAD</b>
      <br />
      Los Usuarios mantendrán indemne a Aliclik, así como a quienes la dirigen,
      suceden, administran, representan y/o trabajan en ellas, por cualquier
      reclamo administrativo o judicial iniciado por otros Usuarios, terceros o
      por cualquier Organismo, relacionado con sus actividades. En virtud de esa
      responsabilidad, podrán realizar compensaciones, retenciones u otras
      medidas necesarias para la reparación de pérdidas, daños y perjuicios,
      cualquiera sea su naturaleza.
      <br />
      <b>17. NULIDAD PARCIAL</b>
      <br />
      Si alguna disposición, o una parte de la misma, contemplada en estos
      Términos del servicio, por cualquier motivo, se considerase inválida,
      ilegal o inejecutable en cualquier aspecto, dicha nulidad, ilegalidad o
      inaplicabilidad no afectará a ninguna otra disposición (ni a la parte no
      afectada de la disposición) de los Términos del Servicio, y los Términos
      del servicio se interpretarán como si dicha disposición no válida, ilegal
      o no exigible, o parte de la disposición, nunca hubiera estado incluida en
      los Términos del Servicio.
      <br />
      <b>18. JURISDICCIÓN Y LEY APLICABLE</b>
      <br />
      Estos Términos y Condiciones se rigen por las leyes de la República del
      Perú. Toda controversia derivada de su aplicación, interpretación,
      ejecución o validez será sometida y resuelta por los tribunales de Lima,
      salvo disposición específica de normas de orden público. Para todos los
      efectos relacionados con estos Términos y Condiciones y con el uso del
      sitio, Aliclik Perú S.A.C. con RUC 20611293365, establece como domicilio
      la Calle German Schreiber Gulsmanco N° 276, distrito de San Isidro,
      provincia y departamento de Lima, Perú.
      <br />
      <b>19. REVERSIÓN DEL PAGO/ MODIFICACIÓN DEL PAGO.</b>
      <br />
      Es muy importante que tengas presente que, al acceder y usar Aliclik,
      estás aceptando estos Términos y Condiciones de manera íntegra y sin
      reserva la reversión o modificación en la billetera dispuesta para tu
      comercio. Por lo anterior, al hacer uso de la plataforma y del servicio de
      pagos, aceptas que Aliclik pueda hacer reversión o modificación de pagos
      en el evento en que se realice pago a la billetera de tu comercio con
      ocasión del informe emitido por la transportadora en donde se indica un
      estado logístico que no corresponda con el estado real del envío,
      generándose un enriquecimiento sin causa alguna que así lo justifique. Lo
      anterior implica que conforme a la trazabilidad emitida por la
      transportadora y debidamente contrastada por Aliclik con la guía de envío,
      si este se encuentre en estado “entregado” y realmente ello no corresponde
      a su estado logístico real, se procederá por el área correspondiente a
      realizar las validaciones y de ser el caso, a reversar y/o modificar el
      pago, según corresponda. Con fundamento en el supuesto anterior, autorizas
      modificar dentro de los treinta (30) días hábiles siguientes a la
      notificación del estado logístico realizado por la transportadora a
      Aliclik el saldo de tu billetera sin necesidad de requerir validaciones o
      autorizaciones distintas a la aceptación de los presentes Términos y
      Condiciones. Para tales efectos, Aliclik se obliga a soportar
      documentalmente con la guía de envío la validación realizada, la cual
      podrá ser solicitada por el cliente dentro de los treinta (30) días
      hábiles siguientes a la reversión o modificación de la billetera. Sumado a
      lo anterior, con la aceptación de los presentes Términos y Condiciones
      renuncias a cualquier reclamación extrajudicial o judicial producto de la
      reversión y/o modificación de saldos en la billetera realizada en el plazo
      aquí definido.
    </Modal>
  );
};
