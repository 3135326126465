import React, { FC } from 'react';

import { ConfigProvider, theme } from 'antd';

// DayJS
import dayjs from 'dayjs';
import locale from 'antd/es/locale/es_ES';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Lima');
dayjs.locale('es');

// Theme
const { darkAlgorithm, defaultAlgorithm } = theme;

interface IThemeContextProps {
  children: React.ReactNode;
}

export const ThemeContext: FC<IThemeContextProps> = ({ children }) => {
  const darkMode = false;

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: '#f88d1c',
          borderRadius: 18,
          colorLink: '#f88d1c',
          fontSize: 13.8,
        },
        components: {
          Button: {
            fontWeight: 500,
          },
          Form: {
            labelFontSize: 13.2,
          },
          Table: {
            fontSize: 13.2,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
