import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { DepositEditForm } from '../../forms';
import {
  useLiquidationCreate,
  useVoucherDelete,
} from '../../../../../services/liquidation-edit/liquidation-edit.service';
import { useLiquidationStore } from '../../../../../services/liquidation-edit/liquidation-edit.store';
import { ILiquidationSchema } from '../../../../../services/liquidation-edit/liquidation.schema';

const LiquidationEditContainer = () => {
  const { updateLiquidation, liquidationEditUpdated, loading } =
    useLiquidationCreate();
  const { deleteVoucher, voucherDeleted } = useVoucherDelete();
  const { closeDrawer } = useDrawer();
  const { setLiquidationUpdated } = useLiquidationStore();

  const handleSubmit = (data: ILiquidationSchema) => {
    updateLiquidation(data);
  };

  const deleteVoucherLiquidation = (id: number) => {
    deleteVoucher(id);
  };

  useEffect(() => {
    if (voucherDeleted) {
      setLiquidationUpdated({ id: 1 });
      closeDrawer();
    }
  }, [voucherDeleted]);

  useEffect(() => {
    if (liquidationEditUpdated) {
      setLiquidationUpdated(liquidationEditUpdated);
      closeDrawer();
    }
  }, [liquidationEditUpdated]);

  return (
    <>
      <Spin spinning={loading}>
        <DepositEditForm
          onSubmit={handleSubmit}
          onDeleteVoucher={deleteVoucherLiquidation}
        />
      </Spin>
    </>
  );
};

export default LiquidationEditContainer;
