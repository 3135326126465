import { Button, Divider, Form, Input, Select, Switch, Tag } from 'antd';
import React, { FC, useEffect } from 'react';
import { useRolesList } from '../../../../../services/role-list/role-list.service';
import { IUserCreateSchema } from '../../../../../services/user-create/user-create.schema';
import { useUserStore } from '../../../../../services/user/user.service';
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { CountryPhoneFormItem } from '../../../../../modules/country-phone/containers';
import { formItemLayout, formLayout } from '../../../../../config/forms-layout';

const { Option } = Select;

interface IUserEditFormProps {
  onSubmit: (data: IUserCreateSchema) => void;
}

const UserEditForm: FC<IUserEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IUserCreateSchema>();
  const { getRoles, loading, roles } = useRolesList(true);
  const { getWarehouses, warehouses, loadingWarehouses } =
    useWarehouseByCountry();
  const { userSelected } = useUserStore();
  const roleId = Form.useWatch('roleId', form);
  const color = Form.useWatch('markercolor', form);
  const warehouseId = Form.useWatch('warehouseId', form);

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (color && color.length > 0) {
      if (color === '#FFFFFF') {
        form.setFieldValue('iconColor', 'wht');
      } else if (color === '#1976D2') {
        form.setFieldValue('iconColor', 'blu');
      } else if (color === '#FF5252') {
        form.setFieldValue('iconColor', 'red');
      } else if (color === '#4CAF50') {
        form.setFieldValue('iconColor', 'grn');
      } else if (color === '#03A9F4') {
        form.setFieldValue('iconColor', 'ltblu');
      } else if (color === '#E91E63') {
        form.setFieldValue('iconColor', 'pink');
      } else if (color === '#9C27B0') {
        form.setFieldValue('iconColor', 'purple');
      } else if (color === '#FFEB3B') {
        form.setFieldValue('iconColor', 'ylw');
      } else if (color === '#FF9800') {
        form.setFieldValue('iconColor', 'orange');
      } else if (color === '#795548') {
        form.setFieldValue('iconColor', 'brown');
      } else if (color === '#FFC107') {
        form.setFieldValue('iconColor', 'amber');
      } else if (color === '#00BCD4') {
        form.setFieldValue('iconColor', 'cyan');
      } else if (color === '#009688') {
        form.setFieldValue('iconColor', 'teal');
      } else if (color === '#FF5722') {
        form.setFieldValue('iconColor', 'deep-orange');
      } else if (color === '#9E9E9E') {
        form.setFieldValue('iconColor', 'grey');
      } else if (color === '#607D8B') {
        form.setFieldValue('iconColor', 'blue-grey');
      }
    }
  }, [color]);

  useEffect(() => {
    if (userSelected) {
      form.setFieldsValue({
        id: userSelected.id,
        email: userSelected.email,
        password: '',
        fullname: userSelected.fullname,
        phone: userSelected.phone,
        roleId: userSelected.roleId,
        status: userSelected.status,
        isActive: userSelected.status === 'ACTIVE',
        roleName: userSelected.role.name,
        userMail: userSelected.userMail,
        companyId: userSelected.companyId,
        warehouseId: userSelected.warehouseId,
        warehouseName: userSelected.warehouseName,
        markercolor: userSelected.markerIcon?.color,
        iconColor: userSelected.markerIcon?.icon,
        countryPhoneId: userSelected.countryPhone?.id as unknown as number,
      });
    }
  }, [userSelected]);

  useEffect(() => {
    if (roleId) {
      const name = roles?.find((t) => t.id == roleId)?.name;

      form.setFieldValue('roleName', name);

      if (roleId === 9) {
        getWarehouses();
      }
    }
  }, [roleId]);

  useEffect(() => {
    if (warehouseId) {
      form.setFieldValue('warehouseId', `${warehouseId}`);

      const warehouse = warehouses.find((w) => `${w.id}` === `${warehouseId}`);

      if (warehouse) {
        form.setFieldValue('warehouseName', warehouse.name);
      }
    }
  }, [warehouseId]);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        name="UserForm"
        labelWrap
        onFinish={onSubmit}
        {...formLayout}
      >
        <Form.Item hidden name={['id']}>
          <Input />
        </Form.Item>

        <Form.Item hidden name={['companyId']}>
          <Input />
        </Form.Item>

        <Form.Item hidden name={['warehouseName']}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Usuario"
          name={['email']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input disabled={!!userSelected} />
        </Form.Item>

        <Form.Item
          label="Nombres y apellidos"
          name={['fullname']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input />
        </Form.Item>

        {!userSelected ? (
          <Form.Item
            label="Password"
            name={['password']}
            hasFeedback
            rules={[
              { required: true, message: 'Obligatorio' },
              {
                pattern: /(?=.*[A-Z])/,
                message: 'Debe incluir al menos una mayúscula',
              },
              {
                pattern: /(?=.*\d)/,
                message: 'Debe incluir al menos un número',
              },
              {
                pattern: /.{6,}/,
                message: 'Debe tener al menos 6 caracteres',
              },
            ]}
          >
            <Input.Password
              placeholder="Crea una contraseña"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        ) : null}

        <Form.Item
          label="Correo electrónico"
          name={['userMail']}
          hasFeedback
          rules={[
            {
              type: 'email',
              message: 'No es un correo electrónico válido',
            },
            { required: true, message: 'Obligatorio' },
          ]}
        >
          <Input />
        </Form.Item>

        <CountryPhoneFormItem form={form} />

        <Form.Item
          label="Status"
          name={['status']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción">
            <Option value="ACTIVE">ACTIVO</Option>
            <Option value="INACTIVE">INACTIVO</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Rol"
          name={['roleId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción" loading={loading}>
            {roles?.map((r) => (
              <Option key={r.id} value={r.id}>
                {r.description}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={['roleName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['iconColor']} hidden>
          <Input />
        </Form.Item>

        {userSelected ? (
          <Form.Item label="Activo" name={['isActive']} valuePropName="checked">
            <Switch />
          </Form.Item>
        ) : null}

        {roleId === 9 ? (
          <>
            <Form.Item
              label="Almacén"
              name={['warehouseId']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <Select
                loading={loadingWarehouses}
                style={{ display: 'flex', width: '200px' }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {warehouses.map((c) => (
                  <Option key={c.id} value={`${c.id}`} label={c.name}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : null}

        {roleId === 3 ? (
          <>
            <Form.Item
              label="Color de marcador"
              name={['markercolor']}
              rules={[{ required: false, message: '' }]}
            >
              <Select
                style={{ display: 'flex', width: '200px' }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key={'#FFFFFF'} value={'#FFFFFF'} label={'#FFFFFF'}>
                  <Tag color="#FFFFFF" style={{ width: '100%' }}>
                    BLANCO
                  </Tag>
                </Option>
                <Option key={'#1976D2'} value={'#1976D2'} label={'#1976D2'}>
                  <Tag color="#1976D2" style={{ width: '100%' }}>
                    AZUL
                  </Tag>
                </Option>
                <Option key={'#FF5252'} value={'#FF5252'} label={'#FF5252'}>
                  <Tag color="#FF5252" style={{ width: '100%' }}>
                    ROJO
                  </Tag>
                </Option>
                <Option key={'#4CAF50'} value={'#4CAF50'} label={'#4CAF50'}>
                  <Tag color="#4CAF50" style={{ width: '100%' }}>
                    VERDE
                  </Tag>
                </Option>
                <Option key={'#03A9F4'} value={'#03A9F4'} label={'#03A9F4'}>
                  <Tag color="#03A9F4" style={{ width: '100%' }}>
                    CELESTE
                  </Tag>
                </Option>
                <Option key={'#E91E63'} value={'#E91E63'} label={'#E91E63'}>
                  <Tag color="#E91E63" style={{ width: '100%' }}>
                    ROSADO
                  </Tag>
                </Option>
                <Option key={'#9C27B0'} value={'#9C27B0'} label={'#9C27B0'}>
                  <Tag color="#9C27B0" style={{ width: '100%' }}>
                    MORADO
                  </Tag>
                </Option>
                <Option key={'#FFEB3B'} value={'#FFEB3B'} label={'#FFEB3B'}>
                  <Tag color="#FFEB3B" style={{ width: '100%' }}>
                    AMARILLO
                  </Tag>
                </Option>
                <Option key={'#FF9800'} value={'#FF9800'} label={'#FF9800'}>
                  <Tag color="#FF9800" style={{ width: '100%' }}>
                    ANARANJADO
                  </Tag>
                </Option>

                <Option key={'#795548'} value={'#795548'} label={'#795548'}>
                  <Tag color="#795548" style={{ width: '100%' }}>
                    MARRÓN
                  </Tag>
                </Option>

                <Option key={'#00BCD4'} value={'#00BCD4'} label={'#00BCD4'}>
                  <Tag color="#00BCD4" style={{ width: '100%' }}>
                    CIAN
                  </Tag>
                </Option>

                <Option key={'#009688'} value={'#009688'} label={'#009688'}>
                  <Tag color="#009688" style={{ width: '100%' }}>
                    VERDE AZULADO
                  </Tag>
                </Option>

                <Option key={'#FF5722'} value={'#FF5722'} label={'#FF5722'}>
                  <Tag color="#FF5722" style={{ width: '100%' }}>
                    NARANJA INTENSO
                  </Tag>
                </Option>

                <Option key={'#FFC107'} value={'#FFC107'} label={'#FFC107'}>
                  <Tag color="#FFC107" style={{ width: '100%' }}>
                    AMBAR
                  </Tag>
                </Option>

                <Option key={'#9E9E9E'} value={'#9E9E9E'} label={'#9E9E9E'}>
                  <Tag color="#9E9E9E" style={{ width: '100%' }}>
                    GRIS
                  </Tag>
                </Option>

                <Option key={'#607D8B'} value={'#607D8B'} label={'#607D8B'}>
                  <Tag color="#607D8B" style={{ width: '100%' }}>
                    GRIS AZULADO
                  </Tag>
                </Option>
              </Select>
            </Form.Item>
          </>
        ) : null}

        <Divider />

        <Form.Item {...formItemLayout}>
          <Button type="primary" htmlType="submit" block>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserEditForm;
