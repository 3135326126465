import { DeleteOutlined } from '@ant-design/icons'
import React, { FC } from 'react'
import { IProductSearchSchema } from '../../../../../services/product/product.schema'
import './ProductListSearch.css'

interface IProductListSearchProps {
  data: IProductSearchSchema[]
  total: number
  payAgency: number
  managementType?: string
  warName: string
  disabled: boolean
  onDelete: (item: IProductSearchSchema) => void
}

const ProductListSearch: FC<IProductListSearchProps> = (
  { data, total, onDelete, managementType, payAgency, disabled, warName }
) => {

  if (!data?.length) {
    return <div className="ProductListSearch__empty">
      No hay productos.
    </div>
  }

  return <>
    <div className="ProductListSearch__grid ProductListSearch__head">
      <div>Cant.</div>
      <div>Producto</div>
      <div className="right">P.U.</div>
      <div className="right">Subtotal</div>
      <div></div>
    </div>

    {data?.map((item, index) =>
      <div
        key={index}
        className="ProductListSearch__grid"
      >
        <div>{item.quantity}</div>
        <div>{item.productName}</div>
        <div className="right">
          {item.sku?.currency.symbol} {item.price}
        </div>
        <div className="right">
          {item.sku?.currency.symbol} {item.subtotal}
        </div>
        <div className="center" hidden={disabled}>
          <DeleteOutlined onClick={() => onDelete(item)} />
        </div>
      </div>
    )}

    <div className="ProductListSearch__footer">
      <div className="right">Total:</div>
      <div className="right">
        <b>
          {total}
        </b>
      </div>
    </div>

    { managementType === 'PARTNER' ? <>
      <br></br>
      <div className='right'>
        <b>Recuerda registrar tu guía por Shalom PRO en la agencia {warName}</b>
      </div>
      <div className="right" style={{ color: 'red' }}>
        <b>Pago a realizar:</b> Para despachar su producto tiene que registrar un depósito de S/{payAgency.toFixed(2)}
      </div>
      <div className="right" style={{ color: 'blue' }}>
        Recepción de pagos a nombre de <b>Aliclik System:</b><br></br>
        <b>BCP:</b> N° cuenta corriente: 19390910810099<br></br>CCI: 00219319091081009912<br></br>
        <b>Interbank:</b> N° cuenta corriente: 2003005306300<br></br>CCI: 00320000300530630031<br></br> 
      </div>
    </> : null}
  </>
}

export default ProductListSearch
