import React, { FC } from 'react';
import { Button, Form, Input, Typography } from 'antd';

type TEmailVerificationFormProps = {
  loading: boolean;
  onVerifyCode: (data: { codeAuth: string }) => void;
};

export const EmailVerificationForm: FC<TEmailVerificationFormProps> = ({
  loading,
  onVerifyCode,
}) => {
  const [form] = Form.useForm<{ codeAuth: string }>();

  return (
    <>
      <Form
        name="basic"
        form={form}
        onFinish={onVerifyCode}
        autoComplete="off"
        layout="vertical"
      >
        <Typography.Title level={3} style={{ textAlign: 'center' }}>
          Verificación de correo
        </Typography.Title>

        <Form.Item
          label="Ingrese su código verificación enviado a su correo para continuar"
          name="codeAuth"
          rules={[
            {
              required: true,
              message: 'Campo obligatorio',
            },
          ]}
        >
          <Input
            placeholder="Código de verificación"
            style={{
              borderRadius: '20px',
              borderColor: '#ff9800',
              textAlign: 'center',
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Verificar y crear cuenta
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
