import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'
import { IWarehouseDropShalom, IWarehouseResponse } from './warehouse-list.interface'

export const useWarehousePublicByCountry = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IWarehouseResponse[]>()

  const getWarehouses = () => {
    execute({
      method: 'GET',
      url: '/warehouse/public',
      params: {
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getWarehouses,
    loadingWarehouses: loading,
    warehouses: response ? response : []
  }

}

export const useWarehouseByCountry = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IWarehouseResponse[]>()

  const getWarehouses = () => {
    execute({
      method: 'GET',
      url: '/warehouse',
      params: {
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getWarehouses,
    loadingWarehouses: loading,
    warehouses: response ? response : []
  }

}

export const useWarehouseModList = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<IWarehouseResponse[]>()

  const getWarehouses = () => {

    const cId = companyGlobal ? `${companyGlobal.id}` === '0' ? '' : companyGlobal?.id : authUser.user.company.id

    execute({
      method: 'GET',
      url: '/warehouse',
      params: {
        companyId: authUser.user.role.name === 'STORE' ? authUser.user.company.id : cId,
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getWarehouses,
    loading,
    warehouses: response ? response : []
  }
}

export const useWarehouseList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IWarehouseResponse[]>()

  const getWarehouses = (companyId?: string) => {

    execute({
      method: 'GET',
      url: '/warehouse',
      params: {
        companyId: companyId ? (companyId === '-1' ? '' : companyId) : authUser.user.company.id,
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getWarehouses,
    loading,
    warehouses: response ? response : []
  }
}

export const useWarehouseCompanyList = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<IWarehouseResponse[]>()

  const getWarehouses = () => {
    execute({
      method: 'GET',
      url: '/warehouse',
      params: {
        companyId: companyGlobal ? companyGlobal.id === 0 ? '' : authUser.user.company.id : authUser.user.company.id,
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getWarehouses,
    loading,
    warehouses: response ? response : []
  }
}

export const useWarehouseDropShalom = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IWarehouseDropShalom[]>()

  const getWarehouses = () => {

    execute({
      method: 'GET',
      url: '/warehouse/dropShalom',
      params: {
        countryCode: authUser.user.company.countryCode,
      },
    })
  }

  return {
    getWarehouses,
    loadingWarehouse: loading,
    warehouses: response ? response : []
  }
}
