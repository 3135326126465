/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IOrderRecycleCustomHistory, IOrderUpdateResponse } from '../order-update/order-update.interface'
import { IOrderLevelUser } from './order-level.interface'
import { IOrderRecycleSchema } from './order-recycle.schema'

export const useLevelUser = () => {
  const { execute, response, loading } = useAxios<IOrderLevelUser>()
  const { authUser } = useAuthStore()

  const getLevelUser = () => {
    execute({
      method: 'GET',
      url: '/order/level',
      params: {
        companyId: authUser.user.company.id,
      },
    })
  }

  return {
    getLevelUser,
    loadingLevel: loading,
    level: response,
  }
}

export const useOrderDisableScan = () => {
  const { execute, response, loading } = useAxios<IOrderUpdateResponse>()

  const setDisableScanOrder = (orderId: number) => {
    execute({
      method: 'PATCH',
      url: `/order/disable/scan/${orderId}`,
    })
  }

  return {
    setDisableScanOrder,
    loadingScanUpdated: loading,
    orderScanUpdated: response,
  }
}

export const useOrderRescheduled = () => {
  const { execute, response, loading } = useAxios<IOrderUpdateResponse>()

  const updateValidateOrderRescheduled = (orderDeliveryId: number) => {
    execute({
      method: 'PATCH',
      url: `/order/validate/reschedule/${orderDeliveryId}`,
    })
  }

  return {
    updateValidateOrderRescheduled,
    loadingUpdated: loading,
    orderUpdated: response,
  }
}

export const useOrderRecycle = () => {
  const { execute, response, loading } = useAxios<IOrderUpdateResponse>()

  const updateOrderRecycle = (data: IOrderRecycleSchema) => {
    execute({
      method: 'PATCH',
      url: '/order/recycle/customer-address',
      data: {
        orderId: data.orderId,
        name: data.name,
        lastName: data.lastName,
        address1: data.address,
        address2: data.address1 || '',
        reference: data.reference || '',
        scheduleDate: data.scheduleDate,
        districtCode: `${data.districtCode}`,
        districtName: data.districtName,
        phone: `${data.phone}`,
        lat: data.gps.split(',')[0],
        lng: data.gps.split(',')[1]
      },
    })
  }

  return {
    updateOrderRecycle,
    loadingUpdated: loading,
    orderUpdated: response,
  }
}

export const useOrderRecycleCustomHistory = () => {
  const { execute, response, loading } = useAxios<IOrderRecycleCustomHistory>()

  const getOrderRecycle = (orderId: number) => {
    execute({
      method: 'GET',
      url: '/order/recycle',
      params: {
        id: orderId,
      },
    })
  }

  return {
    getOrderRecycle,
    loadingRecycle: loading,
    orderRecycle: response,
  }
}
