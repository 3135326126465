import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'
import { useConveyorStore } from '../location/location.service'
import { IMotorizedResponse } from './user-motorized.interface'

export const useMotoriedListAll = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingMt } = useAxios<IMotorizedResponse[]>()

  const getMotorizedAll = () => {
    execute({
      method: 'GET',
      url: '/maintenance/user/motorized',
      params: {
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getMotorizedAll,
    loadingMt,
    motorizeds: response ? response : []
  }

}

export const useMotorizedToConveyorList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingMt } = useAxios<IMotorizedResponse[]>()

  const getMotorizeds = (conveyorId?: string) => {
    execute({
      method: 'GET',
      url: '/maintenance/user/conveyor/motorized',
      params: {
        conveyorId: conveyorId === 'ALL' ? '' : conveyorId,
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getMotorizeds,
    loadingMt,
    motorizeds: response ? response : []
  }

}

export const useMotoriedList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingMt } = useAxios<IMotorizedResponse[]>()

  const getMotorizeds = () => {
    execute({
      method: 'GET',
      url: '/maintenance/user/motorized',
      params: {
        companyId: authUser.user.company.id,
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getMotorizeds,
    loadingMt,
    motorizeds: response ? response : []
  }

}

export const useMotorizedStoreList = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading: loadingMtStores } = useAxios<IMotorizedResponse[]>()

  const getMotorizedStores = () => {
    execute({
      method: 'GET',
      url: '/maintenance/user/dispacth',
      params: {
        companyId: companyGlobal ? companyGlobal.id : authUser.user.company.id,
        countryCode: authUser.user.company.countryCode
      },
    })
  }

  return {
    getMotorizedStores,
    loadingMtStores,
    motorizedStores: response ? response : []
  }

}

export const useMotoStoreAll = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingMtStAll } = useAxios<IMotorizedResponse[]>()

  const getMotorizedStoresAll = () => {
    execute({
      method: 'GET',
      url: '/maintenance/user/dispatch/all',
      params: {
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
      },
    })
  }

  return {
    getMotorizedStoresAll,
    loadingMtStAll,
    motorizedStoresAll: response ? response : []
  }

}

export const useMotoToConveyorAll = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingMtStAll } = useAxios<IMotorizedResponse[]>()
  const { conveyorSelected } = useConveyorStore()

  const getMotorizedStoresAll = () => {
    execute({
      method: 'GET',
      url: '/maintenance/user/conveyor/dispatch/all',
      params: {
        countryCode: authUser.user.company.countryCode,
        parentId: authUser.user.company.parentId,
        conveyorId: conveyorSelected ? conveyorSelected.id : authUser.user.conveyor?.id,
        //companyId: companyGlobal ? companyGlobal.id : authUser.user.company.id,
      },
    })
  }

  return {
    getMotorizedStoresAll,
    loadingMtStAll,
    motorizedStoresAll: response ? response : []
  }

}
