import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { ReturnEditForm } from '../../forms';
import { useReturnCreate } from '../../../../../services/return-create/return-create.service';
import { ICreateReturnSchema } from '../../../../../services/return-create/return-create.schema';
import { useReturnStore } from '../../../../../services/return-create/return.store';

const ReturnEditContainer = () => {
  const { saveReturn, returnCreated, loading } = useReturnCreate();
  const { closeDrawer } = useDrawer();
  const { setReturnUpdated } = useReturnStore();

  const handleSubmit = (data: ICreateReturnSchema) => {
    saveReturn(data);
  };

  useEffect(() => {
    if (returnCreated) {
      setReturnUpdated(returnCreated);
      closeDrawer();
    }
  }, [returnCreated]);

  return (
    <>
      <Spin spinning={loading}>
        <ReturnEditForm onSubmit={handleSubmit} />
      </Spin>
    </>
  );
};

export default ReturnEditContainer;
