import { useEffect, useState } from 'react'
import { useAxios } from '../../hooks'
import { IMotorizedOrderGroup, IOrderToReturn, ISummaryProdOrder } from './order-return.interface'
import { useAuthStore } from '../auth/auth.service'

export const useSummaryProdOrderReturn = () => {
  const { execute, response, loading } = useAxios<ISummaryProdOrder[]>()
  
  const getSummaryProdOrderReturn = (email: string) => {
    execute({
      method: 'GET',
      url: `/order/summary-products/to-return/${email}`,
    })
  }
  
  return {
    getSummaryProdOrderReturn,
    loading,
    summaryProducts: response,
  }
}

export const useOrdersToReturn = () => {
  const { execute, response, loading } = useAxios<IOrderToReturn[]>()
  const [ordersMotorized, setOrdersMotorized] = useState<IMotorizedOrderGroup[]>([])
  const { authUser } = useAuthStore()

  const getOrdersToReturn = (startDate: string, endDate: string, 
    dpto: string, motorized: string, companyId: string, conveyorId: string,
    dispatchStatus: string
  ) => {
    execute({
      method: 'GET',
      url: '/order/order-detail/to-return',
      params: {
        startDate: startDate,
        endDate: endDate,
        motorized: motorized === 'ALL' ? '' : motorized,
        department: dpto === 'ALL' ? '' : dpto,
        parentId: authUser.user.company.parentId,
        countryCode: authUser.user.company.countryCode,
        companyId: companyId === '0' ? '' : companyId,
        conveyorId: conveyorId === 'ALL' ? '' : conveyorId,
        dispatchStatus: dispatchStatus === 'ALL' ? '' : dispatchStatus,
      }
    })
  }

  useEffect(() => {
    if (response && response.length > 0) {

      const code = 'motorized'
      const key = code.split('.').length < 0 ? code.split('.')[1] : code.split('.')[0]
      const groupMotorized = function group(array : any) {

        return array.reduce((acc : any, obj : any) => {
          const property = obj[key]
          
          acc[property] = acc[property] || []
          acc[property].push(obj)

          return acc
        }, {})
      }

      const ordersGroup = groupMotorized(response)
      const motorizedGroup: IMotorizedOrderGroup[] = []

      for (const motorized in ordersGroup) {
        if (Object.prototype.hasOwnProperty.call(ordersGroup, motorized)) {

          const value: IMotorizedOrderGroup = {
            motorized: motorized,
            quantityOrders: ordersGroup[motorized].length
          }

          motorizedGroup.push(value)

        }
      }

      setOrdersMotorized(motorizedGroup)

    } else {
      setOrdersMotorized([])
    }
  }, [response])

  return {
    getOrdersToReturn,
    loading,
    orders: response || [],
    ordersMotorized,
  }
}
