import React, { FC, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Divider,
  Form,
  Select,
  Input,
  Upload,
  message,
  Radio,
} from 'antd';
import {
  OrderAgencyPart,
  OrderCustomerPart,
  OrderProductPart,
  OrderAgencyShippingPart,
} from './parts';
import { useProductStore } from '../../../../../services/product/product.store';
import TextArea from 'antd/lib/input/TextArea';
import {
  TRACKING_STATUS_LABEL,
  TRACKING_STATUS_LIST,
} from '../../../../../services/order-create/order.params';
import {
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
} from '../../../../../services/pre-order/pre-order.params';
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema';
import { IOrderItemResponse } from '../../../../../services/order-list/order.interface';
import { useUserSupSeller } from '../../../../../services/order-create/order.edit';
import { useSellerList } from '../../../../../services/user-seller-list/user-seller-list.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { ISellerResponse } from '../../../../../services/user-seller-list/user-seller-list.interface';
import { PAYMENT_TYPE_LABEL } from '../../../../../services/payment-create/payment.params';
import { UploadOutlined } from '@ant-design/icons';
import { storage } from '../../../../../libs/firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { formItemLayout, formLayout } from '../../../../../config/forms-layout';
import dayjs from 'dayjs';

const { Option, OptGroup } = Select;

interface IOrderEditFormProps {
  initialValues: IOrderItemResponse | null;
  onSubmit: (data: IOrderUpdateSchema) => void;
}

const OrderAgencyEditForm: FC<IOrderEditFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const [form] = Form.useForm<IOrderUpdateSchema>();
  const { setProductEmpty } = useProductStore();
  const { isUser } = useUserSupSeller();
  const { getSellers, sellers } = useSellerList();
  const { companyGlobal } = useCompanyStore();
  const seller = Form.useWatch(['user', 'fullname'], form);
  const [useCallStatus, setUseCallStatus] = useState(true);
  const [percent, setPercent] = useState(0);
  const [file, setFile] = useState<any>();
  const [url, setUrl] = useState('');
  const voucherAgency = Form.useWatch(['voucherPayAgency'], form);
  const managementType = Form.useWatch(['managementType'], form);
  const flagManagement = Form.useWatch(['flagManagementType'], form);
  const trackingStatus = Form.useWatch(['trackingStatus'], form);
  const { authUser } = useAuthStore();
  const [blockSave, setBlockSave] = useState(false);
  const blockFields = Form.useWatch(['blockFields'], form);
  const [percentGuide, setPercentGuide] = useState(0);
  const [fileGuide, setFileGuide] = useState<any>();
  const [urlGuide, setUrlGuide] = useState('');
  const guideSecundary = Form.useWatch(['guideSecundary'], form);
  const [levelStore, setLevelStore] = useState(false);
  const [showGuide, setShowGuide] = useState(false);

  useEffect(() => {
    if (seller && sellers.length > 0) {
      const s = sellers.find((i: ISellerResponse) => i.fullname == seller);

      form.setFieldValue(['user', 'id'], s?.id);
    }
  }, [seller, sellers]);

  useEffect(() => {
    form.setFieldValue(['voucherPayAgency'], url);
  }, [url]);

  useEffect(() => {
    form.setFieldValue(['guideSecundary'], urlGuide);
  }, [urlGuide]);

  useEffect(() => {
    if (initialValues === null) {
      setUseCallStatus(true);
      form.setFieldValue(['shipping', 'shippingByAgency'], true);
    } else {
      setUseCallStatus(false);
      if (isUser) {
        getSellers(companyGlobal ? `${companyGlobal.id}` : '');
      }
    }

    if (initialValues) {
      setProductEmpty();

      const shipping = initialValues.shipping;

      form.setFieldsValue({
        status: initialValues.status
          ? initialValues.status
          : 'PENDING_DELIVERY',
        callStatus: initialValues.callStatus,
        dispatchStatus: initialValues.dispatchStatus,
        trackingStatus: initialValues.trackingStatus,
        guideSecundary: initialValues.guideSecundary,
        note: initialValues.note,
        channel: initialValues.channel,
        shippingCost: initialValues.shippingCost,
        managementType: initialValues.managementType,
        voucherPayAgency: initialValues.voucherPayAgency,
        payAgency: initialValues.payAgency,
        user: {
          id: initialValues.user.id,
          fullname: initialValues.user.fullname,
        },
        paymentType: initialValues.paymentType,
        customer: {
          name: initialValues.customerHistory?.name,
          lastName: initialValues.customerHistory?.lastName || '',
          phone: initialValues.customerHistory?.phone,
          companyId: initialValues.customerHistory?.companyId,
        },
        shipping: {
          id: shipping.id,
          address1: shipping?.address1 || '',
          address2: shipping?.address2 || '',
          reference: shipping?.reference,
          shippingByAgency: initialValues.isOrderAgency,
          gps:
            shipping?.lat.length > 0 && shipping?.lng.length > 0
              ? `${shipping?.lat},${shipping?.lng}`
              : '',
          department: shipping?.departmentName,
          departmentId: Number.parseInt(shipping?.departmentCode),
          province: shipping?.provinceName,
          provinceId: Number.parseInt(shipping?.provinceCode),
          district: shipping?.districtName,
          districtId: Number.parseInt(shipping?.districtCode),
          contactName: shipping?.contactName,
          contactPhone: shipping?.contactPhone,
          contactDocumentNumber: shipping?.contactDocumentNumber,
          contactDocumenType: shipping?.contactDocumenType,
          guideNumber: shipping?.guideNumber,
          attachFile: shipping?.attachFile,
          addressPickUp: shipping?.addressPickUp,
          shippingCost: shipping?.shippingCost,
          keyCode: shipping?.keyCode,
          agencyName: shipping?.agencyName,
          scheduleDate: shipping?.scheduleDate
            ? dayjs(shipping.scheduleDate)
            : '',
        },
      });
    } else {
      form.setFieldValue('status', 'PENDING_DELIVERY');
      form.setFieldValue('callStatus', 'CONFIRMED');
      form.setFieldValue('trackingStatus', 'TO_REGISTER');
      form.setFieldValue('managementType', 'PARTNER');
    }
  }, [initialValues]);

  function handleBeforeUpload(file: any) {
    setFile(file);
    if (!file) {
      message.success('Debe seleccionar un archivo');

      return;
    }

    const storageRef = ref(storage, `/files_agency/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercent(percent);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url);
          // eslint-disable-next-line no-console
          console.log('url image', url);
        });
      },
    );

    return false;
  }

  function handleBeforeUploadGuide(file: any) {
    setFileGuide(file);
    if (!file) {
      message.success('Debe seleccionar un archivo');

      return;
    }

    const storageRef = ref(storage, `/files_agency/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercentGuide(percent);
      },
      () => {
        //console.log('error', error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlGuide(url);
          //console.log('url image', url)
        });
      },
    );

    return false;
  }

  useEffect(() => {
    if (trackingStatus) {
      if (managementType === 'PARTNER') {
        if (trackingStatus === TRACKING_STATUS_LIST.TO_REGISTER) {
          setUseCallStatus(false);
        } else {
          setUseCallStatus(true);
        }

        if (
          authUser.user.company.type === 'NORMAL' &&
          (authUser.user.role.name === 'ADMIN_STORE' ||
            authUser.user.role.name === 'SELLER')
        ) {
          if (initialValues) {
            if (
              initialValues.trackingStatus ===
                TRACKING_STATUS_LIST.LEAVE_IN_AGENCY &&
              trackingStatus === TRACKING_STATUS_LIST.LEAVE_IN_AGENCY
            ) {
              setBlockSave(true);
            } else {
              setBlockSave(false);
            }
          } else {
            setBlockSave(false);
          }
        }

        if (
          authUser.user.role.name === 'STORE' ||
          authUser.user.role.name === 'ADMIN' ||
          authUser.user.role.name === 'SUPER_MASTER'
        ) {
          if (trackingStatus === TRACKING_STATUS_LIST.LEAVE_IN_AGENCY) {
            setLevelStore(false);
          } else {
            setLevelStore(true);
          }

          setBlockSave(false);
          form.setFieldValue('blockFields', true);
        } else {
          form.setFieldValue('blockFields', false);
          setLevelStore(false);
        }

        if (
          initialValues &&
          initialValues.trackingStatus === TRACKING_STATUS_LIST.DELIVERED
        ) {
          setBlockSave(true);
        }

        if (
          trackingStatus === TRACKING_STATUS_LIST.LEAVE_IN_AGENCY ||
          trackingStatus === TRACKING_STATUS_LIST.DELIVERED ||
          trackingStatus === TRACKING_STATUS_LIST.DESTINATION
        ) {
          setShowGuide(false);
        } else {
          setShowGuide(true);
        }
      } else {
        if (trackingStatus === TRACKING_STATUS_LIST.LEAVE_IN_AGENCY) {
          setLevelStore(false);
        } else {
          setLevelStore(true);
        }

        setBlockSave(false);
        setShowGuide(false);
      }
    }
  }, [trackingStatus]);

  useEffect(() => {
    if (managementType === 'PARTNER' && !initialValues) {
      form.setFieldValue('callStatus', 'CONFIRMED');
      form.setFieldValue('trackingStatus', 'TO_REGISTER');
    }
  }, [managementType]);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        name="order"
        labelWrap
        onFinish={onSubmit}
        initialValues={{
          channel: 'Shopify',
        }}
        {...formLayout}
      >
        <Form.Item
          name={['blockFields']}
          label="Bloquear campos por roles"
          hidden
        >
          <Radio.Group
            options={[
              { label: 'No', value: false },
              { label: 'Si', value: true },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>

        <Form.Item
          name="callStatus"
          label="Estado llamada"
          rules={[{ required: true, message: '' }]}
        >
          <Select placeholder="Seleccione una opción" disabled={useCallStatus}>
            {ORDER_STATUS_LIST_CONFIRMED.map((status) => (
              <Option key={status.value} value={status.value}>
                {status.label}
              </Option>
            ))}
            <OptGroup label="ORDEN">
              {ORDER_STATUS_LIST_NOT_AVAILABLE.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>

        <Form.Item name="status" label="Estado" hidden>
          <input />
        </Form.Item>

        {managementType === 'PARTNER' ? (
          <>
            <Form.Item name="trackingStatus" label="Estado de seguimiento">
              <Select placeholder="Seleccione una opción">
                {initialValues && initialValues !== null ? (
                  <>
                    {authUser.user.role.name === 'ADMIN' ||
                    authUser.user.role.name === 'STORE' ||
                    authUser.user.role.name === 'SUPER_MASTER' ? (
                      <>
                        {initialValues.trackingStatus ===
                        TRACKING_STATUS_LIST.DELIVERED ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.DELIVERED}
                              value={TRACKING_STATUS_LIST.DELIVERED}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.DELIVERED
                                ].label
                              }
                            </Option>
                          </>
                        ) : (
                          <>
                            {Object.keys(TRACKING_STATUS_LABEL).map((key) => (
                              <Option key={key} value={key}>
                                <Badge
                                  style={{ marginRight: '.5rem' }}
                                  text=""
                                />
                                {TRACKING_STATUS_LABEL[key].label}
                              </Option>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {initialValues.trackingStatus ===
                        TRACKING_STATUS_LIST.TO_REGISTER ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.TO_REGISTER}
                              value={TRACKING_STATUS_LIST.TO_REGISTER}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.TO_REGISTER
                                ].label
                              }
                            </Option>

                            <Option
                              key={TRACKING_STATUS_LIST.REGISTERED}
                              value={TRACKING_STATUS_LIST.REGISTERED}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.REGISTERED
                                ].label
                              }
                            </Option>

                            <Option
                              key={TRACKING_STATUS_LIST.TO_PREPARE}
                              value={TRACKING_STATUS_LIST.TO_PREPARE}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.TO_PREPARE
                                ].label
                              }
                            </Option>
                          </>
                        ) : initialValues.trackingStatus ===
                          TRACKING_STATUS_LIST.REGISTERED ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.REGISTERED}
                              value={TRACKING_STATUS_LIST.REGISTERED}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.REGISTERED
                                ].label
                              }
                            </Option>

                            <Option
                              key={TRACKING_STATUS_LIST.TO_PREPARE}
                              value={TRACKING_STATUS_LIST.TO_PREPARE}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.TO_PREPARE
                                ].label
                              }
                            </Option>
                          </>
                        ) : initialValues.trackingStatus ===
                          TRACKING_STATUS_LIST.TO_PREPARE ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.TO_PREPARE}
                              value={TRACKING_STATUS_LIST.TO_PREPARE}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.TO_PREPARE
                                ].label
                              }
                            </Option>
                          </>
                        ) : initialValues.trackingStatus ===
                          TRACKING_STATUS_LIST.PREPARED ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.PREPARED}
                              value={TRACKING_STATUS_LIST.PREPARED}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.PREPARED
                                ].label
                              }
                            </Option>

                            <Option
                              key={TRACKING_STATUS_LIST.LEAVE_IN_AGENCY}
                              value={TRACKING_STATUS_LIST.LEAVE_IN_AGENCY}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.LEAVE_IN_AGENCY
                                ].label
                              }
                            </Option>
                          </>
                        ) : initialValues.trackingStatus ===
                          TRACKING_STATUS_LIST.LEAVE_IN_AGENCY ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.LEAVE_IN_AGENCY}
                              value={TRACKING_STATUS_LIST.LEAVE_IN_AGENCY}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.LEAVE_IN_AGENCY
                                ].label
                              }
                            </Option>

                            <Option
                              key={TRACKING_STATUS_LIST.DESTINATION}
                              value={TRACKING_STATUS_LIST.DESTINATION}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.DESTINATION
                                ].label
                              }
                            </Option>

                            <Option
                              key={TRACKING_STATUS_LIST.DELIVERED}
                              value={TRACKING_STATUS_LIST.DELIVERED}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.DELIVERED
                                ].label
                              }
                            </Option>
                          </>
                        ) : initialValues.trackingStatus ===
                          TRACKING_STATUS_LIST.DESTINATION ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.DESTINATION}
                              value={TRACKING_STATUS_LIST.DESTINATION}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.DESTINATION
                                ].label
                              }
                            </Option>

                            <Option
                              key={TRACKING_STATUS_LIST.DELIVERED}
                              value={TRACKING_STATUS_LIST.DELIVERED}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.DELIVERED
                                ].label
                              }
                            </Option>
                          </>
                        ) : initialValues.trackingStatus ===
                          TRACKING_STATUS_LIST.DELIVERED ? (
                          <>
                            <Option
                              key={TRACKING_STATUS_LIST.DELIVERED}
                              value={TRACKING_STATUS_LIST.DELIVERED}
                            >
                              <Badge style={{ marginRight: '.5rem' }} text="" />
                              {
                                TRACKING_STATUS_LABEL[
                                  TRACKING_STATUS_LIST.DELIVERED
                                ].label
                              }
                            </Option>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Option
                      key={TRACKING_STATUS_LIST.TO_REGISTER}
                      value={TRACKING_STATUS_LIST.TO_REGISTER}
                    >
                      <Badge style={{ marginRight: '.5rem' }} text="" />
                      {
                        TRACKING_STATUS_LABEL[TRACKING_STATUS_LIST.TO_REGISTER]
                          .label
                      }
                    </Option>
                  </>
                )}
              </Select>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name="trackingStatus" label="Estado de seguimiento">
              <Select placeholder="Seleccione una opción">
                {Object.keys(TRACKING_STATUS_LABEL).map((key) => (
                  <Option key={key} value={key}>
                    <Badge style={{ marginRight: '.5rem' }} text="" />
                    {TRACKING_STATUS_LABEL[key].label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item label="Nota" name="note">
          <TextArea rows={1} />
        </Form.Item>

        <Form.Item label="Costo de entrega" name="shippingCost" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="channel"
          label="Canal"
          rules={[{ required: true, message: '' }]}
        >
          <Select
            disabled={!!initialValues}
            placeholder="Seleccione una opción"
          >
            <Option value="Shopify">Shopify</Option>
            <Option value="Whatsapp">Whatsapp</Option>
            <Option value="Llamada">Llamada</Option>
            <Option value="Redes sociales">Redes sociales</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Tipo de gestión"
          name={['managementType']}
          rules={[{ required: true, message: '' }]}
        >
          <Select
            disabled={blockSave || flagManagement}
            placeholder="Seleccione una opción"
          >
            <Option value="OWN">Propia</Option>
            <Option value="PARTNER">Drop Shalom</Option>
          </Select>
        </Form.Item>

        <Form.Item name={['user', 'id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['dispatchStatus']} hidden>
          <Input />
        </Form.Item>

        {initialValues && isUser ? (
          <>
            <Form.Item name={['user', 'fullname']} label="Vendedor">
              <Select
                placeholder="Seleccione un vendedor"
                disabled={blockFields}
              >
                {sellers.map((s: ISellerResponse) => (
                  <Option key={s.id} value={s.fullname}>
                    {s.fullname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : null}

        <Divider />

        <OrderCustomerPart disabled={blockFields} />

        <OrderProductPart
          form={form}
          orderDetails={initialValues?.orderDetails}
          warName={initialValues?.warehouseName}
        />

        {showGuide ? (
          <></>
        ) : (
          <>
            <Divider />
          </>
        )}

        {managementType === 'PARTNER' ? (
          <>
            <Form.Item
              name={['voucherPayAgency']}
              label="Subir voucher de pago:"
            >
              <Upload
                name="file"
                beforeUpload={handleBeforeUpload}
                maxCount={1}
                fileList={[]}
                disabled={blockFields}
              >
                <Button disabled={blockFields}>
                  <UploadOutlined /> {file ? file.name : ''} {percent} %
                </Button>
              </Upload>
              {voucherAgency?.length !== 0 ? (
                <>
                  <a
                    href={voucherAgency}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    Ver voucher
                  </a>
                </>
              ) : null}
            </Form.Item>
          </>
        ) : null}

        <Form.Item
          name={['guideSecundary']}
          label="Guía dejada en agencia"
          hidden={showGuide}
          rules={[
            {
              required:
                trackingStatus === TRACKING_STATUS_LIST.LEAVE_IN_AGENCY &&
                managementType === 'PARTNER',
              message: '',
            },
          ]}
        >
          <Upload
            name="file"
            beforeUpload={handleBeforeUploadGuide}
            maxCount={1}
            fileList={[]}
            disabled={levelStore}
          >
            <Button disabled={levelStore}>
              <UploadOutlined /> {fileGuide ? fileGuide.name : ''}{' '}
              {percentGuide} %
            </Button>
          </Upload>
          {guideSecundary?.length !== 0 ? (
            <>
              <a
                href={guideSecundary}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                Ver guía
              </a>
            </>
          ) : null}
        </Form.Item>

        <Divider />

        <Form.Item
          label="Tipo de pago"
          name={['paymentType']}
          rules={[{ required: true, message: 'Seleccione un tipo de pago' }]}
        >
          <Select placeholder="Tipo de pago" disabled={blockFields}>
            {Object.keys(PAYMENT_TYPE_LABEL).map((key) => (
              <Option key={key} value={key}>
                {PAYMENT_TYPE_LABEL[key].label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />

        <OrderAgencyShippingPart form={form} />
        <Divider />

        <OrderAgencyPart form={form} />
        <Divider />

        <Form.Item {...formItemLayout}>
          <Button type="primary" htmlType="submit" block hidden={blockSave}>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OrderAgencyEditForm;
