import React from 'react'
import { UsersTransporterContainer } from './containers'

const UsersTransporterView = () => {
  return (
    <>
      <UsersTransporterContainer/>
    </>
  )
}

export default UsersTransporterView
