import { useAxios } from '../../hooks'
import { ICoverageItemResponse } from '../coverage-create/coverage-create.interface'
import { ICoverageCreateSchema } from '../coverage-create/coverage-create.schema'

export const useCoverageUpdate = () => {
  const { execute, response, loading } = useAxios<ICoverageItemResponse>()

  const updateCoverage = (id: number) => {
    execute({
      method: 'PATCH',
      url: `/transport/ubigeo/deleted/${id}`,
      data: {
        isActive: false
      },
    })
  }

  return {
    updateCoverage,
    loading,
    coverageUpdated: response
  }
}

export const useCoverageUbigeoUpdate = () => {
  const { execute, response, loading } = useAxios<ICoverageItemResponse>()

  const updateCoverageUbigeo = (data: ICoverageCreateSchema) => {
    execute({
      method: 'PATCH',
      url: `/transport/ubigeo/${data.id}`,
      data: {
        warehouses: data.warehouseIds,
        deliveryCost: data.deliveryCost,
        addDays: data.addDays,
        deliveryCostPremium: data.deliveryCostPremium,
        returnCost: data.returnCost,
        returnCostPremium: data.returnCostPremium,
        motorizedCost: data.motorizedCost,
      },
    })
  }

  return {
    updateCoverageUbigeo,
    loadingUbigeo: loading,
    coverageUbigeoUpdated: response
  }
}
