/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFrequentQuestion } from '../../../../services/frequent-question-list/frequent-question.interface';

/**
 * Group questions by module
 * @param questions IFrequentQuestion[]
 * @returns { module: string, questions: IFrequentQuestion[] }[]
 */
export const questionGroupByModule = (questions: IFrequentQuestion[]) => {
  if (questions?.length === 0) {
    return [];
  }

  const groupByModule = questions.reduce((acc: any, item) => {
    const module = item.module || 'General';

    if (!acc[module]) {
      acc[module] = [];
    }

    acc[module].push(item);

    return acc;
  }, {});

  const questionGroupedArray = Object.keys(groupByModule).map((key) => ({
    module: key,
    questions: groupByModule[key],
  }));

  /**
   * If the module has no questions, filter it and return only the modules with questions
   */
  return questionGroupedArray.filter((q) => q.questions?.length);
};
