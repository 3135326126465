//import { Alert } from 'antd'
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../routes/routes.param';
import { IAuthRequest } from '../../../../services/auth/auth.interface';
import { useAuthentication } from '../../../../services/auth/auth.service';
import { SignInForm } from '../../forms';
import { useDrawer } from '../../../../contexts/DrawerContext';

const SignInContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { getAuthentication, loading, authUser } = useAuthentication();
  const handleSubmit = (auth: IAuthRequest) => {
    getAuthentication(auth);
  };
  const { openDrawer } = useDrawer();
  const [openSignUp, setOpenSignUp] = useState<boolean>(false);

  useEffect(() => {
    const regex = /^ALICLIK-\d+$/;

    if (params.code && params.code.length > 0) {
      if (regex.test(params.code)) {
        localStorage.setItem('codeReference', params.code);
        setOpenSignUp(true);
      } else {
        navigate(ROUTES.SIGN_IN.PATH, { replace: true });
      }
    }
  }, [params.code]);

  useEffect(() => {
    if (openSignUp) {
      openDrawer('REGISTER_USER', 'Registrarse');
    }
  }, [openSignUp]);

  useEffect(() => {
    if (authUser) {
      if (
        authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'SELLER'
      ) {
        if (authUser.user.daysCreated > 15) {
          navigate(ROUTES.ALINEWS.PATH, { replace: true });
        } else {
          navigate(ROUTES.FREQUENT_QUESTION.PATH, { replace: true });
        }
      } else if (authUser.user.role.name === 'STORE') {
        navigate('/order/orders', { replace: true });
      } else {
        navigate(ROUTES.DASHBOARD.PATH, { replace: true });
      }
    }
  }, [authUser]);

  return <SignInForm onSubmit={handleSubmit} loading={loading} />;
};

export default SignInContainer;
