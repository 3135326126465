import { useAuthStore } from '../auth/auth.service'

export const useMovementEdit = () => {
  const { roleUser } = useAuthStore()
  let companyUser = 0
  let isEditable = true

  if (roleUser?.name === 'ADMIN_STORE') {
    companyUser = 2
    isEditable = false
  } else if (roleUser?.name === 'ADMIN' || roleUser?.name === 'SUP_SELLER' || roleUser?.name === 'STORE'
    || roleUser?.name === 'SUPER_MASTER'
  ) {
    companyUser = 1
    isEditable = false
  } else if (roleUser?.name === 'MASTER') {
    isEditable = false
    companyUser = 3
  } else if (roleUser?.name === 'SELLER') {
    companyUser = 2
    isEditable = true
  }

  return { companyUser, isEditable }
}
