import { IFrequentQuestion } from '../../../../services/frequent-question-list/frequent-question.interface';

/**
 * Filter questions by role or public
 * @param data IFrequentQuestion[]
 * @param rolName string
 * @returns IFrequentQuestion[]
 */
export const getQuestionFiltered = (
  data: IFrequentQuestion[],
  rolName: string,
): IFrequentQuestion[] => {
  /**
   * Si no hay un rol, se filtran las preguntas que son publicas
   */
  if (!rolName) {
    return data.filter((item) => item.isPublic);
  }

  return data.filter((item) => item.roles?.includes(rolName) || item.isPublic);
};
