import React from 'react'
import { TransportersMaintContainer } from './containers'

const TransportersMaintView = () => {
  return (
    <>
      <TransportersMaintContainer />
    </>
  )
}

export default TransportersMaintView
