/* eslint-disable max-len */
import { useAxios } from '../../hooks';
import { useAuthStore } from '../auth/auth.service';
import {
  IOrderDeliveryFilter,
  IOrderDeliveryResumen,
  IOrderDeliveryResponse,
} from './order-motorized.interface';
import { useEffect, useState } from 'react';
import { ORDER_STATUS_LIST } from '../order-create/order.params';
import { CALL_STATUS_LIST } from '../pre-order/pre-order.params';
import dayjs from 'dayjs';

export const useOrderMotorizedList = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IOrderDeliveryResponse[]>();
  const [orders, setOrders] = useState<IOrderDeliveryResponse[]>();
  const [ordersOriginal, setOrdersOriginal] =
    useState<IOrderDeliveryResponse[]>();
  const [resumen, setResumen] = useState<IOrderDeliveryResumen>();

  const getOrders = (filter?: IOrderDeliveryFilter) => {
    execute({
      method: 'GET',
      url: '/order/motorized',
      params: {
        status: 'PENDING_DELIVERY',
        callStatus: CALL_STATUS_LIST.CONFIRMED,
        companyId: filter?.companyId || authUser.user.company.id,
        createdAtFrom: filter?.createdAtFrom || dayjs().endOf('day').toJSON(),
        createdAtTo: filter?.createdAtTo || dayjs().startOf('day').toJSON(),
        provinceCode:
          filter?.provinceCode === 'ALL' ? '' : filter?.provinceCode,
        isOrderAgency: filter?.isOrderAgency === 'FALSE' ? '0' : '1',
        countryCode: authUser.user.company.countryCode,
        conveyorId: filter?.conveyorId === 'ALL' ? '' : filter?.conveyorId,
      },
    });
  };

  const orderFilter = () => {
    const orderProcessed = mapToOrderTable(
      ordersOriginal as IOrderDeliveryResponse[],
    );

    setOrders(orderProcessed.orders);
    setResumen(orderProcessed.resumen);
  };

  useEffect(() => {
    if (response) {
      const orderProcessed = mapToOrderTable(response);

      setOrdersOriginal(orderProcessed.orders);
      setOrders(orderProcessed.orders);
      setResumen(orderProcessed.resumen);
    }
  }, [response]);

  return {
    getOrders,
    loading,
    orders,
    resumen,
    orderFilter,
  };
};

const mapToOrderTable = (
  orders: IOrderDeliveryResponse[],
): {
  orders: IOrderDeliveryResponse[];
  resumen: IOrderDeliveryResumen;
} => {
  let totalAmount = 0;
  let totalOrder = 0;

  const ordersMapped = orders
    .map((orderLocation) => {
      // Mapea campos de segundo nivel
      return {
        ...orderLocation,
        orders: orderLocation.orders.map((item) => {
          const totalOrder = item.orders.length;
          const totalCompleted = item.orders.filter(
            (e) => e.status !== ORDER_STATUS_LIST.PENDING_DELIVERY,
          ).length;
          const totalDelivered = item.orders.filter(
            (e) => e.status === ORDER_STATUS_LIST.DELIVERED,
          ).length;
          const totalRefused = item.orders.filter(
            (e) => e.status === ORDER_STATUS_LIST.REFUSED,
          ).length;
          const totalRescheduled = item.orders.filter(
            (e) => e.status === ORDER_STATUS_LIST.RESCHEDULED,
          ).length;
          const ratioCompleted = Math.round(
            (totalCompleted / totalOrder) * 100,
          );
          const ratioDelivered = Math.round(
            (totalDelivered / totalOrder) * 100,
          );
          const ratioRefused = Math.round((totalRefused / totalOrder) * 100);
          const ratioRescheduled = Math.round(
            (totalRescheduled / totalOrder) * 100,
          );

          return {
            ...item,
            totalAmount: item.orders?.length
              ? item.orders.map((e) => e.total).reduce((a, b) => a + b)
              : 0,
            currency: item.orders[0]?.currency,
            totalOrder,
            totalCompleted,
            totalDelivered,
            totalRefused,
            totalRescheduled,
            ratioCompleted,
            ratioDelivered,
            ratioRefused,
            ratioRescheduled,
          };
        }),
      };
    })
    .map((orderLocation) => {
      const totalAmount = orderLocation.orders
        .map((e) => e.totalAmount)
        ?.reduce((a, b) => a + b);
      const totalOrder = orderLocation.orders
        .map((e) => e.totalOrder)
        ?.reduce((a, b) => a + b);

      return {
        ...orderLocation,
        currency: orderLocation.orders[0]?.currency,
        totalAmount,
        totalOrder,
      };
    })
    .map((orderLocation) => {
      return {
        ...orderLocation,
        orders: orderLocation.orders.map((item) => {
          const ratioAmount = Math.round(
            (item.totalAmount / orderLocation.totalAmount) * 100,
          );

          return {
            ...item,
            ratioAmount,
          };
        }),
      };
    })
    .map((orderLocation) => {
      // Mapea campos de primer nivel
      totalAmount = totalAmount + orderLocation.totalAmount;
      totalOrder = totalOrder + orderLocation.totalOrder;

      return {
        ...orderLocation,
        orders: orderLocation.orders.filter((e) => e.totalOrder),
      };
    })
    .map((orderLocation) => {
      // Mapea campos de primer nivel
      return {
        ...orderLocation,
        ratioAmount: Math.round(
          (orderLocation.totalAmount / totalAmount) * 100,
        ),
      };
    })
    .filter((e) => e.totalOrder);

  const resumen: IOrderDeliveryResumen = {
    totalOrder,
    totalAmount,
    currency: ordersMapped[0]?.currency,
  };

  return {
    orders: ordersMapped,
    resumen,
  };
};
