import { useState, useEffect } from 'react'
import { useAxios } from '../../hooks'
import { IAuthUserResponse } from '../auth/auth.interface'
import { useAuthStore } from '../auth/auth.service'
import { ITransporterResponse } from '../transporter/transporter.interface'

// Hook básico para obtener transportistas sin paginación
export const useTransporterList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<{ transports: ITransporterResponse[], totalCount: number }>()

  const getTransporters = () => {
    execute({
      method: 'GET',
      url: '/transport',
      params: {
        countryCode: authUser.user.company.countryCode,
      }
    })
  }

  return {
    getTransporters,
    loadingTransporters: loading,
    transporters: response ? response.transports : []
  }
}

// Hook para obtener transportistas con paginación
export const useTransportersPagination = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<{ transports: ITransporterResponse[], totalCount: number }>()

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  const getTransporters = (isActive: boolean, search = '', pageNumber = 1) => {
    setPage(pageNumber)
    execute({
      method: 'GET',
      url: '/transport',
      params: {
        countryCode: authUser.user.company.countryCode,
        isActive,
        search,
        page: pageNumber,
      }
    })
  }

  useEffect(() => {
    if (response) {
      setCount(response.totalCount || 0)
    }
  }, [response])

  return {
    getTransporters,
    loadingTransporters: loading,
    transporters: response ? response.transports : [],
    count,
    page,
  }
}

// Hook para obtener usuarios asociados a un transportista
export const useUserToTransporterList = () => {
  const { execute, response, loading } = useAxios<IAuthUserResponse[]>()

  const getUsersToTransporter = (search: string, conveyorId: string) => {
    
    execute({
      method: 'GET',
      url: '/maintenance/user/transporter',
      params: {
        search: search,
        transporterId: conveyorId === 'ALL' ? '' : conveyorId
      }
    })
  }

  return {
    getUsersToTransporter,
    loading: loading,
    users: response ? response : []
  }
}
