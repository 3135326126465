import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Divider, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useAuthStore,
  useLogout,
} from '../../../../services/auth/auth.service';
import { IRoutePath, ROUTES } from '../../../../routes/routes.param';
import { filterRoutesByPermission } from '../../utils';

interface IMenu {
  key: string;
  label: ReactNode;
  children?: IMenu[];
}

interface ISidebarMenuProps {
  onSelect: () => void;
}

export const SidebarMenu: FC<ISidebarMenuProps> = ({ onSelect }) => {
  const { authUser } = useAuthStore();
  const { logout, error, response } = useLogout();
  const navigate = useNavigate();
  const location = useLocation();
  const [menu, setMenu] = useState<IMenu[]>([]);
  const locationSplit = location.pathname.split('/').filter((e) => e);
  const selectedKeyModule = locationSplit[0];
  const selectedKeySubmenu = locationSplit[1];

  const handleGoToNavigation = (path: string) => {
    navigate(path);
    onSelect();
  };

  const handleLogout = () => {
    logout();
  };

  const renderMenuItem = (module: IRoutePath) => {
    return (
      <>
        {module.ICON && (
          <span style={{ marginRight: '6px' }}>{module.ICON}</span>
        )}{' '}
        {module.TITLE}
        {module.TAG}
      </>
    );
  };

  useEffect(() => {
    if (response || error) {
      navigate(ROUTES.SIGN_IN.PATH);
    }
  }, [response, error]);

  useEffect(() => {
    const routesFiltered = filterRoutesByPermission(
      Object.values(ROUTES),
      authUser.user.role.permissions,
    );
    const menuTemp: IMenu[] = routesFiltered.map((mod) => {
      const myMenu: IMenu = {
        key: mod.PATH,
        label: mod.CHILDREN ? (
          renderMenuItem(mod)
        ) : (
          <div onClick={() => handleGoToNavigation(mod.PATH)}>
            {renderMenuItem(mod)}
          </div>
        ),
      };

      if (mod.CHILDREN) {
        const items: IMenu[] = [];

        mod.CHILDREN.forEach((sub) => {
          const value = {
            key: sub.PATH,
            label: (
              <div
                onClick={() => handleGoToNavigation(`${mod.PATH}/${sub.PATH}`)}
              >
                {/* TODO: Review this part {sub.TITLE} */}
                {renderMenuItem(sub)}
              </div>
            ),
          };

          /**
           * TODO: Refactor this part
           */
          if (authUser.user.company.countryCode === 'PER') {
            if (value.key === 'orders-shopify') {
              if (authUser.user.company.configShopify === true) {
                items.push(value);
              }
            } else if (value.key === 'pre-orders') {
              // block
            } else if (value.key === 'manage') {
              if (
                authUser.user.role.name === 'ADMIN' ||
                authUser.user.role.name === 'MASTER' ||
                authUser.user.role.name === 'SUPER_MASTER' ||
                (authUser.user.isCatalogAdmin &&
                  authUser.user.isCatalogAdmin === true)
              ) {
                items.push(value);
              }
            } else {
              items.push(value);
            }
          } else if (authUser.user.company.countryCode === 'BOL') {
            if (
              value.key === 'pre-orders' ||
              value.key === 'return-management'
            ) {
              // block
            } else {
              items.push(value);
            }
          }
        });

        myMenu.children = items;
      }

      return myMenu;
    });

    setMenu(menuTemp);
  }, []);

  return (
    <>
      <Menu
        mode="inline"
        defaultOpenKeys={[selectedKeySubmenu ? `/${selectedKeyModule}` : '']}
        selectedKeys={[
          selectedKeySubmenu ? selectedKeySubmenu : `/${selectedKeyModule}`,
        ]}
        items={menu}
      />

      <Divider />

      <div>
        <Button
          type="link"
          onClick={handleLogout}
          style={{
            fontSize: '15px',
            color: 'black',
            marginLeft: '20px',
            marginTop: '15px',
          }}
        >
          Cerrar Sesión
        </Button>
      </div>
    </>
  );
};
