import { ISignUpSchema } from './sign-up.schema';
import { ISignUpRequest } from './sign-up.interface';

const mapToSignUpRequest = (signUp: ISignUpSchema): ISignUpRequest => {
  return {
    email: signUp.email,
    password: signUp.password,
    phone: signUp.phone,
    companyName: signUp.companyName,
    fullname: signUp.fullname,
    userMail: signUp.userMail,
    referenceCode: signUp.referenceCode,
    termsAcceptedDate: signUp.acceptTerms ? new Date().toISOString() : '',
    termsAcceptedVersion: signUp.acceptTerms ? '1.0' : '',
    countryPhoneId: signUp.countryPhoneId,
  };
};

export { mapToSignUpRequest };
