import React, { FC } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import { IOrderFilter } from '../../../../../services/order-list/order.interface';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';

interface IOrderFilterFormProps {
  onChange: (filter: IOrderFilter) => void;
}

const OrderFilterForm: FC<IOrderFilterFormProps> = ({ onChange }) => {
  const handleChange: DatePickerProps['onChange'] = (date) => {
    onChange({
      createdAtFrom: date?.endOf('day').toJSON(),
      createdAtTo: date?.startOf('day').toJSON(),
    });
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().startOf('day').subtract(2, 'days');
  };

  return (
    <>
      <DatePicker
        disabledDate={disabledDate}
        placeholder="Día entrega"
        onChange={handleChange}
        defaultValue={dayjs()}
      />
    </>
  );
};

export default OrderFilterForm;
