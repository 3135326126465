import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Badge,
  Tag,
  Table,
  Upload,
  message,
  InputNumber,
  Image,
  DatePicker,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TextArea from 'antd/lib/input/TextArea';
import { ICreateDispatchGuideSchema } from '../../../../../services/dispatch-guide-create/dispatch-guide-create.schema';
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store';
import {
  useConveyorStore,
  useLocationLevel1,
  useLocationLevel2,
} from '../../../../../services/location/location.service';
import { useMotoToConveyorAll } from '../../../../../services/user-motorized/user-motorized.service';
import {
  DISPATCH_STATUS_LABEL,
  DISPATCH_STATUS_LIST,
} from '../../../../../services/dispatch-guide-create/dispatch.params';
import { useSummaryProducts } from '../../../../../services/summary-products/summary-products.service';
import { ISummaryProductResponse } from '../../../../../services/summary-products/summary-products.interface';
import { UploadOutlined } from '@ant-design/icons';
import { storage } from '../../../../../libs/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useWarehouseCompanyList } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { IWarehouseResponse } from '../../../../../services/warehouse-list/warehouse-list.interface';
import dayjs from 'dayjs';

const { Option } = Select;

interface IDispatchGuideEditFormProps {
  onSubmit: (data: ICreateDispatchGuideSchema) => void;
}

const DispatchGuideEditForm: FC<IDispatchGuideEditFormProps> = ({
  onSubmit,
}) => {
  const [form] = Form.useForm<ICreateDispatchGuideSchema>();
  const { dispatchGuideSelected } = useDispatchGuideStore();
  const { getWarehouses, warehouses, loading } = useWarehouseCompanyList();
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } =
    useLocationLevel2();
  const warId = Form.useWatch(['warehouseId'], form);
  const motoOriginId = Form.useWatch(['motorizedSenderId'], form);
  const motoDestinyId = Form.useWatch(['motorizedRecipientId'], form);
  const departmentOriginId = Form.useWatch(['departmentOriginCode'], form);
  const provinceOriginId = Form.useWatch(['provinceOriginCode'], form);
  const departmentId = Form.useWatch(['departmentCode'], form);
  const provinceId = Form.useWatch(['provinceCode'], form);
  const status = Form.useWatch(['dispatchStatus'], form);
  const { getMotorizedStoresAll, loadingMtStAll, motorizedStoresAll } =
    useMotoToConveyorAll();
  const { getSummaryProducts, summaryProducts } = useSummaryProducts();
  const [disabled, setDisabled] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [adminRegistered, setAdminRegistered] = useState(false);
  const [adminAgency, setAdminAgency] = useState(false);
  const [saved, setSaved] = useState(false);
  const urlVoucher = Form.useWatch(['urlVoucher'], form);
  const photoMerchandiseOrigin = Form.useWatch(
    ['photoMerchandiseOrigin'],
    form,
  );
  const photoMerchandiseDestination = Form.useWatch(
    ['photoMerchandiseDestination'],
    form,
  );
  const photoReturnOrigin = Form.useWatch(['photoReturnOrigin'], form);
  const photoReturnDestination = Form.useWatch(
    ['photoReturnDestination'],
    form,
  );
  const [percent, setPercent] = useState(0);
  const [file, setFile] = useState<any>();
  const [url, setUrl] = useState('');
  const [percentGuide, setPercentGuide] = useState(0);
  const [fileGuide, setFileGuide] = useState<any>();
  const [urlGuide, setUrlGuide] = useState('');
  const [percentMerchandise, setPercentMerchandise] = useState(0);
  const [fileMerchandise, setFileMerchandise] = useState<any>();
  const [urlMerchandise, setUrlMerchandise] = useState('');
  const [percentMerchandiseD, setPercentMerchandiseD] = useState(0);
  const [fileMerchandiseD, setFileMerchandiseD] = useState<any>();
  const [urlMerchandiseD, setUrlMerchandiseD] = useState('');
  const [percentDest, setPercentDest] = useState(0);
  const [fileDest, setFileDest] = useState<any>();
  const [urlMerchandiseDest, setUrlMerchandiseDest] = useState('');
  const { orderDeliveryChecked } = useOrderStore();
  const { authUser } = useAuthStore();
  const { conveyorSelected } = useConveyorStore();

  useEffect(() => {
    if (url.length > 0) {
      form.setFieldValue(['urlVoucher'], url);
    }
  }, [url]);

  useEffect(() => {
    if (urlGuide.length > 0) {
      form.setFieldValue(['photoReturnOrigin'], urlGuide);
    }
  }, [urlGuide]);

  useEffect(() => {
    if (urlMerchandise.length > 0) {
      form.setFieldValue(['photoMerchandiseOrigin'], urlMerchandise);
    }
  }, [urlMerchandise]);

  useEffect(() => {
    if (urlMerchandiseDest.length > 0) {
      form.setFieldValue(['photoReturnDestination'], urlMerchandiseDest);
    }
  }, [urlMerchandiseDest]);

  useEffect(() => {
    if (urlMerchandiseD.length > 0) {
      form.setFieldValue(['photoMerchandiseDestination'], urlMerchandiseD);
    }
  }, [urlMerchandiseD]);

  function handleBeforeUpload(file: any) {
    setFile(file);
    if (!file) {
      message.success('Debe seleccionar un archivo');

      return;
    }

    const storageRef = ref(storage, `/files_dispatch/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercent(percent);
      },
      (_) => {
        //console.log('error', error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url);
          //console.log('url image', url)
        });
      },
    );

    return false;
  }

  function handleBeforeUploadMerchandiseOrigin(file: any) {
    setFileMerchandise(file);
    if (!file) {
      message.success('Debe seleccionar una imagen');

      return;
    }

    const storageRef = ref(storage, `/files_dispatch/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercentMerchandise(percent);
      },
      (_) => {
        //console.log('error', error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlMerchandise(url);
        });
      },
    );

    return false;
  }

  function handleBeforeUploadGuide(fileGuide: any) {
    setFileGuide(fileGuide);
    if (!fileGuide) {
      message.success('Debe seleccionar un archivo');

      return;
    }

    const storageRef = ref(storage, `/files_dispatch/${fileGuide?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileGuide);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercentGuide(percent);
      },
      (_) => {
        //console.log('error', error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlGuide(url);
          //console.log('url image', url)
        });
      },
    );

    return false;
  }

  function handleBeforeUploadMerchandiseDestination(file: any) {
    setFileMerchandiseD(file);
    if (!file) {
      message.success('Debe seleccionar una imagen');

      return;
    }

    const storageRef = ref(storage, `/files_dispatch/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercentMerchandiseD(percent);
      },
      (_) => {
        //console.log('error', error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlMerchandiseD(url);
        });
      },
    );

    return false;
  }

  function handleBeforeUploadGuideDest(fileGuide: any) {
    setFileDest(fileGuide);
    if (!fileGuide) {
      message.success('Debe seleccionar un image');

      return;
    }

    const storageRef = ref(storage, `/files_dispatch/${fileGuide?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileGuide);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercentDest(percent);
      },
      (_) => {
        //console.log('error', error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlMerchandiseDest(url);
          //console.log('url image', url)
        });
      },
    );

    return false;
  }

  const handleChangeDepartment = (ubigeoId: number) => {
    getLocationsGeoLevel2({
      parentId: ubigeoId,
    });
  };

  const handleChangeDepartmentDestination = (ubigeoId: number) => {
    getLocationsGeoLevel2({
      parentId: ubigeoId,
    });
  };

  useEffect(() => {
    const dptos = locationsGeolevel1
      ? locationsGeolevel1.filter((f) => f.flagContraentrega)
      : [];

    if (departmentOriginId && dptos.length > 0) {
      const dpto = dptos.find((d) => `${d.id}` === `${departmentOriginId}`);

      if (dpto) {
        form.setFieldValue(['departmentOriginName'], dpto?.name);
        handleChangeDepartment(dpto.id);
      }
    }
  }, [departmentOriginId, locationsGeolevel1]);

  useEffect(() => {
    const provinces = locationsGeolevel2
      ? locationsGeolevel2.filter((f) => f.flagContraentrega)
      : [];

    if (provinceOriginId && provinces.length > 0) {
      const prov = provinces.find((d) => `${d.id}` === `${provinceOriginId}`);

      if (prov) {
        form.setFieldValue(['provinceOriginName'], prov.name);
      }
    }
  }, [provinceOriginId, locationsGeolevel2]);

  useEffect(() => {
    const dptos = locationsGeolevel1
      ? locationsGeolevel1.filter((f) => f.flagContraentrega)
      : [];

    if (departmentId && dptos.length > 0) {
      const dpto = dptos.find((d) => `${d.id}` === `${departmentId}`);

      if (dpto) {
        form.setFieldValue(['departmentName'], dpto?.name);
        handleChangeDepartment(dpto.id);
      }
    }
  }, [departmentId, locationsGeolevel1]);

  useEffect(() => {
    const provinces = locationsGeolevel2
      ? locationsGeolevel2.filter((f) => f.flagContraentrega)
      : [];

    if (provinceId && provinces.length > 0) {
      const prov = provinces.find((d) => `${d.id}` === `${provinceId}`);

      if (prov) {
        form.setFieldValue(['provinceName'], prov.name);
      }
    }
  }, [provinceId, locationsGeolevel2]);

  useEffect(() => {
    getLocationsGeoLevel1();
    getMotorizedStoresAll();
  }, []);

  useEffect(() => {
    if (motoOriginId) {
      const moto = motorizedStoresAll.find((m) => m.id === motoOriginId);

      form.setFieldValue(['motorizedSender'], {
        id: moto?.id,
        name: moto?.email,
      });
    }
  }, [motoOriginId, motorizedStoresAll]);

  useEffect(() => {
    if (motoDestinyId) {
      const moto = motorizedStoresAll.find((m) => m.id === motoDestinyId);

      form.setFieldValue(['motorizedRecipient'], {
        id: moto?.id,
        name: moto?.email,
      });
    }
  }, [motoDestinyId, motorizedStoresAll]);

  useEffect(() => {
    getWarehouses();

    if (dispatchGuideSelected) {
      if (
        dispatchGuideSelected.dispatchStatus === DISPATCH_STATUS_LIST.DELIVERED
      ) {
        setDisabled(true);
        setUpdateStatus(true);
        setAdminRegistered(true);
        setSaved(false);
        setAdminAgency(true);
      } else if (
        dispatchGuideSelected.dispatchStatus === DISPATCH_STATUS_LIST.IN_AGENCY
      ) {
        setDisabled(false);
        setUpdateStatus(true);
        setAdminRegistered(true);
        if (authUser.user.role.name === 'ADMIN') {
          setAdminAgency(false);
        } else {
          setAdminAgency(true);
        }
        setSaved(false);
      } else if (
        dispatchGuideSelected.dispatchStatus === DISPATCH_STATUS_LIST.REGISTERED
      ) {
        setDisabled(false);
        setUpdateStatus(true);
        if (authUser.user.role.name === 'ADMIN') {
          setAdminRegistered(false);
          setAdminAgency(false);
        } else {
          setAdminRegistered(false);
          setAdminAgency(true);
        }
        setSaved(false);

        if (
          dispatchGuideSelected.order.length > 0 &&
          dispatchGuideSelected.isEditedProducts === false
        ) {
          getSummaryProducts(dispatchGuideSelected.order.map((o) => o.id));
        }
      }

      form.setFieldsValue({
        id: dispatchGuideSelected.id,
        shippingCost: dispatchGuideSelected.shippingCost,
        guideNumber: dispatchGuideSelected.guideNumber,
        warehouseId: dispatchGuideSelected.warehouseOriginId,
        warehouse: dispatchGuideSelected.warehouseOrigin,
        departmentOriginCode: parseInt(
          dispatchGuideSelected.departmentOriginCode,
        ),
        departmentOriginName: dispatchGuideSelected.departmentOriginName,
        provinceOriginCode: parseInt(dispatchGuideSelected.provinceOriginCode),
        provinceOriginName: dispatchGuideSelected.provinceOriginName,
        departmentCode: parseInt(dispatchGuideSelected.departmentCode),
        departmentName: dispatchGuideSelected.departmentName,
        provinceCode: parseInt(dispatchGuideSelected.provinceCode),
        provinceName: dispatchGuideSelected.provinceName,
        motorizedRecipientId: dispatchGuideSelected.motorizedRecipientId,
        motorizedSenderId: dispatchGuideSelected.motorizedSenderId,
        dispatchStatus: dispatchGuideSelected.dispatchStatus,
        guideCode: dispatchGuideSelected.guideCode,
        order: dispatchGuideSelected.order,
        comment: dispatchGuideSelected.comment,
        urlVoucher: dispatchGuideSelected.urlVoucher,
        commentDestination: dispatchGuideSelected.commentDestination,
        photoMerchandiseDestination:
          dispatchGuideSelected.photoMerchandiseDestination,
        photoMerchandiseOrigin: dispatchGuideSelected.photoMerchandiseOrigin,
        photoReturnDestination: dispatchGuideSelected.photoReturnDestination,
        photoReturnOrigin: dispatchGuideSelected.photoReturnOrigin,
        originCost: dispatchGuideSelected.originCost,
        destinationCost: dispatchGuideSelected.destinationCost,
        transporterId: conveyorSelected
          ? conveyorSelected.id
          : authUser.user.conveyor?.id,
        dispatchDate: dispatchGuideSelected.dispatchDate
          ? dayjs(dispatchGuideSelected.dispatchDate)
          : '',
      });
    } else {
      const ids = orderDeliveryChecked.map((o) => {
        return {
          id: o.id,
          orderNumber: o.orderNumber,
        };
      });

      form.setFieldValue(['order'], ids || []);
      form.setFieldValue(['id'], null);
      form.setFieldValue(['dispatchStatus'], DISPATCH_STATUS_LIST.REGISTERED);
      form.setFieldValue(
        ['transporterId'],
        conveyorSelected ? conveyorSelected.id : authUser.user.conveyor?.id,
      );
      setUpdateStatus(true);
    }
  }, [dispatchGuideSelected]);

  useEffect(() => {
    if (warehouses && warehouses.length > 0 && authUser.user.warehouse) {
      form.setFieldValue(['warehouseId'], authUser.user.warehouse?.id);
      form.setFieldValue(['motorizedSenderId'], authUser.user.id)
    }
  }, [warehouses]);

  useEffect(() => {
    if (warId && warehouses.length > 0) {
      const war = warehouses.find((t) => `${t.id}` === `${warId}`);

      if (war) {
        form.setFieldValue(['warehouse'], { id: warId, name: war.name });
      }
    }
  }, [warId]);

  const columns: ColumnsType<ISummaryProductResponse> = [
    {
      title: 'EAN',
      dataIndex: 'ean',
      align: 'center',
      render: (_, p) => <>{p.ean}</>,
    },
    {
      title: 'Productos',
      dataIndex: 'name',
      align: 'left',
      render: (_, p) => (
        <>
          {p.name} - {p.companyName}
        </>
      ),
    },
    {
      title: 'Almacén',
      dataIndex: 'warehouseName',
      align: 'center',
      render: (_, p) => <>{p.warehouseName}</>,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      align: 'center',
      render: (_, p) => <>{p.quantity}</>,
    },
  ];

  const columnsSummary: ColumnsType<any> = [
    {
      title: 'EAN',
      dataIndex: 'ean',
      align: 'center',
      render: (_, p) => <>{p.ean}</>,
    },
    {
      title: 'Productos',
      dataIndex: 'skuName',
      align: 'left',
      render: (_, p) => (
        <>
          {p.skuName} - {p.companyName}
        </>
      ),
    },
    {
      title: 'Almacén',
      dataIndex: 'warehouseName',
      align: 'center',
      render: (_, p) => <>{p.warehouseName}</>,
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      align: 'center',
      render: (_, p) => <>{p.quantity}</>,
    },
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <>
      <Form
        layout="horizontal"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name="DispatchGuideForm"
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['transporterId']} hidden>
          <Input />
        </Form.Item>

        {dispatchGuideSelected ? (
          <>
            <Form.Item
              label="#Guía"
              name={['guideNumber']}
              rules={[{ required: false, message: 'Obligatorio' }]}
            >
              <Input disabled />
            </Form.Item>
          </>
        ) : null}

        <Form.Item
          name={['dispatchStatus']}
          label="Estado de despacho"
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción" disabled={updateStatus}>
            {dispatchGuideSelected ? (
              <>
                {dispatchGuideSelected.dispatchStatus ===
                DISPATCH_STATUS_LIST.REGISTERED ? (
                  <>
                    <Option
                      key={DISPATCH_STATUS_LIST.IN_AGENCY}
                      value={DISPATCH_STATUS_LIST.IN_AGENCY}
                    >
                      <Badge style={{ marginRight: '.5rem' }} text="" />
                      {
                        DISPATCH_STATUS_LABEL[DISPATCH_STATUS_LIST.IN_AGENCY]
                          .label
                      }
                    </Option>
                  </>
                ) : dispatchGuideSelected.dispatchStatus ===
                  DISPATCH_STATUS_LIST.IN_AGENCY ? (
                  <>
                    <Option
                      key={DISPATCH_STATUS_LIST.DELIVERED}
                      value={DISPATCH_STATUS_LIST.DELIVERED}
                    >
                      <Badge style={{ marginRight: '.5rem' }} text="" />
                      {
                        DISPATCH_STATUS_LABEL[DISPATCH_STATUS_LIST.DELIVERED]
                          .label
                      }
                    </Option>
                  </>
                ) : null}
              </>
            ) : (
              <>
                {Object.keys(DISPATCH_STATUS_LIST).map((key) => (
                  <Option key={key} value={key}>
                    <Badge style={{ marginRight: '.5rem' }} text="" />
                    {DISPATCH_STATUS_LABEL[key].label}
                  </Option>
                ))}
              </>
            )}
          </Select>
        </Form.Item>

        {authUser.user.warehouse ? (
          <>
            <Form.Item
              label="Almacén"
              name={['warehouseId']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <Select
                placeholder="Seleccione una opción"
                loading={loading}
                disabled
              >
                {warehouses?.map((r: IWarehouseResponse) => (
                  <Option key={r.id} value={r.id}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label="Almacén"
              name={['warehouseId']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <Select placeholder="Seleccione una opción" loading={loading}>
                {warehouses?.map((r: IWarehouseResponse) => (
                  <Option key={r.id} value={r.id}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Departamento Origen"
          name={['departmentOriginCode']}
          rules={[{ required: false, message: '' }]}
        >
          <Select
            onChange={handleChangeDepartment}
            disabled={adminRegistered}
            showSearch
            loading={loadingGeo1}
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={locationsGeolevel1
              ?.filter((f) => f.flagContraentrega)
              .map((e) => ({
                label: e.name,
                value: e.id,
              }))}
          />
        </Form.Item>

        <Form.Item name={['departmentOriginName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Provincia Origen"
          name={['provinceOriginCode']}
          rules={[{ required: false, message: '' }]}
        >
          <Select
            disabled={adminRegistered}
            showSearch
            loading={loadingGeo2}
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={locationsGeolevel2
              ?.filter((f) => f.flagContraentrega)
              .map((e) => ({
                label: e.name,
                value: e.id,
              }))}
          />
        </Form.Item>

        <Form.Item name={['provinceOriginName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Motorizado Remitente"
          name={['motorizedSenderId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select
            placeholder="Seleccione una opción"
            loading={loadingMtStAll}
            disabled={adminRegistered}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={motorizedStoresAll?.map((e) => ({
              label: e.email,
              value: e.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={['motorizedSender']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Departamento Destino"
          name={['departmentCode']}
          rules={[{ required: false, message: '' }]}
        >
          <Select
            onChange={handleChangeDepartmentDestination}
            disabled={adminRegistered}
            showSearch
            loading={loadingGeo1}
            optionFilterProp="label"
            options={locationsGeolevel1
              ?.filter((f) => f.flagContraentrega)
              .map((e) => ({
                label: e.name,
                value: e.id,
              }))}
          />
        </Form.Item>

        <Form.Item name={['departmentName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Provincia Destino"
          name={['provinceCode']}
          rules={[{ required: false, message: '' }]}
        >
          <Select
            disabled={adminRegistered}
            showSearch
            loading={loadingGeo2}
            optionFilterProp="label"
            options={locationsGeolevel2
              ?.filter((f) => f.flagContraentrega)
              .map((e) => ({
                label: e.name,
                value: e.id,
              }))}
          />
        </Form.Item>

        <Form.Item name={['provinceName']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Motorizado Destino"
          name={['motorizedRecipientId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select
            placeholder="Seleccione una opción"
            loading={loadingMtStAll}
            disabled={adminAgency}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={motorizedStoresAll?.map((e) => ({
              label: e.email,
              value: e.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={['motorizedRecipient']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['order']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['warehouse']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label="Comentario" name={['comment']}>
          <TextArea rows={3} disabled={disabled} />
        </Form.Item>

        {dispatchGuideSelected &&
        (status === DISPATCH_STATUS_LIST.IN_AGENCY ||
          status === DISPATCH_STATUS_LIST.DELIVERED) ? (
          <>
            <Form.Item
              label="Número de guía"
              name={['guideCode']}
              rules={[{ required: false, message: '' }]}
            >
              <Input disabled={disabled} />
            </Form.Item>

            <Form.Item
              label="Costo de guía"
              name={['shippingCost']}
              rules={[{ required: false, message: '' }]}
            >
              <InputNumber disabled={disabled} />
            </Form.Item>

            <Form.Item name={['urlVoucher']} label="Documento guía">
              <Upload
                name="file"
                beforeUpload={handleBeforeUpload}
                maxCount={1}
                disabled={disabled}
                fileList={[]}
              >
                <Button>
                  <UploadOutlined /> {file ? file.name : ''} {percent} %
                </Button>
              </Upload>
              {urlVoucher && urlVoucher.length > 0 ? (
                <>
                  <a
                    href={urlVoucher}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    Ver voucher adjunto
                  </a>
                </>
              ) : null}
            </Form.Item>

            <Form.Item
              name={['photoMerchandiseOrigin']}
              label="Foto mercadería inicial"
            >
              <Upload
                name="file"
                beforeUpload={handleBeforeUploadMerchandiseOrigin}
                maxCount={1}
                disabled={disabled}
                fileList={[]}
              >
                <Button>
                  <UploadOutlined />{' '}
                  {fileMerchandise ? fileMerchandise.name : ''}{' '}
                  {percentMerchandise} %
                </Button>
              </Upload>
              {photoMerchandiseOrigin && photoMerchandiseOrigin.length > 0 ? (
                <>
                  <Image width={100} src={photoMerchandiseOrigin} />
                </>
              ) : null}
            </Form.Item>

            <Form.Item
              label="Fecha de llegada"
              name={['dispatchDate']}
              rules={[
                { required: true, message: 'Seleccionar una fecha de llegada' },
              ]}
            >
              <DatePicker
                disabled={disabled}
                inputReadOnly={true}
                showNow={false}
                disabledDate={disabledDate}
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </>
        ) : null}

        {dispatchGuideSelected ? (
          <>
            <Form.Item
              label="Costo de origen"
              name={['originCost']}
              rules={[{ required: false, message: '' }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Costo de destino"
              name={['destinationCost']}
              rules={[{ required: false, message: '' }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item name={['photoReturnOrigin']} label="Devolución origen">
              <Upload
                name="fileReturnOrigin"
                beforeUpload={handleBeforeUploadGuide}
                maxCount={1}
                disabled={disabled}
                fileList={[]}
              >
                <Button>
                  <UploadOutlined /> {fileGuide ? fileGuide.name : ''}{' '}
                  {percentGuide} %
                </Button>
              </Upload>
              {photoReturnOrigin && photoReturnOrigin.length > 0 ? (
                <>
                  <Image width={100} src={photoReturnOrigin} />
                </>
              ) : null}
            </Form.Item>

            <Form.Item
              name={['photoReturnDestination']}
              label="Devolución destino"
            >
              <Upload
                name="fileReturnDestination"
                beforeUpload={handleBeforeUploadGuideDest}
                maxCount={1}
                disabled={disabled}
                fileList={[]}
              >
                <Button>
                  <UploadOutlined /> {fileDest ? fileDest.name : ''}{' '}
                  {percentDest} %
                </Button>
              </Upload>
              {photoReturnDestination && photoReturnDestination.length > 0 ? (
                <>
                  <Image width={100} src={photoReturnDestination} />
                </>
              ) : null}
            </Form.Item>
          </>
        ) : null}

        {status === DISPATCH_STATUS_LIST.DELIVERED ? (
          <>
            <Form.Item
              name={['photoMerchandiseDestination']}
              label="Foto mercadería destino"
            >
              <Upload
                name="file"
                beforeUpload={handleBeforeUploadMerchandiseDestination}
                maxCount={1}
                disabled={disabled}
                fileList={[]}
              >
                <Button>
                  <UploadOutlined />{' '}
                  {fileMerchandiseD ? fileMerchandiseD.name : ''}{' '}
                  {percentMerchandiseD} %
                </Button>
              </Upload>
              {photoMerchandiseDestination &&
              photoMerchandiseDestination.length > 0 ? (
                <>
                  <Image width={100} src={photoMerchandiseDestination} />
                </>
              ) : null}
            </Form.Item>

            <Form.Item label="Comentario Destino" name={['commentDestination']}>
              <TextArea rows={3} disabled={disabled} />
            </Form.Item>
          </>
        ) : null}

        {dispatchGuideSelected ? (
          <>
            {dispatchGuideSelected.order.length > 0 ? (
              <>
                <Form.Item label="Resumen de pedidos">
                  {dispatchGuideSelected.order.map((o) => {
                    return <Tag key={o.id}>{o.orderNumber}</Tag>;
                  })}
                </Form.Item>

                {dispatchGuideSelected.dispatchStatus ===
                DISPATCH_STATUS_LIST.REGISTERED ? (
                  <>
                    {dispatchGuideSelected.isEditedProducts ? (
                      <>
                        <Table
                          rowKey="sku"
                          columns={columnsSummary}
                          dataSource={
                            dispatchGuideSelected.summaryDispatchProduct
                          }
                          pagination={false}
                          size="small"
                        />
                      </>
                    ) : (
                      <>
                        <Table
                          rowKey="sku"
                          columns={columns}
                          dataSource={summaryProducts}
                          pagination={false}
                          size="small"
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Table
                      rowKey="sku"
                      columns={columnsSummary}
                      dataSource={dispatchGuideSelected.summaryDispatchProduct}
                      pagination={false}
                      size="small"
                    />
                  </>
                )}
              </>
            ) : null}
          </>
        ) : null}

        <Divider />

        <Button type="primary" htmlType="submit" hidden={saved}>
          Guardar
        </Button>
      </Form>
    </>
  );
};

export default DispatchGuideEditForm;
