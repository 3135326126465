import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  message,
  Form,
  Input,
  Radio,
  Select,
  Typography,
  Upload,
} from 'antd';
import {
  AGENCY_LIST,
  AGENCY_LIST_ONLY_SHALOM,
  DOCUMENT_TYPE_LIST,
} from '../../../../../../config/data.params';
import { FormInstance } from 'antd/es/form/Form';
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema';
import { storage } from '../../../../../../libs/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { UploadOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface IOrderAgencyPartProps {
  form: FormInstance<IOrderUpdateSchema>;
}

const OrderAgencyPart: FC<IOrderAgencyPartProps> = ({ form }) => {
  const shippingByAgency = Form.useWatch(
    ['shipping', 'shippingByAgency'],
    form,
  );
  const urlShipping = Form.useWatch(['shipping', 'attachFile'], form);
  const [percent, setPercent] = useState(0);
  const [file, setFile] = useState<any>();
  const [url, setUrl] = useState('');
  const managementType = Form.useWatch(['managementType'], form);
  const blockFields = Form.useWatch(['blockFields'], form);

  function handleBeforeUpload(file: any) {
    setFile(file);
    if (!file) {
      message.success('Debe seleccionar un archivo');

      return;
    }

    const storageRef = ref(storage, `/files_agency/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercent(percent);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url);
          // eslint-disable-next-line no-console
          console.log('url image', url);
        });
      },
    );
    // Return false to prevent upload immediately.
    // Return a Promise to delay upload until Promise is resolved.
    // Return nothing or true to allow upload immediately.

    return false;
  }

  /*
  function handleChange(event: any) {
    setFile(event.target.files[0])
  }
  */

  useEffect(() => {
    form.setFieldValue(['shipping', 'attachFile'], url);
  }, [url]);

  return (
    <>
      <Form.Item
        name={['shipping', 'shippingByAgency']}
        label="¿Envío por agencia?"
        hidden
      >
        <Radio.Group
          options={[
            { label: 'No', value: false },
            { label: 'Si', value: true },
          ]}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
      {shippingByAgency ? (
        <>
          <Form.Item
            name={['shipping', 'agencyName']}
            label="Agencia"
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Select
              placeholder="Seleccione una opción"
              allowClear
              disabled={blockFields}
              options={
                managementType === 'PARTNER'
                  ? AGENCY_LIST_ONLY_SHALOM
                  : AGENCY_LIST
              }
            />
          </Form.Item>

          <Text style={{ marginBottom: '1rem', display: 'block' }}>
            Datos de la persona que recoge:
          </Text>

          <Form.Item label="Documento">
            <Form.Item
              name={['shipping', 'contactDocumenType']}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Select
                placeholder="Tipo de documento"
                allowClear
                disabled={blockFields}
                options={DOCUMENT_TYPE_LIST}
              />
            </Form.Item>
            <Form.Item
              name={['shipping', 'contactDocumentNumber']}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                margin: '0 8px',
              }}
            >
              <Input
                placeholder="Nro de documento"
                min={0}
                disabled={blockFields}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Nombre" name={['shipping', 'contactName']}>
            <Input disabled={blockFields} />
          </Form.Item>
          <Form.Item label="Celular" name={['shipping', 'contactPhone']}>
            <Input disabled={blockFields} />
          </Form.Item>

          <Form.Item label="Número de Guía" name={['shipping', 'guideNumber']}>
            <Input disabled={blockFields} />
          </Form.Item>

          <Form.Item label="Clave:" name={['shipping', 'keyCode']}>
            <Input disabled={blockFields} />
          </Form.Item>

          <Form.Item
            label="Dirección de recojo:"
            name={['shipping', 'addressPickUp']}
          >
            <Input disabled={blockFields} />
          </Form.Item>

          <Form.Item
            label="Costo de envío:"
            name={['shipping', 'shippingCost']}
          >
            <Input disabled={blockFields} />
          </Form.Item>

          <Form.Item name={['shipping', 'attachFile']} label="Guía de shalom:">
            <Upload
              name="file"
              beforeUpload={handleBeforeUpload}
              maxCount={1}
              fileList={[]}
              disabled={blockFields}
            >
              <Button disabled={blockFields}>
                <UploadOutlined /> {file ? file.name : ''} {percent} %
              </Button>
            </Upload>
            {urlShipping?.length !== 0 ? (
              <>
                <a
                  href={urlShipping}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  Visualizar archivo adjunto
                </a>
              </>
            ) : null}
          </Form.Item>
        </>
      ) : null}
    </>
  );
};

export default OrderAgencyPart;
