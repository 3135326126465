import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { ICompanyCreateRequest } from '../../../../../services/company-create/company-create.interface';
import {
  useCompanyCreate,
  useCompanyStore,
} from '../../../../../services/company-create/company-create.service';
import { StoresEditForm } from '../../forms';

const StoresEditContainer = () => {
  const { saveCompany, loading, company } = useCompanyCreate();
  const { setCompanyUpdated } = useCompanyStore();
  const { closeDrawer } = useDrawer();

  const handleSubmit = (data: ICompanyCreateRequest) => {
    saveCompany(data);
  };

  useEffect(() => {
    if (company) {
      closeDrawer();
      setCompanyUpdated(company);
    }
  }, [company]);

  return (
    <>
      <Spin spinning={loading}>
        <StoresEditForm onSubmit={handleSubmit} />
      </Spin>
    </>
  );
};

export default StoresEditContainer;
