/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store';
import { AddOrderForm } from '../../forms';
import { useAddOrderToDispatch } from '../../../../../services/summary-products/summary-products.service';
import { ISummaryProductCreateSchema } from '../../../../../services/summary-products/summary-products.schema';
import { useDrawer } from '../../../../../contexts/DrawerContext';

const AddOrderContainer = () => {
  const { dispatchGuideSelected, setDispatchGuideSelected } =
    useDispatchGuideStore();
  const { closeDrawer } = useDrawer();
  const { addOrderToDispatch, orderToDispatch, loading } =
    useAddOrderToDispatch();
  const { setOrderAddedToDispatch } = useDispatchGuideStore();

  const handleSubmitAddOrder = (data: ISummaryProductCreateSchema) => {
    addOrderToDispatch(data);
  };

  useEffect(() => {
    if (orderToDispatch) {
      setDispatchGuideSelected(null);
      setOrderAddedToDispatch(orderToDispatch);
      closeDrawer();
    }
  }, [orderToDispatch]);

  return (
    <div style={{ overflow: 'auto' }}>
      {dispatchGuideSelected ? (
        <>
          <Spin spinning={loading}>
            <AddOrderForm onSubmitAddOrder={handleSubmitAddOrder} />
          </Spin>
        </>
      ) : null}
    </div>
  );
};

export default AddOrderContainer;
