import { ITransport } from '../../services/location/location.interface'

export const actionTypes = {
  SET_CONVEYOR_SELECTED: 'SET_CONVEYOR_SELECTED',
  SET_CONVEYOR_GLOBAL: 'SET_CONVEYOR_GLOBAL',
}

const setConveyorSelected = (data: ITransport | null) => ({
  type: actionTypes.SET_CONVEYOR_SELECTED,
  payload: data,
})

const setConveyorGlobal = (transporter: ITransport | null) => ({
  type: actionTypes.SET_CONVEYOR_GLOBAL,
  payload: transporter,
})

export const conveyorActions = {
  setConveyorSelected,
  setConveyorGlobal,
}
