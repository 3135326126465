import React, { useEffect } from 'react';
import { Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { formatDateUTCHour } from '../../../../../utils/date';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import useMeasureY from '../../../../../hooks/use-measurey';
import { ICatalogPrivate } from '../../../../../services/catalog/catalog.interface';
import { useCatalogStore } from '../../../../../services/catalog/catalog.store';
import { useCatalogDelete } from '../../../../../services/catalog/catalog.service';
import { useAuthStore } from '../../../../../services/auth/auth.service';

interface ICatalogListTableProps {
  catalogs: ICatalogPrivate[];
  loading: boolean;
}

const CatalogTable: React.FC<ICatalogListTableProps> = ({
  catalogs,
  loading,
}) => {
  const { openDrawer } = useDrawer();
  const { setCatalogSelected, setCatalogUpdated } = useCatalogStore();
  const { windowSize } = useMeasureY();
  const { deleteCatalog, catalogDeleted } = useCatalogDelete();
  const { authUser } = useAuthStore();

  const handleSelected = (data: ICatalogPrivate) => {
    setCatalogSelected(data);
    openDrawer('CATALOG_CREATE', 'Editar catálogo');
  };

  const handleDeleted = (data: ICatalogPrivate) => {
    deleteCatalog(data.id);
  };

  useEffect(() => {
    if (catalogDeleted) {
      setCatalogUpdated(catalogDeleted);
    }
  }, [catalogDeleted]);

  const columns: ColumnsType<ICatalogPrivate> = [
    {
      title: '#',
      dataIndex: 'id',
      width: '5%',
      align: 'center',
      render: (_, tk) => <>#000{tk.id}</>,
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      width: '10%',
      align: 'center',
      render: (_, tk) => (
        <>
          {tk.name}
          <br />
          {tk.description}
          <br />
          {formatDateUTCHour(tk.createdAt)}
        </>
      ),
    },
    {
      title: '#Proveedor',
      dataIndex: 'supplier',
      width: '8%',
      align: 'center',
      render: (_, tk) => <>{tk.company?.name || ''}</>,
    },
    {
      title: 'Tiendas',
      dataIndex: 'stores',
      width: '12%',
      align: 'center',
      render: (_, tk) => (
        <>
          {tk.catalogsCompany.map((cc) => {
            return (
              <span key={cc.company.id}>
                <Tag>{cc.company.name}</Tag>
              </span>
            );
          })}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '5%',
      align: 'center',
      render: (_, tk) => (
        <>
          <Tooltip title={'Editar'}>
            <a onClick={() => handleSelected(tk)}>
              <EditFilled />
            </a>
          </Tooltip>

          {authUser.user.role.name === 'ADMIN' ||
          authUser.user.role.name === 'MASTER' ? (
            <>
              <Tooltip title={'Eliminar'}>
                <a
                  onClick={() => handleDeleted(tk)}
                  style={{ marginLeft: '12px' }}
                >
                  <DeleteFilled />
                </a>
              </Tooltip>
            </>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      pagination={false}
      bordered
      dataSource={catalogs}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      size="small"
      scroll={{
        x: 1000,
        y: windowSize.height - 200,
      }}
    />
  );
};

export default CatalogTable;
