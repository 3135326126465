import React, { useEffect } from 'react';
import { Space, Spin } from 'antd';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import Title from 'antd/lib/typography/Title';
import { formatDate } from '../../../../../utils/date';
import {
  EnvironmentOutlined,
  PhoneOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useOrderCreate } from '../../../../../services/order-create/order.service';
import {
  IS_NOT_AVAILABLE_STATUS,
  IS_SUCCESS_STATUS,
} from '../../../../../services/pre-order/pre-order.params';
import { useOrderShopifyStore } from '../../../../../services/order-shopify/order-shopify.store';
import { IOrderShopifySchema } from '../../../../../services/order-shopify/order-shopify.schema';
import OrderShopifyForm from '../../forms/OrderShopifyForm/OrderShopifyForm';

const OrderShopifyNewContainer = () => {
  const {
    orderShopifyCreate,
    orderCreated,
    loading: loadingSave,
    deleteOrderShopify,
    updatedOrderShopify,
  } = useOrderCreate();
  const { orderShopifySelected } = useOrderShopifyStore();
  const { closeDrawer } = useDrawer();

  const handleFinish = (data: IOrderShopifySchema) => {
    if (
      IS_NOT_AVAILABLE_STATUS.includes(data.orderStatus as string) ||
      IS_SUCCESS_STATUS.includes(data.orderStatus as string)
    ) {
      orderShopifyCreate(data);
    } else {
      if (orderShopifySelected) {
        updatedOrderShopify(orderShopifySelected, data);
        closeDrawer();
      }
    }
  };

  useEffect(() => {
    if (orderCreated && orderShopifySelected) {
      deleteOrderShopify(orderShopifySelected);
      closeDrawer();
    }
  }, [orderCreated]);

  return (
    <>
      {orderShopifySelected ? (
        <>
          <Space align="baseline" style={{ marginBottom: '.5rem' }}>
            <Title level={5}>#{orderShopifySelected.orderNumber}</Title>
            <span>{formatDate(orderShopifySelected.createdAt)}</span>
          </Space>

          <div style={{ marginBottom: '0.35rem' }}>
            <Space>
              <div>
                <TagOutlined />
              </div>
              <div>
                <div>
                  {orderShopifySelected.items.map((i) => (
                    <span key={i.productShopifyId}>
                      {i.quantity} {i.name}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            </Space>
          </div>

          <div style={{ marginBottom: '0.35rem' }}>
            <Space>
              <div>
                <UserOutlined />
              </div>
              <div>
                {orderShopifySelected.customer.firstName}{' '}
                {orderShopifySelected.customer.lastName}
              </div>
            </Space>
          </div>

          <div style={{ marginBottom: '0.35rem' }}>
            <Space>
              <div>
                <PhoneOutlined />
              </div>
              <div>{orderShopifySelected.shipping.phone}</div>
            </Space>
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <Space>
              <div>
                <EnvironmentOutlined />
              </div>
              <div>
                {orderShopifySelected.shipping.address} -{' '}
                {orderShopifySelected.shipping.city}-{' '}
                {orderShopifySelected.shipping.province}
              </div>
            </Space>
          </div>

          <Spin spinning={loadingSave}>
            <OrderShopifyForm
              initialValues={orderShopifySelected}
              onSubmit={handleFinish}
            />
          </Spin>
        </>
      ) : null}
    </>
  );
};

export default OrderShopifyNewContainer;
