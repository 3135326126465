import React from 'react';
import { useDrawer } from '../../../../contexts/DrawerContext';
import { EmailVerificationForm } from '../../forms';
import { useSignUpCreate } from '../../../../services/sign-up/sign-up.service';
import { useSignupStore } from '../../store/signup.store';
import { message } from 'antd';

export const EmailVerificationContainer = () => {
  const { closeDrawer } = useDrawer();
  const { signUpCreate, loading } = useSignUpCreate();
  const { createNewAccountRequest } = useSignupStore();

  const handleVerificationCode = (data: { codeAuth: string }) => {
    if (!createNewAccountRequest) {
      message.info('No se ha encontrado información de la cuenta');
      closeDrawer();

      return;
    }

    signUpCreate({
      codeAuth: data.codeAuth,
      signUpSchema: createNewAccountRequest,
    }).then(() => {
      localStorage.removeItem('referenceCode');
      closeDrawer();
    });
  };

  return (
    <EmailVerificationForm
      loading={loading}
      onVerifyCode={handleVerificationCode}
    />
  );
};
