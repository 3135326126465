import React, { useEffect, useState } from 'react';
import {
  Button,
  Select,
  Input,
  DatePicker,
  Badge,
  Modal,
  Space,
  Typography,
} from 'antd';
import { PageHeader } from '../../../../../components';
import { CompanyChangeContainer } from '../../../../../containers';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import {
  CALL_STATUS_LIST,
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
} from '../../../../../services/pre-order/pre-order.params';
import { useOrdersCall } from '../../../../../services/order-call/order-call.service';
import { usePreOrderEdit } from '../../../../../services/pre-order/pre-order.edit';
import OrdersTableLevel from '../../components/OrdersTableLevel/OrdersTableLevel';
import { subDays } from 'date-fns';
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_DISPATCH_STATUS_LIST,
  ORDER_STATUS_LABEL,
} from '../../../../../services/order-create/order.params';
import { useLocationLevel1 } from '../../../../../services/location/location.service';
import { OrderResumen } from '../../components';
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useSellerList } from '../../../../../services/user-seller-list/user-seller-list.service';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../routes/routes.param';
import { useTransporterList } from '../../../../../services/transporter-list/transporter-list.service';
import dayjs from 'dayjs';
import { FilterOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Search } = Input;
const { confirm } = Modal;

const OrdersListContainer = () => {
  const { getOrdersCallAll, response, loadingOrder, count, page, resumen } =
    useOrdersCall();
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getSellers, loading, sellers } = useSellerList();
  const { orderUpdated, setOrderSelected } = useOrderStore();
  const { companyGlobal } = useCompanyStore();
  const { authUser } = useAuthStore();
  const { openDrawer } = useDrawer();
  const { userCompany } = usePreOrderEdit();
  const dateFormat = 'YYYY-MM-DD';
  const [selectedDates, setSelectedDates] = useState([
    dayjs(subDays(new Date(), 30)),
    dayjs(new Date()),
  ]);

  const [sellerSelected, setSellerSelected] = useState<string>('ALL');
  const [defaultSelected, setDefaultSelected] = useState<string[]>([
    CALL_STATUS_LIST.CONFIRMED,
  ]);
  const [statusSelected, setStatusSelected] = useState<string[]>([]);
  const [dispatchSelected, setDispatchSelected] = useState<string>('ALL');
  const [department, setDepartment] = useState<string>('ALL');
  const [filterDate, setFilterDate] = useState<string>('create');
  const [filterRecycle, setFilterRecycle] = useState<string>('ALL');
  const [warSelected, setWarSelected] = useState<string>('ALL');
  const { getWarehouses, loadingWarehouses, warehouses } =
    useWarehouseByCountry();
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [hiddenWar, setHiddenWar] = useState(false);
  const [hiddenTransp, setHiddenTransp] = useState(false);
  const navigate = useNavigate();
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const colorList = [
    '#ffd6a5',
    '#fdffb6',
    '#caffbf',
    '#9bf6ff',
    '#a0c4ff',
    '#bdb2ff',
    '#ffc6ff',
    '#95A581FF',
    '#d0f4de',
    '#f4d35e',
  ];

  const handleOrderCreate = () => {
    if (
      authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER'
    ) {
      if (authUser.user.company.configBankAccount === true) {
        setOrderSelected(null);
        openDrawer('ORDER_EDIT', 'Agregar orden');
      } else {
        confirm({
          title: '',
          content: (
            <span>
              Para que puedas subir tu pedido primero tienes que configurar tus
              datos bancarios.
              <br />
              <br />
              Mira el video de como hacerlo{' '}
              <a target="_blank" rel="noreferrer" href="https://bit.ly/3Ux8r7d">
                https://bit.ly/3Ux8r7d
              </a>
              .
            </span>
          ),
          okText: 'Ir Configuración',
          cancelText: null,
          onCancel: undefined,
          onOk() {
            navigate(ROUTES.SETTING.PATH, { replace: true });
          },
        });
      }
    } else {
      setOrderSelected(null);
      openDrawer('ORDER_EDIT', 'Agregar orden');
    }
  };

  const showWarehouseDefault = () => {
    if (authUser.user.role.name === 'STORE') {
      setWarSelected(`${authUser.user.warehouse?.id}`);
      setHiddenWar(true);
    } else {
      setHiddenWar(false);
      getWarehouses();
    }
  };

  useEffect(() => {
    if (orderUpdated) {
      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        page: 1,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
        conveyorId: transpSelected,
        filterRecycle: filterRecycle,
      });
    }
  }, [orderUpdated]);

  useEffect(() => {
    showWarehouseDefault();

    if (
      authUser.user.role.name === 'SUPER_MASTER' ||
      authUser.user.role.name === 'ADMIN_STORE' ||
      authUser.user.role.name === 'SELLER' ||
      authUser.user.role.name === 'STORE'
    ) {
      getTransporters();
      setHiddenTransp(false);
    } else if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER'
    ) {
      setHiddenTransp(true);
      setTranspSelected(`${authUser.user.conveyor?.id}`);
    }

    if (companyGlobal && (userCompany === 1 || userCompany === 3)) {
      setSellerSelected('ALL');
      getSellers(`${companyGlobal?.id === 0 ? '' : companyGlobal?.id}`);
      getLocationsGeoLevel1();
      setDefaultSelected([CALL_STATUS_LIST.CONFIRMED]);

      let conveyorIdAux = 'ALL';

      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        conveyorIdAux = `${authUser.user.conveyor?.id}`;
      }

      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        page: 1,
        userId: 'ALL',
        dispatchStatus: dispatchSelected,
        warehouseId:
          authUser.user.role.name === 'STORE'
            ? `${authUser.user.warehouse?.id}`
            : warSelected,
        conveyorId: conveyorIdAux,
        filterRecycle: filterRecycle,
      });
    } else if (userCompany === 2) {
      getSellers(`${authUser.user.company.id}`);
      getLocationsGeoLevel1();
      setDefaultSelected([CALL_STATUS_LIST.CONFIRMED]);
      getOrdersCallAll({
        companyId: authUser.user.company.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        page: 1,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
        conveyorId: transpSelected,
        filterRecycle: filterRecycle,
      });
    }
  }, [companyGlobal]);

  const handleChangeWarehouse = (warId: string) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warId,
      page: 1,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });

    setWarSelected(warId);
  };

  const handleChange = (callStatus: string[]) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: callStatus,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
    setDefaultSelected(callStatus);
  };

  const handleStatusChange = (status: string[]) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: status,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
    setStatusSelected(status);
  };

  const handleSellerChange = (sellerId: string) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      userId: sellerId,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });

    setSellerSelected(sellerId);
  };

  const handleStatusDispatchChange = (dispatchStatus: string) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchStatus,
      warehouseId: warSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });

    setDispatchSelected(dispatchStatus);
  };

  const handlePagination = (page: number) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      page: page,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
  };

  const onSearch = (value: string) => {
    if (value.length > 0) {
      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        search: value,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
        conveyorId: transpSelected,
        filterRecycle: filterRecycle,
      });
    } else {
      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('YYYY-MM-DD'),
        endDate: selectedDates[1].format('YYYY-MM-DD'),
        page: 1,
        search: value,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
        conveyorId: transpSelected,
        filterRecycle: filterRecycle,
      });
    }
  };

  const handleCalendarChange = (dates: any, _: any) => {
    getOrdersCallAll({
      callStatusList: defaultSelected,
      companyId: companyGlobal?.id,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
    setSelectedDates(dates);
  };

  const handleChangeDepartment = (name: string) => {
    getOrdersCallAll({
      callStatusList: defaultSelected,
      statusList: statusSelected,
      companyId: companyGlobal?.id,
      departmentName: name,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      page: 1,
      userId: sellerSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });
    setDepartment(name);
  };

  const handleChangeTransporter = (id: string) => {
    getOrdersCallAll({
      callStatusList: defaultSelected,
      statusList: statusSelected,
      companyId: companyGlobal?.id,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      page: 1,
      userId: sellerSelected,
      conveyorId: id,
      filterRecycle: filterRecycle,
    });
    setTranspSelected(id);
  };

  const handleChangeFilterDate = (filter: string) => {
    getOrdersCallAll({
      callStatusList: defaultSelected,
      statusList: statusSelected,
      companyId: companyGlobal?.id,
      departmentName: department,
      filterDate: filter,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      page: 1,
      userId: sellerSelected,
      conveyorId: transpSelected,
      filterRecycle: filterRecycle,
    });

    setFilterDate(filter);
  };

  const handleChangeFilterRecycle = (filter: string) => {
    setFilterRecycle(filter);

    getOrdersCallAll({
      callStatusList: defaultSelected,
      statusList: statusSelected,
      companyId: companyGlobal?.id,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('YYYY-MM-DD'),
      endDate: selectedDates[1].format('YYYY-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      page: 1,
      userId: sellerSelected,
      conveyorId: transpSelected,
      filterRecycle: filter,
    });
  };

  return (
    <>
      <PageHeader>
        {/* Header and title */}
        <Space wrap style={{ marginBottom: 20 }}>
          {authUser.user.role?.permissions?.order_create && (
            <Button
              type="primary"
              onClick={handleOrderCreate}
              style={{ flex: '0 1 auto' }}
            >
              + Nuevo Pedido
            </Button>
          )}

          {authUser.user.role?.permissions?.order_company_change && (
            <CompanyChangeContainer isAll={true} />
          )}

          <Search
            placeholder="Busca tu orden"
            onSearch={onSearch}
            enterButton
            style={{ width: '240px' }}
          />
        </Space>

        <Space wrap>
          <div>
            <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
              Estado llamada:
            </Typography.Text>
            <Select
              placeholder="Selecciona estado(s)"
              style={{ width: '320px' }}
              onChange={handleChange}
              value={defaultSelected}
              mode="multiple"
              allowClear
              maxTagCount="responsive"
              tagRender={({ label, value, closable, onClose }) => {
                // Combina las listas solo dentro del render
                const combinedStatusList = [
                  ...ORDER_STATUS_LIST_CONFIRMED,
                  ...ORDER_STATUS_LIST_NOT_AVAILABLE,
                ];

                // Encuentra el índice del color en la lista combinada
                const colorIndex = combinedStatusList.findIndex(
                  (status) => status.value === value,
                );
                const color = colorList[colorIndex % colorList.length];

                return (
                  <div
                    style={{
                      backgroundColor: color,
                      borderRadius: '5px',
                      padding: '4px 8px',
                      marginRight: '5px',
                      display: 'inline-flex',
                      alignItems: 'center',
                      color: '#000',
                    }}
                  >
                    <span>{label}</span>
                    {closable && (
                      <span
                        onClick={onClose}
                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                      >
                        x
                      </span>
                    )}
                  </div>
                );
              }}
            >
              {/* Aquí debes mapear las listas de opciones */}
              {ORDER_STATUS_LIST_CONFIRMED.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
              {ORDER_STATUS_LIST_NOT_AVAILABLE.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
              Estado entrega:
            </Typography.Text>
            <Select
              placeholder="Selecciona estado(s)"
              style={{ width: '320px' }}
              onChange={handleStatusChange}
              value={statusSelected}
              mode="multiple"
              allowClear
              maxTagCount="responsive"
            >
              {Object.keys(ORDER_STATUS_LABEL).map((key) => (
                <Option key={key} value={key}>
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={ORDER_STATUS_LABEL[key].color}
                    text=""
                  />
                  {ORDER_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
          </div>

          <div>
            <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
              Almacén:
            </Typography.Text>
            <Select
              placeholder="Almacén"
              value={warSelected}
              onChange={handleChangeWarehouse}
              loading={loadingWarehouses}
              style={{ width: '180px' }}
              showSearch
              optionFilterProp="label"
            >
              <Option key="ALL" value="ALL">
                TODOS
              </Option>
              {warehouses.map((c) => (
                <Option key={c.id} value={`${c.id}`}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </div>

          <Space wrap>
            <div>
              <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
                Filtrar por:
              </Typography.Text>
              <Select
                onChange={handleChangeFilterDate}
                value={filterDate}
                style={{ minWidth: '150px' }}
              >
                <Option key="create" value="create">
                  Fecha Creación
                </Option>
                <Option key="dispatch" value="dispatch">
                  Fecha Despacho
                </Option>
                <Option key="delivery" value="delivery">
                  Fecha Entrega
                </Option>
              </Select>
            </div>

            <div>
              <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
                Fecha inicio:
              </Typography.Text>
              <DatePicker
                placeholder="Fecha Inicial"
                format={dateFormat}
                defaultValue={dayjs(subDays(new Date(), 30))}
                onChange={(date, dateString) =>
                  handleCalendarChange([date, selectedDates[1]], dateString)
                }
                clearIcon={false}
                style={{ width: '100%' }}
              />
            </div>

            <div>
              <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
                Fecha final:
              </Typography.Text>
              <DatePicker
                placeholder="Fecha Final"
                format={dateFormat}
                defaultValue={dayjs(new Date())}
                onChange={(date, dateString) =>
                  handleCalendarChange([selectedDates[0], date], dateString)
                }
                clearIcon={false}
                style={{ width: '100%' }}
              />
            </div>
          </Space>

          {showMoreFilters && (
            <>
              <div>
                <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
                  Vendedor:
                </Typography.Text>

                <Select
                  placeholder="Vendedor"
                  style={{ minWidth: '180px' }}
                  onChange={handleSellerChange}
                  value={sellerSelected}
                  loading={loading}
                >
                  <Option key="ALL" value="ALL">
                    <Badge
                      style={{ marginRight: '.5rem' }}
                      color={'#000000'}
                      text=""
                    >
                      TODOS
                    </Badge>
                  </Option>
                  {sellers.map((key) => (
                    <Option key={key.id} value={key.id}>
                      {key.fullname} ({key.email})
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
                  Estado despacho:
                </Typography.Text>
                <Select
                  placeholder="Estado de despacho"
                  style={{ minWidth: '150px' }}
                  onChange={handleStatusDispatchChange}
                  value={dispatchSelected}
                >
                  <Option key="ALL" value="ALL">
                    TODOS
                  </Option>
                  {Object.keys(ORDER_DISPATCH_STATUS_LIST).map((key) => (
                    <Option key={key} value={key}>
                      {ORDER_DISPATCH_STATUS_LABEL[key].label}
                    </Option>
                  ))}
                </Select>
              </div>

              {!hiddenTransp && (
                <div>
                  <Typography.Text
                    style={{ display: 'block', fontSize: '13px' }}
                  >
                    Transportadora:
                  </Typography.Text>
                  <Select
                    onChange={handleChangeTransporter}
                    showSearch
                    optionFilterProp="key"
                    loading={loadingTransporters}
                    value={transpSelected}
                    style={{ minWidth: '120px' }}
                    filterOption={(input, option) =>
                      (option?.key as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option key="ALL" value="ALL">
                      <Badge
                        style={{ marginRight: '.5rem' }}
                        color={'#000000'}
                        text=""
                      >
                        TODOS
                      </Badge>
                    </Option>
                    {transporters?.map((key) => (
                      <Option key={key.name} value={key.id}>
                        {key.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}

              <div>
                <Typography.Text style={{ display: 'block', fontSize: '13px' }}>
                  Pedidos:
                </Typography.Text>
                <Select
                  onChange={handleChangeFilterRecycle}
                  optionFilterProp="key"
                  value={filterRecycle}
                  style={{ width: '180px' }}
                >
                  <Option key="ALL" value="ALL">
                    TODOS
                  </Option>
                  <Option key="recycle" value="recycle">
                    RECICLADOS
                  </Option>
                </Select>
              </div>

              {!hiddenWar && (
                <div>
                  <Typography.Text
                    style={{ display: 'block', fontSize: '13px' }}
                  >
                    Departamento
                  </Typography.Text>
                  <Select
                    onChange={handleChangeDepartment}
                    showSearch
                    optionFilterProp="key"
                    loading={loadingGeo1}
                    value={department}
                    style={{ minWidth: '150px' }}
                  >
                    <Option key="ALL" value="ALL">
                      <Badge
                        style={{ marginRight: '.5rem' }}
                        color="#000000"
                        text=""
                      >
                        TODOS
                      </Badge>
                    </Option>
                    {locationsGeolevel1?.map((key) => (
                      <Option key={key.name} value={key.name}>
                        {key.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </>
          )}

          <Button
            color="primary"
            variant="filled"
            style={{ marginTop: 15 }}
            onClick={() => setShowMoreFilters((prev) => !prev)}
          >
            <FilterOutlined />
            {showMoreFilters ? 'Ocultar filtros' : 'Más filtros'}
          </Button>
        </Space>

        <div style={{ width: '100%' }}>
          {/* Mostrar el resumen */}
          {resumen ? <OrderResumen resumen={resumen} /> : null}
        </div>
      </PageHeader>

      <OrdersTableLevel
        record={response?.result}
        loading={loadingOrder}
        handlePagination={handlePagination}
        count={count}
        currentPage={page}
      />
    </>
  );
};

export default OrdersListContainer;
