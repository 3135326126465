import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useOrderStore } from '../../../../../services/order-list/order.store';
import { useEvidenceUpdate } from '../../../../../services/evidence-update/evidence-update.service';
import { IUpdateEvidenceSchema } from '../../../../../services/evidence-update/evidence-update.schema';
import EvidenceForm from '../../forms/EvidenceForm/EvidenceForm';

const OrderEvidenceContainer = () => {
  const { orderSelected, setOrderEvidence } = useOrderStore();
  const { closeDrawer } = useDrawer();
  const { loading, evidenceUpdated, updateEvidence } = useEvidenceUpdate();

  const handleSubmit = (data: IUpdateEvidenceSchema) => {
    console.log('data', data);
    if (orderSelected) {
      updateEvidence(data, orderSelected.id);
    }
  };

  useEffect(() => {
    if (evidenceUpdated) {
      closeDrawer();
      setOrderEvidence(evidenceUpdated);
    }
  }, [evidenceUpdated]);

  return (
    <>
      {orderSelected ? (
        <>
          <Spin spinning={loading}>
            <EvidenceForm
              onSubmit={handleSubmit}
              orderId={orderSelected?.id}
              orderDelivery={orderSelected?.orderDelivery}
            />
          </Spin>
        </>
      ) : null}
    </>
  );
};

export default OrderEvidenceContainer;
