import React, { FC, ReactNode } from 'react';

interface IOverlayContentProps {
  children: ReactNode;
}

export const OverlayContent: FC<IOverlayContentProps> = ({ children }) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '25%',
          left: '50%',
          zIndex: 10,
          transform: 'translate(-50%, -50%)',
          borderRadius: '.5rem',
          fontSize: '1rem',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {children}
      </div>
      <div
        style={{
          position: 'absolute',
          background: '#ffffffD9',
          zIndex: 9,
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      />
    </>
  );
};
