/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './conveyor.actions'
import { ITransport } from '../../services/location/location.interface'

export interface IConveyorState {
  conveyorSelected: ITransport | null;
  conveyorGlobal: ITransport | null;
}

const initialState: IConveyorState = {
  conveyorSelected: null,
  conveyorGlobal: null,
}

export const conveyorReducer = (
  state: IConveyorState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_CONVEYOR_SELECTED:
    return {
      ...state,
      conveyorSelected: action.payload,
    }
  case actionTypes.SET_CONVEYOR_GLOBAL:
    return {
      ...state,
      conveyorGlobal: action.payload,
    }
  default:
    return state
  }
}
