/* eslint-disable max-len */
import React, { FC } from 'react';
import { Collapse, CollapseProps, Tag, Typography } from 'antd';
import { IFrequentQuestion } from '../../../../services/frequent-question-list/frequent-question.interface';
import { CollapseQuestions } from '..';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

interface ICollapseModulesProps {
  data: {
    module: string;
    questions: IFrequentQuestion[];
  }[];
}

export const CollapseModules: FC<ICollapseModulesProps> = ({ data }) => {
  const items: CollapseProps['items'] =
    data?.map((item) => ({
      key: item.module,
      label: (
        <Typography.Title level={4} style={{ margin: 0 }}>
          {item.module}
          {'  '}
          <Tag bordered={false} color="gold">
            {item.questions.length} pregunta
            {item.questions.length > 1 ? 's' : ''}
          </Tag>
        </Typography.Title>
      ),
      styles: {
        header: { padding: 8 },
        body: { padding: 8 },
      },
      children: <CollapseQuestions data={item.questions} />,
    })) || [];

  return (
    <Collapse
      ghost
      defaultActiveKey={['1']}
      expandIcon={(panelProps) =>
        panelProps.isActive ? <MinusOutlined /> : <PlusOutlined />
      }
      expandIconPosition="end"
      size="large"
      items={items}
      style={{ marginBottom: '2rem', width: '100%' }}
    />
  );
};
