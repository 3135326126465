import { useAxios } from '../../hooks'
import departments from '../../config/data/departments.json'
import provinces from '../../config/data/provinces.json'
import districts from '../../config/data/districts.json'
import { ICoverageConveyor, ILocation, ILocationGeo, IQueryLocationGeo, ITransport } from './location.interface'
import { useAuthStore } from '../auth/auth.service'
import { useDispatch, useSelector } from 'react-redux'
import { IConveyorState } from '../../state/conveyor/conveyor.reducer'
import { IRootState } from '../../state'
import { conveyorActions } from '../../state/conveyor/conveyor.actions'
import { useCompanyStore } from '../company-create/company-create.service'

const mapToProvinceList = (): ILocation[] => {
  const provincesTemp: { [key: string]: ILocation[] } = provinces
  const provinceList: ILocation[] = []

  for (const key in provincesTemp) {
    if (Object.prototype.hasOwnProperty.call(provincesTemp, key)) {
      const element = provincesTemp[key]

      provinceList.push(...element)
    }
  }

  return provinceList
}

const filterDistrict = (ubigeoId: string): ILocation[] => {
  const districtsTemp: { [key: string]: ILocation[] } = districts

  return districtsTemp[ubigeoId]
}

export const getProvinceList = () => {
  return mapToProvinceList()
}

export const getDeparmentList = () => {
  const { authUser } = useAuthStore()
  const parentId = authUser.user.company.countryCode === 'PER' ? '2533' : '2534'
  
  return departments.filter(d => d.is_available === '1' && d.id_padre_ubigeo === parentId)
}

export const getProvincesByDepartment = (ubigeoId: string) => {
  const provincesTemp: { [key: string]: ILocation[] } = provinces

  return provincesTemp[ubigeoId]
}

export const getDistrictsByProvince = (ubigeoId: string) => {
  return filterDistrict(ubigeoId)
}

export const getLocations = (provinceId: string, districtId: string) => {
  const province = getProvinceList().find(p => p.id_ubigeo === provinceId) as ILocation
  const department = departments.find(d => d.id_ubigeo === province?.id_padre_ubigeo) as ILocation
  const district = getDistrictsByProvince(provinceId).find(p => p.id_ubigeo === districtId) as ILocation

  return {
    department,
    province,
    district,
  }
}

export const useLocationLevel1 = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingGeo1 } = useAxios<ILocationGeo[]>()

  const getLocationsGeoLevel1 = () => {
    execute({
      method: 'GET',
      url: '/ubigeo',
      params: {
        countryCode: authUser.user.company.countryCode,
        nivel: 1,
        parentId: ''
      },
    })
  }

  return {
    getLocationsGeoLevel1,
    loadingGeo1,
    locationsGeolevel1: response ? response : []
  }
}

export const useLocationLevel2 = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingGeo2 } = useAxios<ILocationGeo[]>()

  const getLocationsGeoLevel2 = (filter: IQueryLocationGeo) => {
    execute({
      method: 'GET',
      url: '/ubigeo',
      params: {
        countryCode: authUser.user.company.countryCode,
        nivel: 2,
        parentId: filter.parentId,
      },
    })
  }

  return {
    getLocationsGeoLevel2,
    loadingGeo2,
    locationsGeolevel2: response ? response : []
  }
}

export const useLocationLevel3 = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingGeo3 } = useAxios<ILocationGeo[]>()

  const getLocationsGeoLevel3 = (filter: IQueryLocationGeo) => {
    execute({
      method: 'GET',
      url: '/ubigeo',
      params: {
        countryCode: authUser.user.company.countryCode,
        nivel: 3,
        parentId: filter.parentId,
      },
    })
  }

  return {
    getLocationsGeoLevel3,
    loadingGeo3,
    locationsGeolevel3: response ? response : []
  }
}

export const useTransportCostToUbigeo = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading: loadingTransport } = useAxios<ITransport[]>()

  const getTransport = (ubi1: string, level: string, ubi2?: string, ubi3?: string) => {
    execute({
      method: 'GET',
      url: '/transport/ubigeo/cost',
      params: {
        countryCode: authUser.user.company.countryCode,
        ubigeo1Id: ubi1,
        ubigeo2Id: ubi2 ? ubi2 : '',
        ubigeo3Id: ubi3 ? ubi3 : '',
        level: level,
        companyId: companyGlobal ? (companyGlobal.id === 0 ? authUser.user.company.id : companyGlobal.id) 
          : authUser.user.company.id
      },
    })
  }

  return {
    getTransport,
    loadingTransport,
    transports: response ? response : []
  }
}

export const useTransportToUbigeo = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading: loadingTransport } = useAxios<ITransport[]>()

  const getTransport = (id: number) => {
    execute({
      method: 'GET',
      url: '/transport/coverage/cost',
      params: {
        countryCode: authUser.user.company.countryCode,
        ubigeoId: id,
        companyId: companyGlobal ? (companyGlobal.id === 0 ? authUser.user.company.id : companyGlobal.id) 
          : authUser.user.company.id
      },
    })
  }

  return {
    getTransport,
    loadingTransport,
    transports: response ? response : []
  }
}

export const useCoverageToTransporter = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingCoverage } = useAxios<ICoverageConveyor[]>()

  const getCoverages = (conveyorId?: string) => {
    execute({
      method: 'GET',
      url: '/transport/ubigeo',
      params: {
        countryCode: authUser.user.company.countryCode,
        transporterId: conveyorId === 'ALL' ? '' : conveyorId,
      },
    })
  }

  return {
    getCoverages,
    loadingCoverage,
    coverages: response ? response : []
  }
}

export const useConveyorStore = () => {
  const dispatch = useDispatch()
  const {
    conveyorSelected, conveyorGlobal,
  } = useSelector((state: IRootState) => state.conveyor as IConveyorState)

  const setConveyorSelected = (data: ITransport | null) => {
    dispatch(conveyorActions.setConveyorSelected(data))
  }

  const setConveyorGlobal = (conveyor: ITransport | null) => {
    dispatch(conveyorActions.setConveyorGlobal(conveyor))
  }

  return {
    setConveyorGlobal,
    setConveyorSelected,
    conveyorGlobal,
    conveyorSelected
  }
}
