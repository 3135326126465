import React, { useEffect } from 'react';
import { usePreOrders } from '../../../../../services/pre-order/pre-order.service';
import { ApiOutlined } from '@ant-design/icons';
import { PreOrderResumen, PreOrderTable } from '../../components';
import { IPreOrderItemResponse } from '../../../../../services/pre-order/pre-order.interface';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useUserStore } from '../../../../../services/user/user.service';
import { OverlayContent, PageHeader } from '../../../../../components';
import { usePreOrderStore } from '../../../../../services/pre-order/pre-order.store';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { Alert } from 'antd';
import { CompanyChangeContainer } from '../../../../../containers';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { ImportOrderContainer } from '..';

const PreOrdersListContainer = () => {
  const { getPreOrders, preOrdersGroup, resumen, loading } = usePreOrders();
  const { setPreOrderSelected } = usePreOrderStore();
  const { openDrawer } = useDrawer();
  const { userStatus } = useUserStore();
  const { authUser } = useAuthStore();
  const { companyGlobal } = useCompanyStore();

  const handleSelectItem = (preOrder: IPreOrderItemResponse) => {
    setPreOrderSelected(preOrder);
    openDrawer('PRE_ORDER_EDIT');
  };

  useEffect(() => {
    getPreOrders(companyGlobal?.id);
  }, [companyGlobal]);

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem' }}>
          {authUser.user.role?.permissions?.preorder_company_change ? (
            <CompanyChangeContainer isAll={false} />
          ) : null}

          {resumen ? <PreOrderResumen resumen={resumen} /> : null}

          {authUser.user.role?.permissions?.preorder_import ? (
            <ImportOrderContainer />
          ) : null}
        </div>
      </PageHeader>

      {!(userStatus?.status === 'ACTIVE') ? (
        <OverlayContent>
          <ApiOutlined style={{ fontSize: '3rem', color: '#ff6b6b' }} />
          <div>Usuario inactivo</div>
        </OverlayContent>
      ) : null}

      {authUser.user.role?.permissions?.preorder_all_users ? (
        <Alert
          type="info"
          style={{ margin: '0 1rem 1rem' }}
          message={`Como rol '${authUser.user.role.description}'
          puedes visualizar los pre-orders asignados a todos los vendedores.`}
        />
      ) : null}

      <PreOrderTable
        data={preOrdersGroup}
        onSelectedOrder={handleSelectItem}
        loading={loading}
      />
    </>
  );
};

export default PreOrdersListContainer;
