import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useCreateUserToTransporter } from '../../../../../services/user-create/user-create.service';
import { UserTransporterEditForm } from '../../forms';
import { useUserStore } from '../../../../../services/user/user.service';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { IUserCreateSchema } from '../../../../../services/user-create/user-create.schema';

const UserTransporterEditContainer = () => {
  const { createUser, userCreated, loading } = useCreateUserToTransporter();
  const { setUserUpdated } = useUserStore();
  const { closeDrawer } = useDrawer();

  const handleSubmit = (data: IUserCreateSchema) => {
    createUser(data);
  };

  useEffect(() => {
    if (userCreated) {
      closeDrawer();
      setUserUpdated(userCreated);
    }
  }, [userCreated]);

  return (
    <>
      <Spin spinning={loading}>
        <UserTransporterEditForm onSubmit={handleSubmit} />
      </Spin>
    </>
  );
};

export default UserTransporterEditContainer;
