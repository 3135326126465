import { useAxios } from '../../hooks'
import { IFilterLiquidation, ILiquidationResponse, ILiquidationTotal } from './liquidation-list.interface'

export const useLiquidationTotal = () => {
  const { execute, response, loading } = useAxios<ILiquidationTotal>()

  const getLiquidationTotal = (deliveryDate: string, conveyorId: string) => {
    execute({
      method: 'GET',
      url: '/liquidation/total-global',
      params: {
        startDate: deliveryDate,
        conveyorId: conveyorId === 'ALL' ? '' : conveyorId,
      },
    })
  }

  return {
    getLiquidationTotal,
    loadingTotal: loading,
    summaryTotal: response,
  }

}

export const useLiquidationList = () => {
  const { execute, response, loading } = useAxios<ILiquidationResponse[]>()

  const getLiquidations = (filter: IFilterLiquidation) => {
    execute({
      method: 'GET',
      url: '/liquidation',
      params: {
        deliveryDate: filter.deliveryDate,
        status: filter.status ? filter.status : '',
        userId: filter.userId ? filter.userId : '',
        conveyorId: filter.conveyorId === 'ALL' ? '' : filter.conveyorId,
      },
    })
  }

  return {
    getLiquidations,
    loading,
    liquidations: response ? response : [],
  }
}
