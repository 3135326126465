import { Button, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { PageHeader } from '../../../../../components';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { CatalogTable } from '../../components';
import { useCatalogs } from '../../../../../services/catalog/catalog.service';
import { useCatalogStore } from '../../../../../services/catalog/catalog.store';
import { useAuthStore } from '../../../../../services/auth/auth.service';

const { Title } = Typography;

const CatalogsContainer = () => {
  const { openDrawer } = useDrawer();
  const { authUser } = useAuthStore();
  const { catalogs, getCatalogs, loadingCatalogs } = useCatalogs();
  const { setCatalogSelected, catalogUpdated } = useCatalogStore();

  const handleOpenDrawer = () => {
    setCatalogSelected(null);
    openDrawer('CATALOG_CREATE', 'Crear catálogo');
  };

  useEffect(() => {
    getCatalogs();
  }, [catalogUpdated]);

  return (
    <>
      <PageHeader>
        <Space size="middle">
          {authUser.user.role.name === 'MASTER' ||
          authUser.user.role.name === 'ADMIN' ||
          authUser.user.role.name === 'SUPER_MASTER' ? (
            <>
              <Button type="primary" onClick={handleOpenDrawer}>
                Nuevo catálogo
              </Button>
            </>
          ) : null}

          <Title level={5} style={{ margin: 0 }}>
            Catálogos #{catalogs.length}
          </Title>
        </Space>
      </PageHeader>

      <CatalogTable catalogs={catalogs} loading={loadingCatalogs} />
    </>
  );
};

export default CatalogsContainer;
