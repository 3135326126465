/* eslint-disable max-len */
import React, { FC } from 'react';
import { Collapse, CollapseProps } from 'antd';
import { IFrequentQuestion } from '../../../../services/frequent-question-list/frequent-question.interface';

interface ICollapseQuestionsProps {
  data: IFrequentQuestion[] | undefined;
}

export const CollapseQuestions: FC<ICollapseQuestionsProps> = ({ data }) => {
  const items: CollapseProps['items'] =
    data?.map((item) => ({
      key: item.id.toString(),
      label: <span style={{ fontWeight: 500 }}>{item.question}</span>,
      styles: {
        header: { padding: '6px 0' },
        body: {
          padding: '0 12px 16px',
          '.ant-collapse': {
            backgroundColor: 'red',
          },
        },
      },
      children: (
        <div
          dangerouslySetInnerHTML={{ __html: item.answer }}
          style={{ fontSize: 'small' }}
        />
      ),
    })) || [];

  return (
    <Collapse
      ghost
      expandIconPosition="end"
      size="large"
      items={items}
      style={{ marginBottom: '.5rem' }}
    />
  );
};
