import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';

export interface State {
  displayModal: boolean;
  modalView: string | null;
  modalTitle: string | undefined;
}

const initialState = {
  displayModal: false,
  modalView: null,
  modalTitle: undefined,
};

type Action =
  | {
      type: 'OPEN_MODAL';
      view: string;
      title: string | undefined;
    }
  | {
      type: 'CLOSE_MODAL';
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalContext = createContext<State | any>(initialState);
ModalContext.displayName = 'ModalContext';

function modalReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return {
        ...state,
        modalView: action.view,
        displayModal: true,
        modalTitle: action.title,
      };
    }

    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      };
    }
  }
}

interface IModalProviderProps {
  children: ReactNode;
}
export const ModalProvider: FC<IModalProviderProps> = (props) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  const openModal = (view: string, title?: string) =>
    dispatch({ type: 'OPEN_MODAL', view, title });
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });

  const value = useMemo(
    () => ({
      ...state,
      openModal,
      closeModal,
    }),
    [state],
  );

  return <ModalContext.Provider value={value} {...props} />;
};

/**
 * @deprecated Please use `react-modal-hook` library instead
 */
export const useModal = () => {
  return useContext(ModalContext);
};
