import { useAxios } from '../../hooks'
import { useConveyorStore } from '../location/location.service'
import { ICoverageItemResponse } from './coverage-create.interface'
import { ICoverageCreateSchema } from './coverage-create.schema'

export const useCoverageCreate = () => {
  const { conveyorSelected } = useConveyorStore()
  const { execute, response, loading } = useAxios<ICoverageItemResponse>()

  const saveCoverage = (data: ICoverageCreateSchema) => {

    let ubigeId = 0
    const ubigeosJson: any = {}

    if (data.department) {

      if (data.province) {

        if (data.districts && data.districts.length > 0) {

          if (data.dpto) {

            ubigeosJson.dptoId = data.dpto.id
            ubigeosJson.dptoName = data.dpto.name

          }

          if (data.prov) {

            ubigeosJson.provId = data.prov.id
            ubigeosJson.provName = data.prov.name

          }

        } else {

          if (data.dpto) {

            ubigeosJson.dptoId = data.dpto.id
            ubigeosJson.dptoName = data.dpto.name

          }

          if (data.prov) {

            ubigeosJson.provId = data.prov.id
            ubigeosJson.provName = data.prov.name

          }

          ubigeId = data.province
        }

      } else {
        ubigeId = data.department
      }

    }

    const dataRequest = {
      transporterId: conveyorSelected?.id,
      ubigeoId: ubigeId,
      ubigeos: ubigeosJson,
      districts: data.districts,
      warehouses: data.warehouseIds,
      addDays: data.addDays || 0,
      deliveryCost: data.deliveryCost || 0,
      deliveryCostPremium: data.deliveryCostPremium || 0,
      returnCost: data.returnCost || 0,
      returnCostPremium: data.returnCostPremium || 0,
      motorizedCost: data.motorizedCost || 0,
      flagDeadLineTime: data.flagDeadLineTime || 0
    }

    execute({
      method: 'POST',
      url: '/transport/coverage',
      data: dataRequest,
    })
  }

  return {
    saveCoverage,
    loading,
    coverageUpdated: response
  }
}
