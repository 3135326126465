import React, { FC } from 'react';
import { Badge, Divider, Flex } from 'antd';
import { IOrderDeliveryResponse } from '../../../../../services/order-motorized/order-motorized.interface';
import './DeliveryResumen.css';
import { IMotorizedResponse } from '../../../../../services/user-motorized/user-motorized.interface';

interface IOrderResumenProps {
  orders: IOrderDeliveryResponse[];
  motorizeds: IMotorizedResponse[];
}

const DeliveryResumen: FC<IOrderResumenProps> = ({ orders, motorizeds }) => {
  const getMotorizedColor = (motorized: string) => {
    const motorizedFound = motorizeds.find((it) => it.email === motorized);

    if (motorizedFound) {
      return motorizedFound.markerIcon?.color;
    }

    return 'gray';
  };

  return (
    <div>
      {orders
        .sort((a, b) => b.totalOrder - a.totalOrder)
        .map((orderByMoto) => (
          <div key={orderByMoto.motorized} style={{ paddingRight: '1rem' }}>
            <Flex align="center" gap={8}>
              <div style={{ width: 38, textAlign: 'right' }}>
                <Badge
                  count={orderByMoto.totalOrder}
                  color={getMotorizedColor(orderByMoto.motorized)}
                  style={{ fontSize: 11 }}
                />
              </div>
              <span style={{ fontSize: '12.5px' }}>
                {orderByMoto.motorized}
              </span>
            </Flex>

            <Divider style={{ margin: '.25rem 0' }} />
          </div>
        ))}
    </div>
  );
};

export default DeliveryResumen;
