import { useAxios } from '../../hooks';
import { useAuthStore } from '../auth/auth.service';
import { IProductBaseResponse, IProductResponse } from './product.interface';

const useProductWarList = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IProductResponse[]>();

  const getProductsWar = (
    warId: string,
    companyId: string,
    isAdmin: boolean,
  ) => {
    const params = {
      companyId: isAdmin
        ? ''
        : companyId
          ? companyId
          : authUser.user.company.id,
      warehouseId: warId,
      isMaster: authUser.user.role.name === 'MASTER',
    };

    execute({
      method: 'GET',
      url: '/product/warehouse',
      params: params,
    });
  };

  return {
    getProductsWar,
    loading,
    productsWar: response ? response : [],
  };
};

const useProductFind = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IProductBaseResponse>();

  const getProducts = (
    companyId?: string,
    isCompany?: boolean,
    page?: string,
    search?: string,
    categoryId?: string,
  ) => {
    const params = {
      companyId: isCompany
        ? companyId
          ? companyId === '0'
            ? authUser.user.company.id
            : companyId
          : authUser.user.company.id
        : '',
      page: page ? page : '',
      search: search ? search : '',
      categoryId: categoryId === '0' ? '' : categoryId,
      countryCode: authUser.user.company.countryCode,
      parentId: authUser.user.company.parentId,
    };

    execute({
      method: 'GET',
      url: '/product',
      params: params,
    });
  };

  return {
    getProducts,
    loading,
    products: response ? response.result : [],
    count: response ? response.count : 0,
    currentPage: response ? response.page : 1,
  };
};

const useProductsAll = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IProductBaseResponse>();

  const getProducts = (args: {
    companyId?: string | null;
    page: number;
    search: string;
    categoryId: string;
    warehouseId: string;
  }) => {
    const { companyId } = args;

    const params = {
      companyId: companyId !== null ? companyId : 
        (authUser.user.role.name === 'SUPER_MASTER' ? '' : authUser.user.company.id),
      page: args.page,
      search: args.search,
      categoryId: args.categoryId,
      countryCode: authUser.user.company.countryCode,
      parentId: authUser.user.company.parentId,
      warehouseId: args.warehouseId,
    };

    return execute({
      method: 'GET',
      url: '/product/all',
      params: params,
    });
  };

  return {
    getProducts,
    loading,
    products: response ? response.result : [],
    count: response ? response.count : 0,
    currentPage: response ? response.page : 1,
  };
};

const useProductsWarehouseAll = () => {
  const { authUser } = useAuthStore();
  const { execute, response, loading } = useAxios<IProductBaseResponse>();

  const getProductsToWarehouse = (args: {
    page: number;
    search: string;
    categoryId: string;
    companyId: string;
  }) => {
    const { page, search, categoryId, companyId } = args;
    const params = {
      page: page,
      search: search,
      categoryId: categoryId,
      countryCode: authUser.user.company.countryCode,
      parentId: authUser.user.company.parentId,
      warehouseId: authUser.user.warehouse ? authUser.user.warehouse.id : '',
      companyId: companyId
    };

    execute({
      method: 'GET',
      url: '/product/warehouse/all',
      params: params,
    });
  };

  return {
    getProductsToWarehouse,
    loadingProds: loading,
    productsWar: response ? response.result : [],
    countProds: response ? response.count : 0,
    currentPageProds: response ? response.page : 1,
  };
};

export {
  useProductFind,
  useProductWarList,
  useProductsAll,
  useProductsWarehouseAll,
};
