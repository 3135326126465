import React from 'react';
import { Button, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EditOutlined } from '@ant-design/icons';
import { IFrequentQuestion } from '../../../../../services/frequent-question-list/frequent-question.interface';

interface IUsersListTableProps {
  loading: boolean;
  data: IFrequentQuestion[];
  onEdit: (faq: IFrequentQuestion) => void;
}

export const FaqMaintenanceTable: React.FC<IUsersListTableProps> = ({
  loading,
  data,
  onEdit,
}) => {
  const columns: ColumnsType<IFrequentQuestion> = [
    {
      title: 'Pregunta',
      dataIndex: 'question',
      width: 200,
    },
    {
      title: 'Respuesta',
      dataIndex: 'answer',
      width: 160,
      render: (_, faq) => (
        <p
          style={{
            margin: 0,
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          dangerouslySetInnerHTML={{ __html: faq.answer }}
        ></p>
      ),
    },
    {
      title: 'Módulo',
      dataIndex: 'module',
      width: 120,
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      width: 150,
      render: (_, faq) => {
        const roles = faq.roles?.split(',').slice(0, 3);
        const rolesTooltip = faq.roles?.split(',').slice(3);

        return (
          <>
            {roles?.map((role) => (
              <Tag
                key={role}
                bordered={false}
                color="default"
                style={{ fontSize: 11, margin: '0 2px 2px 0' }}
              >
                {role}
              </Tag>
            ))}
            {rolesTooltip?.length > 0 && (
              <Tooltip title={rolesTooltip.join(', ')}>
                <Tag
                  bordered={false}
                  color="geekblue"
                  style={{ fontSize: 11, margin: '0 2px 2px 0' }}
                >
                  +{rolesTooltip.length}
                </Tag>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'Posición',
      dataIndex: 'position',
      align: 'center',
      width: 70,
    },
    {
      title: 'País',
      dataIndex: 'countryCode',
      align: 'center',
      width: 40,
    },
    {
      title: 'Público',
      dataIndex: 'isPublic',
      align: 'center',
      width: 80,
      render: (_, faq) => (
        <>
          {faq.isPublic ? (
            <Tag color="green">Público</Tag>
          ) : (
            <Tag color="default">Privado</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Activo',
      dataIndex: 'isActive',
      align: 'center',
      width: 80,
      render: (_, faq) => (
        <>
          {faq.isActive ? (
            <Tag color="green">Activo</Tag>
          ) : (
            <Tag color="default">Inactivo</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      width: 80,
      render: (_, faq) => (
        <>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Tooltip title="Editar">
              <Button
                color="default"
                variant="filled"
                icon={<EditOutlined />}
                onClick={() => onEdit(faq)}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: 50,
      }}
      scroll={{ x: 1100 }}
      size="small"
    />
  );
};
