import { useAxios } from '../../hooks';
import { IFrequentQuestionCreateSchema } from '../frequent-question-create/frequent-question-create.schema';
import { IFrequentQuestion } from '../frequent-question-list/frequent-question.interface';

export const useFrequentQuestionUpdate = () => {
  const { execute, response, loading } = useAxios<IFrequentQuestion>();

  const updateQuestion = (data: IFrequentQuestionCreateSchema) => {
    const payload: IFrequentQuestion = {
      ...data,
      position: Number(data.position),
      roles: data.roles?.join(','),
    };

    return execute({
      method: 'PATCH',
      url: `/frequent-question/${data.id}`,
      data: payload,
    });
  };

  return {
    updateQuestion,
    loading,
    created: response,
  };
};
