export const BANKS_FOR_PAYMENTS = [
  {
    code: 'BCP',
    value: 'Banco de Crédito del Perú',
    availableCountry: ['PER'],
  },
  { code: 'IBK', value: 'Interbank', availableCountry: ['PER'] },
  { code: 'BIN', value: 'Binance', availableCountry: ['ALL'] },
  { code: 'PAYPAL', value: 'Paypal', availableCountry: ['ALL'] },
];
