import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Radio,
  Select,
  Spin,
  Typography,
} from 'antd';
import { downloadExcel } from 'react-export-table-to-excel';
import { useAuthStore } from '../../../../services/auth/auth.service';
import { useDispatchReport } from '../../../../services/order-report-created/order-report-created.service';
import { useWarehouseByCountry } from '../../../../services/warehouse-list/warehouse-list.service';
import { formatDateUTC } from '../../../../utils/date';
import { useTransporterList } from '../../../../services/transporter-list/transporter-list.service';
import dayjs from 'dayjs';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DispatchReport = () => {
  const { authUser } = useAuthStore();
  const dateFormat = 'YYYY-MM-DD';
  const { getDispatchReport, dispatchReports, loadingDispatchReports } =
    useDispatchReport();
  const [ecomSelected, setEcomSelected] = useState<string>('');
  const { getWarehouses, loadingWarehouses, warehouses } =
    useWarehouseByCountry();
  const [hiddenWar, setHiddenWar] = useState(false);
  const [filter, setFilter] = useState<string>('dispatch');
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [hiddenTransp, setHiddenTransp] = useState(false);

  const headerOrder = [
    'FECHA ENTREGA',
    'FECHA DESPACHO',
    'PRODUCTO',
    'VARIANTE',
    'PROVEEDOR',
    'ALMACÉN',
    'TIENDA',
    'PAÍS',
    'DEPARTAMENTO',
    'PROVINCIA',
    'DISTRITO',
    'PRECIO DROP',
    'CANTIDAD',
    'NRO. PEDIDO',
    'TRANSPORTADORA',
    'ESTADO ENTREGA',
    'ESTADO DESPACHO',
    'FECHA ACTUALIZACIÓN DESPACHO',
    'DROPSHALOM',
    'TOTAL',
  ];

  function handleDownloadExcelOrders() {
    getDispatchReport(
      selectedDatesOrder[0].format('YYYY-MM-DD'),
      selectedDatesOrder[1].format('YYYY-MM-DD'),
      parseInt(ecomSelected),
      filter,
      authUser.user.company.countryCode,
      transpSelected,
    );
  }

  useEffect(() => {
    if (dispatchReports && dispatchReports.length > 0) {
      downloadExcel({
        fileName: `dispatch-report-${selectedDatesOrder[0].format(
          'YYYY-MM-DD',
        )}-to-${selectedDatesOrder[1].format('YYYY-MM-DD')}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: dispatchReports.map((o) => {
            return {
              deliveryDate: formatDateUTC(o.deliveryDate),
              dispatchDate: formatDateUTC(o.dispatchDate),
              product: o.product,
              variant: o.variant,
              supplier: o.supplier,
              warehouse: o.warehouse,
              ecommerce: o.ecommerce,
              country: o.country,
              department: o.department,
              province: o.province,
              district: o.district,
              dropPrice: o.dropPrice,
              quantity: o.quantity,
              orderNumber: o.orderNumber,
              transporter: o.transporter,
              deliveryStatus: o.deliveryStatus,
              dispatchStatus: o.dispatchStatus,
              dispatchedAt: formatDateUTC(o.dispatchedAt),
              dropShalom: o.dropShalom,
              total: o.total,
            };
          }),
        },
      });
    }
  }, [dispatchReports]);

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates);
  };

  const handleFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const handleChangeEcom = (ecomId: string) => {
    setEcomSelected(ecomId);
  };

  useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      setEcomSelected(`${warehouses[0].id}`);
    }
  }, [warehouses]);

  useEffect(() => {
    if (
      authUser.user.role.name === 'SUPER_MASTER' ||
      authUser.user.role.name === 'STORE'
    ) {
      getTransporters();
      setHiddenTransp(false);

      if (authUser.user.role.name === 'STORE') {
        setHiddenWar(true);
        setEcomSelected(`${authUser.user.warehouse?.id}`);
      } else {
        setHiddenWar(false);
        getWarehouses();
      }
    } else if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER'
    ) {
      setHiddenTransp(true);
      setTranspSelected(`${authUser.user.conveyor?.id}`);
    }
  }, []);

  const handleChangeTransporter = (id: string) => {
    setTranspSelected(id);
  };

  return (
    <>
      <Spin spinning={loadingDispatchReports}>
        <Divider />

        <Title
          level={5}
          style={{
            marginBottom: '0',
            alignContent: 'center',
            padding: '.0rem 1.5rem',
          }}
        >
          Reporte de Despacho
        </Title>

        <div
          style={{
            padding: '.85rem 1.5rem',
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          {hiddenTransp ? (
            <></>
          ) : (
            <>
              <Title level={5} style={{ marginBottom: '0' }}>
                Transportadora
              </Title>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={'#000000'}
                    text=""
                  >
                    TODOS
                  </Badge>
                </Option>
                {transporters?.map((key) => (
                  <Option key={key.name} value={key.id}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <Title level={5} style={{ marginBottom: '0' }}>
            Filtrar por
          </Title>

          <Radio.Group
            options={[
              { label: 'Fecha despacho', value: 'dispatch' },
              { label: 'Fecha entrega', value: 'delivery' },
            ]}
            onChange={handleFilter}
            defaultValue={filter}
          />

          <Title level={5} style={{ marginBottom: '0' }}>
            Seleccionar fechas
          </Title>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(new Date()), dayjs(new Date())]}
            onCalendarChange={handleCalendarChangeOrder}
            clearIcon={false}
          />

          {!hiddenWar ? (
            <>
              <Title level={5} style={{ marginBottom: '0' }}>
                Almacen
              </Title>

              <Select
                placeholder="Ecommerce"
                value={ecomSelected}
                onChange={handleChangeEcom}
                loading={loadingWarehouses}
                style={{ display: 'flex', width: '200px' }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key={-1} value={-1} label={'TODOS'}>
                  TODOS
                </Option>
                {warehouses.map((c) => (
                  <Option key={c.id} value={`${c.id}`} label={c.name}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </>
          ) : null}

          <Button
            type="primary"
            onClick={handleDownloadExcelOrders}
            loading={loadingDispatchReports}
          >
            Descargar reporte
          </Button>
        </div>
      </Spin>
    </>
  );
};

export default DispatchReport;
