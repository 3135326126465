import { ISettingRequest } from './setting.interface';
import { ISettingSchema } from './setting.schema';

const mapToSettingRequest = (data: ISettingSchema): ISettingRequest => {
  return {
    companyId: data.companyId,
    spreadsheetOrder: data.spreadsheetOrder,
    companyPrefix: data.companyPrefix,
    currency: data.currency,
    country: data.country,
    amountDelivery: +data.amountDelivery,
    phone: data.phone,
    codeBank: data.codeBank,
    nameBank: data.nameBank,
    accountNumber: data.accountNumber,
    cci: data.cci,
    holderName: data.holderName,
    businessDocument: data.businessDocument,
    emailTransfer: data.emailTransfer,
    businessName: data.businessName,
    documentHolderAccount: data.documentHolderAccount,
    binanceId: data.binanceId,
    binanceEmail: data.binanceEmail,
    binanceUser: data.binanceUser,
    paypalEmail: data.paypalEmail,
  };
};

export { mapToSettingRequest };
