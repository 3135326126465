import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../components';
import { CompanyChangeContainer } from '../../../../containers';
import { useAuthStore } from '../../../../services/auth/auth.service';
import { useOrderSummary } from '../../../../services/order-summary/order-summary.service';
import { Card, Col, Row, Spin, Statistic, DatePicker } from 'antd';
import { useCompanyStore } from '../../../../services/company-create/company-create.service';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { subDays } from 'date-fns';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const HomeContainer = () => {
  const { authUser } = useAuthStore();
  const { companyGlobal } = useCompanyStore();
  const { getOrderSummary, response, loading } = useOrderSummary();
  const dateFormat = 'YYYY-MM-DD';
  const [selectedDates, setSelectedDates] = useState([
    dayjs(subDays(new Date(), 30)),
    dayjs(new Date()),
  ]);
  //const [labels, setLabels] = useState<string[]>([])
  const [dataChart, setDataChart] = useState<any>();

  useEffect(() => {
    if (selectedDates.length === 2 && selectedDates[0] && selectedDates[1]) {
      if (authUser.user.company.countryCode === 'PER') {
        if (authUser.user.role.name === 'ADMIN' && companyGlobal) {
          getOrderSummary(
            selectedDates[0].format('YYYY-MM-DD'),
            selectedDates[1].format('YYYY-MM-DD'),
            companyGlobal.id,
            authUser.user.company.countryCode,
          );
        } else if (authUser.user.role.name === 'ADMIN_STORE') {
          getOrderSummary(
            selectedDates[0].format('YYYY-MM-DD'),
            selectedDates[1].format('YYYY-MM-DD'),
            authUser.user.company.id,
            authUser.user.company.countryCode,
          );
        }
      } else if (authUser.user.company.countryCode === 'BOL') {
        getOrderSummary(
          selectedDates[0].format('YYYY-MM-DD'),
          selectedDates[1].format('YYYY-MM-DD'),
          authUser.user.company.id,
          authUser.user.company.countryCode,
        );
      }
    }
  }, [companyGlobal, selectedDates]);

  useEffect(() => {
    if (response) {
      if (
        response.confirmedOrders.length > 0 &&
        response.deliveredOrders.length > 0
      ) {
        //setLabels(response.confirmedOrders.map(t => t.dateConfirmed))

        setDataChart({
          labels: response.confirmedOrders.map((t) => t.dateConfirmed),
          datasets: [
            {
              label: 'Pedidos confirmados',
              data: response.confirmedOrders.map((t) => t.quantityConfirmed),
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              yAxisID: 'y',
            },
            {
              label: 'Pedidos entregados',
              data: response.deliveredOrders.map((t) => t.quantityDelivered),
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              yAxisID: 'y1',
            },
          ],
        });
      }
    }
  }, [response]);

  const handleCalendarChange = (dates: any, dateStrings: any) => {
    setSelectedDates(dates);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Line Chart - Multi Axis',
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  /*
  const data = {
    labels: ['2023-12-01', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [200, 220],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Dataset 2',
        data: [100, 150],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y1',
      },
    ],
  }*/

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '.5rem',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            {authUser.user.role?.permissions?.order_company_change &&
            authUser.user.company.countryCode === 'PER' ? (
              <CompanyChangeContainer isAll={false} />
            ) : null}
          </div>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(subDays(new Date(), 30)), dayjs(new Date())]}
            onCalendarChange={handleCalendarChange}
            clearIcon={false}
          />
        </div>
      </PageHeader>

      <div>
        <Spin size="large" spinning={loading}>
          <div>
            <Row gutter={16}>
              <Col span={6}>
                <Card style={{ marginLeft: '8px' }}>
                  <Statistic
                    title="Pedido Generados"
                    value={`${response?.totalOrderGenerated || 0}`}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title={`Pedido Confirmados ${response?.totalConfirmedPercentage || 0}%`}
                    value={`${response?.totalOrderConfirmed || 0} - S/${response?.totalAmountOrderConfirmed || 0}`}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title={`Pedido Entregados ${response?.totalDeliveredPercentage || 0}%`}
                    value={`${response?.totalOrderDelivered || 0} - S/${response?.totalAmountOrderDelivered || 0}`}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card style={{ marginRight: '8px' }}>
                  <Statistic
                    title="% Operación completada"
                    value={response?.totalOperationCompleted || 0}
                    suffix="%"
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </Spin>
        {dataChart ? (
          <>
            <Line options={options} data={dataChart} height={90} />
          </>
        ) : null}
      </div>
    </>
  );
};

export default HomeContainer;
