import React, { FC } from 'react';
import { Spin } from 'antd';
import { FaqEditForm } from '../../forms';
import { Drawer } from '../../../../../components';
import { IFrequentQuestionCreateSchema } from '../../../../../services/frequent-question-create/frequent-question-create.schema';
import { IFrequentQuestion } from '../../../../../services/frequent-question-list/frequent-question.interface';
import { useFrequentQuestionCreate } from '../../../../../services/frequent-question-create/frequent-question-create.service';
import { useFrequentQuestionUpdate } from '../../../../../services/frequent-question-update/frequent-question-create.service';

type TFaqEditContainerProps = {
  faq?: IFrequentQuestion;
  onFinish: () => void;
  onClose: () => void;
};

export const FaqEditContainer: FC<TFaqEditContainerProps> = ({ faq, onFinish, onClose }) => {
  const { createQuestion, loading: isLoadingCreate } = useFrequentQuestionCreate();
  const { updateQuestion, loading: isloadingUpdate } = useFrequentQuestionUpdate();

  const handleSubmit = (data: IFrequentQuestionCreateSchema) => {
    if (data.id) {
      updateQuestion(data).then(() => {
        onClose();
        onFinish();
      });
    } else {
      createQuestion(data).then(() => {
        onClose();
        onFinish();
      });
    }
  };

  return (
    <Drawer
      open
      title={faq ? 'Editar pregunta frecuente' : 'Agregar pregunta frecuente'}
      onClose={onClose}
      width={600}
    >
      <Spin spinning={isLoadingCreate || isloadingUpdate}>
        <FaqEditForm initialValue={faq} onSubmit={handleSubmit} />
      </Spin>
    </Drawer>
  );
};
