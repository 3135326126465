import { IRoutePath } from '../../../../routes/routes.param';
import { IRolePermission } from '../../../../services/auth/auth.interface';

export const filterRoutesByPermission = (
  allRoutes: IRoutePath[],
  permissions: IRolePermission,
): IRoutePath[] => {
  return (
    allRoutes
      /**
       * Step 1
       * Filter by empty title
       */
      .filter((module) => module.TITLE)
      /**
       * Step 2
       * Filter modules by user permissions
       */
      .filter((module) => permissions?.routes[module.PATH])
      /**
       * Step 3
       * Filter children by user permissions
       */
      .map((module) => {
        return {
          ...module,
          CHILDREN: module.CHILDREN?.filter((sub) =>
            permissions?.routes[module.PATH].includes(sub.PATH),
          ),
        };
      })
  );
};
