import React from 'react';
import { SignInContainer } from './containers';
import backgroundLogin from './background_login.png';
import logo from './ic_aliclik.png';
import s from './SignInView.module.css';

const SignInView = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${backgroundLogin})` }}
        className={s.WrapperContainer}
      >
        <div className={s.WrapperBoxSmall}>
          <div className="center">
            <img src={logo} style={{ width: '90px', height: 'auto' }} />
          </div>

          <SignInContainer />

          <p
            className="center"
            style={{ fontSize: '12px', color: '#888', marginTop: '1rem' }}
          >
            Copyright 2023 © Aliclik Perú.
          </p>
        </div>
      </div>
    </>
  );
};

export default SignInView;
