import { create } from 'zustand';
import { ISignUpSchema } from '../../../services/sign-up/sign-up.schema';

type TSignupStore = {
  createNewAccountRequest: ISignUpSchema | null;
  setCreateNewAccountRequest: (createNewAccountRequest: ISignUpSchema) => void;
};

export const useSignupStore = create<TSignupStore>((set) => ({
  createNewAccountRequest: null,
  setCreateNewAccountRequest: (createNewAccountRequest: ISignUpSchema) =>
    set(() => ({ createNewAccountRequest })),
}));
